import * as ALERTS from '../constants/alerts.js';

export default function Alert(props) {
    if(props.alert && props.alert.show) {
        switch(props.alert.type) {
            case ALERTS.SUCCESS:
                return (
                    <div className="alert alert-success notification-alert" role="alert">
                        <strong>Success!</strong> {props.alert.message}
                    </div>
                );
            case ALERTS.ERROR:
                return (
                    <div className="alert alert-danger notification-alert" role="alert">
                        <strong>Error</strong> {props.alert.message}
                    </div>
                );
            case ALERTS.OFFLINE:
                return (
                    <div className="alert alert-danger notification-alert" role="alert">
                        <strong>Error</strong> {props.alert.message}
                    </div>
                );
        }
    }
    return "";
}
import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import './CalendarView.scss';

import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../constants/alerts.js';

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// import Autocomplete from 'react-autocomplete'; // TODO: React-bootstrap-typeahead
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  Typeahead,
  Highlighter,
  Menu,
  MenuItem,
} from 'react-bootstrap-typeahead';

import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'

import EventIcon from '@mui/icons-material/Event';

export default class BoardingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      isLoaded: false,
      inputDisabled: false
    };
  }

  async componentDidMount() {
    await this.props.getFlightNumber();
  }

  handleChange = (e) => {
    this.props.handleChange(e);
  }

  handleSelect = (e) => {
    this.props.handleChange(e);
  }

  handleDateTime = (val) => {
    this.props.handleDateTime(val);
  }

  board = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      let config = {
        headers: { Authorization: `Bearer ${this.props.token}` }
      };

      this.setState(state => { state.form.status_id = 2; return state; })

      axios.put(SETTINGS.API_BASE_URL + "/bookings/" + this.props.formData.id + "/board", this.props.formData, config)
        .then((response) => {
          console.log(response);
          this.props.displayAlert("Booking #" + this.props.formData.id + " Boarded", ALERTS.SUCCESS)
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  // board(event) {
  //   const form = event.currentTarget;
  //   event.preventDefault();
  //   if (form.checkValidity() === false) {
  //     event.stopPropagation();
  //     this.setState(state => { state.validated = true; return state; });
  //   }
  //   else {
  //     this.setState(state => {
  //       state.enabled = false;
  //       return state;
  //     });

  //     let config = {
  //       headers: { Authorization: `Bearer ${this.props.token}` }
  //     };

  //     this.setState(state => { state.form.status_id = 2; return state; })

  //     axios.put(SETTINGS.API_BASE_URL + "/bookings/" + this.state.form.id + "/board", this.state.form, config)
  //       .then((response) => {
  //         console.log(response);
  //         this.props.displayAlert("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
  //         this.props.close();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         this.setState(state => {
  //           state.isLoaded = true;
  //           state.error = error;
  //           return state;
  //         });
  //         this.props.displayAlert(error.message, ALERTS.ERROR);
  //       });
  //   }
  // }

  submitForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.board();
    }
  }

  render() {
    const { loading, data, rowsTotal } = this.state;
    const inputDisabled = this.state.inputDisabled ? true : false;
    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 } };
    const buttons = this.state.enabled ? (<><button className="btn btn-primary btn-block btn-lg" type="submit">Board</button>
      <button type="button" onClick={this.props.back} className="btn btn-secondary btn-block btn-lg">Back</button></>) : (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>)

    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.submitForm}>
        <h4 className="mb-3">Booking</h4>
        <Form.Row>
          <Form.Group as={Col} controlId="date">
            <Form.Label>Date and Time</Form.Label>
            <div className="input-group input-group-datetimepicker">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1"><EventIcon /></span>
              </div>
              <input type="text" readonly="readonly" onfocus="blur();" pickerOptions={{ allowInputToggle: false }} timeConstraints={constraints} name="date" value={this.props.formData.date} disabled={true} required />
            </div>
          </Form.Group>
          <Form.Group as={Col} controlId="flightNumber">
            <Form.Label>Order ID</Form.Label>
            <input
              required
              type="text"
              className="form-control"
              id="flightNumber"
              name="flightNumber"
              placeholder=""
              value={this.props.formData.id}
              disabled={true} />
          </Form.Group>
        </Form.Row>
        <div className="well">
          <Form.Row>
            <Form.Group className="mb-3">
              <Form.Label>PAX Adult</Form.Label>
              <select className="form-control"
                id="passengers"
                name="passengers"
                placeholder=""
                value={this.props.formData.passengers}
                onChange={this.handleChange}
                required
                disabled={true}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
                <option>32</option>
                <option>33</option>
                <option>34</option>
              </select>
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price">
              <Form.Label>Seat Price</Form.Label>
              <input
                type="number"
                className="form-control"
                id="seat_price"
                name="seat_price"
                placeholder=""
                value={this.props.formData.seat_price}
                onChange={this.handleChange}
                required
                disabled={true} />
            </Form.Group>
            <Form.Group as={Col} controlId="price">
              <Form.Label>Price Total</Form.Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">R</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  name="price"
                  placeholder=""
                  value={(this.props.formData.price)}
                  required
                  disabled={true} />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="mb-3">
              <Form.Label>PAX Kids</Form.Label>
              <select className="form-control"
                id="passengersKids"
                name="passengersKids"
                placeholder=""
                value={this.props.formData.passengersKids}
                onChange={this.handleChange}
                required
                disabled={true}>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
                <option>32</option>
                <option>33</option>
                <option>34</option>
              </select>
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price">
              <Form.Label>Seat Price</Form.Label>
              <input
                type="number"
                className="form-control"
                id="seat_price_kids"
                name="seat_price_kids"
                placeholder=""
                value={this.props.formData.seat_price_kids}
                onChange={this.handleChange}
                required
                disabled={true} />
            </Form.Group>
            <Form.Group as={Col} controlId="price">
              <Form.Label>Price Total</Form.Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">R</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="priceKids"
                  name="priceKids"
                  placeholder=""
                  value={(this.props.formData.priceKids)}
                  required
                  disabled={true} />
              </div>
            </Form.Group>
          </Form.Row>
        </div>
        <Form.Row>
          <Form.Group as={Col} controlId="route_id">
            <Form.Label>Route</Form.Label>
            <Select
              name="route_id"
              // options={this.state.data.booking_routes}
              required="true"
              value={this.props.formData.route_id}
              options={this.props.lookups.booking_routes}
              onChange={this.handleChange}
              disabled={true}></Select>
          </Form.Group>
          <Form.Group as={Col} controlId="route_range">
            <Form.Label>Range</Form.Label>
            <input
              type="text"
              className="form-control"
              id="route_range"
              name="route_range"
              placeholder=""
              value={this.props.formData.route_range}
              onChange={this.handleChange}
              required
              disabled={true} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              placeholder=""
              value={this.props.formData.firstname}
              onChange={this.handleChange}
              disabled={true} />
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              placeholder=""
              value={this.props.formData.lastname}
              onChange={this.handleChange}
              disabled={true} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="phone">
            <Form.Label>Phone</Form.Label>
            <input
              type="tel"
              minLength="10"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="+27713034000"
              value={this.props.formData.phone}
              onChange={this.handleChange}
              disabled={true} />
            <Form.Control.Feedback type="invalid">
              Please provide a phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="email">
            <Form.Label>Email</Form.Label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="you@example.com"
              value={this.props.formData.email}
              onChange={this.handleChange}
              disabled={true} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <h4 className="mb-3">Boarding</h4>
        <hr className="mb-4" />
        <Form.Row>
          <Form.Group as={Col} controlId="craft">
            <Form.Label>Select Craft</Form.Label>
            <Select
              name="craft"
              options={this.props.lookups.crafts}
              required="true"
              valueName={true}
              value={this.props.formData.craft}
              onChange={this.props.handleChange}
              disabled={inputDisabled}></Select>
          </Form.Group>
          <Form.Group as={Col} controlId="pilot">
            <Form.Label>Select Pilot</Form.Label>
            <input
              required
              type="text"
              className="form-control"
              id="pilot"
              name="pilot"
              placeholder=""
              value={this.props.formData.pilot}
              onChange={this.props.handleChange}
              disabled={inputDisabled} />
            {/* <Autocomplete className="form-control"
                    getItemValue={(item) => item.name}
                    items={this.props.lookups.pilots}
                    renderItem={(item, isHighlighted) =>
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name}
                      </div>
                    }
                    value={this.props.formData.pilot}
                    onChange={this.props.handleChange}
                  /> */}
          </Form.Group>
        </Form.Row>
        <hr className="mb-4" />
        <Form.Row>
          <Form.Group as={Col} controlId="flightNumber">
            <Form.Label>Flight Number</Form.Label>
            <input
              required
              type="number"
              className="form-control"
              id="flightNumber"
              name="flightNumber"
              placeholder=""
              min="2023010101"
              max="2099010101"
              value={this.props.formData.flightNumber}
              onChange={this.props.handleChange}
              disabled={inputDisabled} />
          </Form.Group>
        </Form.Row>
        <hr className="mb-4" />
        {buttons}
      </Form>
    )
  }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i} selected={props.valueName ? item.name === props.value : item.id === props.value}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i} selected={props.valueName ? item.name === props.value : item.id === props.value}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
// Implement data access layer for printing service. Copilot comments:
// This is a simple example of a data access layer.
// The data access layer is responsible for communicating with the server.
// The data access layer is responsible for sending and receiving data from the server.
// The data access layer is responsible for validating the data received from the server.
// The data access layer is responsible for handling errors.
// The data access layer is responsible for handling the authentication.
// The data access layer is responsible for handling the authorization.
// The data access layer is responsible for handling the caching.
// The data access layer is responsible for handling the offline mode.
// The data access layer is responsible for handling the synchronization.
// The data access layer is responsible for handling the timeouts.
// The data access layer is responsible for handling the throttling.
// The data access layer is responsible for handling the retries.
// The data access layer is responsible for handling the fallback.

import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class PrintServiceDAL {
    constructor(token) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }
    
    // https://local.printservice:6264
    print(id) {
        return axios.get(`${SETTINGS.PRINT_SERVICE}/api/printer/booking/${id}`, this.config);
    }
}
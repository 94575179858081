// src/components/BookingForm/BookingForm.js
import React from 'react';
import * as ROLES from '../../constants/roles';
import * as PERM from '../../constants/permissions';
import * as LOOKUPS from '../../constants/lookups';
import './BookingForm.scss';
import Datetime from '../../components/react-datetime/DateTime';
import '../../components/react-datetime/react-datetime.css';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import EventIcon from '@mui/icons-material/Event';
import axios from 'axios';
import * as SETTINGS from '../../constants/settings';
import * as ALERTS from '../../constants/alerts';

export default class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isLoaded: false,
      formData: { ...props.formData }
    };
  }

  componentDidMount() {
    const { formData, lookups } = this.props;

    // console.log('formData', formData);
    // console.log('lookups', lookups);

    if (formData && formData.craft && lookups.crafts.length > 0) {
      const defaultCraft = lookups.crafts.find(craft => craft.name === formData.craft);
      if (defaultCraft) {
        this.setState(prevState => ({ formData: { ...prevState.formData, craft: defaultCraft.id } }));
      } else {
        this.setState({ formData });
      }
    } else {
      this.setState({ formData });
    }
  }

  handleChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    // console.log(`Field changed: ${name}, Value: ${val}`);

    const handleLookupChange = (lookupList, targetName, filterKey) => {
      const lookupValue = lookupList.find((x) => x.id === parseInt(val));
      if (lookupValue) {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [targetName]: lookupValue[filterKey]
          }
        }));
        this.props.handleChange({ target: { value: lookupValue[filterKey], name: targetName } });
      }
    };

    switch (name) {
      case 'route_id':
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            route_id: val
          }
        }));
        handleLookupChange(this.props.lookups.booking_routes, 'route_range', 'val1');
        break;
      case 'passengers':
        this.props.handleChange({ target: { value: val * this.props.formData.seat_price, name: 'price' } });
        break;
      case 'passengersKids':
        this.props.handleChange({ target: { value: val * this.props.formData.seat_price_kids, name: 'priceKids' } });
        break;
      case 'craft':
        const selectedCraft = this.props.lookups.crafts.find((x) => x.id === val);
        if (selectedCraft) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              craft: selectedCraft.id
            }
          }));
          this.props.handleChange({ target: { value: selectedCraft.id, name: 'craft' } });
        }
        break;
      case 'office_id':
        const selectedOffice = this.props.lookups.booking_offices.find((x) => x.id === parseInt(val));
        if (selectedOffice) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              office_id: selectedOffice.id
            }
          }));
          this.props.handleChange({ target: { value: selectedOffice.id, name: 'office' } });
        }
        break;
      case 'sales_agent_id':
        const selectedAgent = this.props.lookups.agents_sales.find((x) => x.id === parseInt(val));
        if (selectedAgent) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              sales_agent_id: selectedAgent.id
            }
          }));
          this.props.handleChange({ target: { value: selectedAgent.id, name: 'sales_agent' } });
        }
        break;
      default:
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: val
          }
        }));
        this.props.handleChange(e);
        break;
    }
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  goToPayment = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.props.payment();
    }
  }

  submitFormUpdate = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.props.updateBooking();
    }
  }

  render() {
    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 } };
    const { validated, formData } = this.state;
    const { user, handleChange, lookups, payment, goToBoard, handleCancel, permissions } = this.props;

    const messageDialog = null; 

    const buttons = formData.id > 0 ? (
      <>
        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitFormUpdate}>Update</button>
        <button type="submit" className="btn btn-success btn-block btn-lg">Payment</button>
        <button type="button" className="btn btn-success btn-block btn-lg" onClick={goToBoard}>Board</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={handleCancel}>Cancel</button>
      </>
    ) : (
      <>
        <button type="submit" className="btn btn-success btn-block btn-lg">Payment</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={handleCancel}>Cancel</button>
      </>
    );


    return (
      <Form noValidate validated={validated} onSubmit={this.goToPayment}>
        <Form.Row>
          <Form.Group as={Col} controlId="date">
            <Form.Label>Date and Time</Form.Label>
            <InputGroup className="input-group-datetimepicker">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><EventIcon /></InputGroup.Text>
              </InputGroup.Prepend>
              <input type="text" readOnly onFocus={(e) => e.target.blur()} name="date" value={formData.date} disabled={!permissions.date} required />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="flightNumber">
            <Form.Label>Trip Number</Form.Label>
            <input type="text" className="form-control" id="flightNumber" name="flightNumber" value={formData.flightNumber} disabled={!permissions.flightNumber} required />
          </Form.Group>
        </Form.Row>
        <div className="well">
          <Form.Row>
            <Form.Group className="mb-3 col-sm-2">
              <Form.Label>PAX Adult</Form.Label>
              <input type="number" className="form-control" min="1" max="100" id="passengers" name="passengers" value={formData.passengers} onChange={this.handleChange} required disabled={!permissions.passengers} />
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price">
              <Form.Label>Seat Price</Form.Label>
              <input type="number" className="form-control" id="seat_price" name="seat_price" value={formData.seat_price} onChange={this.handleChange} required disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="price">
              <Form.Label>Price Total</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R</InputGroup.Text>
                </InputGroup.Prepend>
                <input type="number" className="form-control" id="price" name="price" value={formData.price} required disabled />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="mb-3 col-sm-2">
              <Form.Label>PAX Kids</Form.Label>
              <input type="number" className="form-control" max="100" id="passengersKids" name="passengersKids" value={formData.passengersKids} onChange={this.handleChange} required disabled={!permissions.passengersKids} />
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price_kids">
              <Form.Label>Seat Price</Form.Label>
              <input type="number" className="form-control" id="seat_price_kids" name="seat_price_kids" value={formData.seat_price_kids} onChange={this.handleChange} required disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="priceKids">
              <Form.Label>Price Total</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R</InputGroup.Text>
                </InputGroup.Prepend>
                <input type="number" className="form-control" id="priceKids" name="priceKids" value={formData.priceKids} required disabled />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="mb-3 col-sm-2">
              <Form.Label>PAX Toddlers</Form.Label>
              <input type="number" className="form-control" max="100" id="passengersToddlers" name="passengersToddlers" value={formData.passengersToddlers} onChange={this.handleChange} required disabled={!permissions.passengersToddlers} />
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price_toddlers">
              <Form.Label>Seat Price</Form.Label>
              <input type="number" className="form-control" id="seat_price_toddlers" name="seat_price_toddlers" value={0} onChange={this.handleChange} required disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="priceToddlers">
              <Form.Label>Price Total</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R</InputGroup.Text>
                </InputGroup.Prepend>
                <input type="number" className="form-control" id="priceToddlers" name="priceToddlers" value={0} required disabled />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="mb-3 col-sm-2">
              <Form.Label>PAX Guides</Form.Label>
              <input type="number" className="form-control" max="100" id="passengersGuides" name="passengersGuides" value={formData.passengersGuides} onChange={this.handleChange} required disabled={!permissions.passengersGuides} />
            </Form.Group>
            <Form.Group as={Col} controlId="seat_price_guides">
              <Form.Label>Seat Price</Form.Label>
              <input type="number" className="form-control" id="seat_price_guides" name="seat_price_guides" value={0} onChange={this.handleChange} required disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="priceGuides">
              <Form.Label>Price Total</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R</InputGroup.Text>
                </InputGroup.Prepend>
                <input type="number" className="form-control" id="priceGuides" name="priceGuides" value={0} required disabled />
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
        <Form.Row>
          <Form.Group as={Col} controlId="route_id">
            <Form.Label>Route</Form.Label>
            <Select name="route_id" value={formData.route_id} options={lookups.booking_routes} onChange={this.handleChange} disabled={!permissions.route_id} />
          </Form.Group>
          <Form.Group as={Col} controlId="route_range">
            <Form.Label>Range</Form.Label>
            <input type="text" className="form-control" id="route_range" name="route_range" value={formData.route_range} onChange={this.handleChange} required disabled={!permissions.route_range} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="craft">
            <Form.Label>Craft</Form.Label>
            <Select
              name="craft"
              value={formData.craft}
              options={lookups.crafts}
              onChange={this.handleChange}
              disabled={!permissions.craft}
              key={formData.craft}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="firstname">
            <Form.Label>First name</Form.Label>
            <input type="text" className="form-control" id="firstname" name="firstname" value={formData.firstname} onChange={this.handleChange} disabled={!permissions.firstname} />
          </Form.Group>
          <Form.Group as={Col} controlId="lastname">
            <Form.Label>Last name</Form.Label>
            <input type="text" className="form-control" id="lastname" name="lastname" value={formData.lastname} onChange={this.handleChange} disabled={!permissions.lastname} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="phone">
            <Form.Label>Phone</Form.Label>
            <input type="tel" minLength="10" className="form-control" id="phone" name="phone" placeholder="+27713034000" value={formData.phone} onChange={this.handleChange} disabled={!permissions.phone} />
            <Form.Control.Feedback type="invalid">Please provide a phone number.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>Email</Form.Label>
            <input type="email" className="form-control" id="email" name="email" placeholder="you@example.com" value={formData.email} onChange={this.handleChange} disabled={!permissions.email} />
            <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="office_id">
            <Form.Label>Booking Office</Form.Label>
            <Select
              name="office_id"
              options={lookups.booking_offices}
              value={formData.office_id}
              onChange={this.handleChange}
              disabled={!permissions.office_id}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="sales_agent_id">
            <Form.Label>Sales Agent</Form.Label>
            <Select
              name="sales_agent_id"
              options={lookups.agents_sales}
              value={formData.sales_agent_id}
              onChange={this.handleChange}
              disabled={!permissions.sales_agent_id}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="notes">
            <Form.Label>Booking Notes</Form.Label>
            <textarea
              value={formData.notes ?? ''}
              onChange={this.handleChange}
              className="form-control"
              id="notes"
              name="notes"
              rows="4"
              disabled={!permissions.notes}
            ></textarea>
          </Form.Group>
        </Form.Row>
        <hr className="mb-4" />
        {messageDialog}
        {buttons}
      </Form>
    );
  }
}

function Select(props) {
  const { name, required, value, options = [], onChange, disabled, showId } = props;

  const lookup = options.map((item) => ({
    id: item.id,
    name: showId ? `(${item.id}) ${item.name}` : item.name
  })).sort((a, b) => {
    if (typeof a.id === 'string' && typeof b.id === 'string') {
      return a.id.localeCompare(b.id);
    } else if (typeof a.id === 'number' && typeof b.id === 'number') {
      return a.id - b.id;
    } else if (typeof a.id === 'string') {
      return -1;
    } else {
      return 1;
    }
  });

  const selectedOption = options.find(option => option.id == value || option.name === value);

  return (
    <select
      className="custom-select d-block w-100"
      id={name}
      name={name}
      required={required}
      value={selectedOption ? selectedOption.id : ''}
      onChange={onChange}
      disabled={disabled}
    >
      <option value="">Choose...</option>
      {lookup.map((item, i) => (
        <option value={item.id} key={i}>{item.name}</option>
      ))}
    </select>
  );
}
// Import packages
import React from 'react';
import './ProductForm.scss';
import * as SETTINGS from '../../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../../constants/alerts.js';
import * as ROLES from '../../../constants/roles.js';
import moment from "moment";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import EventIcon from '@mui/icons-material/Event';
import Datetime from '../../../components/react-datetime/DateTime';
import "../../../components/react-datetime/react-datetime.css";
import * as LOOKUPS from '../../../constants/lookups.js';
import DataAccessLayer from '../../../DAL';
import { STATUS } from '../../../constants/bookingForm';

const entityController = "products";
const entityName = "Product";

export default class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      inputDisabled: false,
      data: {
        booking_types: [],
        booking_routes: [],
        booking_offices: [],
        booking_payment_types: [],
        booking_sales_agents: []
      },
      form: {
        "name": "",
        "craft": "",
        "routeId": 0,
        "priceAdult": 0.0,
        "priceKid": 0.0,
        "wcProductId": 0,
        "wcAdultPriceId": 0,
        "wcKidPriceId": 0,
        "updateDate": null
      },
      enabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.DAL = new DataAccessLayer(this.props.token);
  }

  async componentDidMount() {
    switch (this.props.action) {
      case ACTIONS.CREATE:
        this.setState({ inputDisabled: false, action: ACTIONS.CREATE, submitFunction: this.create });
        break;
      case ACTIONS.UPDATE:
        this.setState({ inputDisabled: false, action: ACTIONS.UPDATE, submitFunction: this.update });
        break;
    }

    if (this.props.lookups) {
      this.setState({data: this.props.lookups});
    }

    if (this.props.data) {
      this.setState({ form: this.props.data });
    }
  }

  handleChange(e) {
    const val = e.target.value;
    const name = e.target.name;
    this.setState(state => { state.form[name] = val; return state; });
    return val;
  }

  create = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      this.DAL.lookups.edit.create(this.state.form)
      .then((response) => {
        console.log(response);
        this.props.displayAlert("Lookup Created", ALERTS.SUCCESS)
        this.props.close();
      })
      .catch((error) => {
        console.log(error);
        this.setState(state => {
          state.isLoaded = true;
          state.error = error;
          return state;
        });
        this.props.displayAlert(error.message, ALERTS.ERROR);
      });
    }
  }

  update = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      this.DAL.lookups.edit.update(this.state.form)
      .then((response) => {
        console.log(response);
        this.props.displayAlert("Lookup Updated", ALERTS.SUCCESS)
        this.props.close();
      })
      .catch((error) => {
        console.log(error);
        this.setState(state => {
          state.isLoaded = true;
          state.error = error;
          return state;
        });
        this.props.displayAlert(error.message, ALERTS.ERROR);
      });
    }
  }

  valueField = (field, fieldName, value) => {
    if(fieldName && fieldName === "Account") {
      return (<Form.Row>
        <Form.Group as={Col} controlId={field}>
          <Form.Label>{fieldName}</Form.Label>
          <Select
            id={field}
            name={field}
            required="true"
            value={value}
            options={LOOKUPS.ACCOUNT}
            onChange={this.handleChange}></Select>
        </Form.Group>
      </Form.Row>)
    }
    else if(fieldName && fieldName === "Id" && this.props.action === ACTIONS.CREATE) {

    }
    else if(fieldName) {
      return (<Form.Row>
        <Form.Group as={Col} controlId={field}>
          <Form.Label>{fieldName}</Form.Label>
          <input
            type="text"
            className="form-control"
            id={field}
            name={field}
            placeholder=""
            value={value}
            onChange={this.handleChange} />
        </Form.Group>
      </Form.Row>)
    }
  }

  render() {
    const { error, isLoaded, data } = this.state;

    const inputDisabled = this.state.inputDisabled ? true : false;

    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="booking-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.state.submitFunction}>
            <div className="row">
            </div>
            <div className="row">
              <div className="col-md-6">
              {this.valueField("value", this.props.lookupTable.value1Name, this.state.form.value)}
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>Name</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder=""
                      value={this.state.form.name}
                      onChange={this.handleChange} required />
                  </Form.Group>
                </Form.Row>
                {this.valueField("value2", this.props.lookupTable.value2Name, this.state.form.value2)}
                {this.valueField("value3", this.props.lookupTable.value3Name, this.state.form.value3)}
                {this.valueField("value4", this.props.lookupTable.value4Name, this.state.form.value4)}
              </div>
            </div>
            <hr className="mb-4" />
            <Submit enabled={this.state.enabled} action={this.state.action} role={this.props.user.role} delete={this.delete}></Submit>
          </Form>
        </div>
      );
    }
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  CREATE: "Submit",
  UPDATE: "Update"
}

function Submit(props) {
  let auth = [ROLES.Admin, ROLES.OfficeManager, ROLES.Accountant];

  if(props.enabled) {
    if(auth.includes(props.role)) {
      if(props.action === ACTIONS.UPDATE) {
        return (
        <div className="form-row"><div className="col"><button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button></div>
        {/* <div className="col"><button disabled={true} type="button" onClick={props.delete} className="btn btn-danger btn-lg btn-block">Delete</button></div> */}
        </div>
        );
      }
      else {
        return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
      }
    }
    else
    {
      return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
    }
  }
  else {
    return (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>);
  }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
import {
    useNavigate,
    useLocation
} from "react-router-dom";

import { useAppContext } from "../../Contexts/AppContext";

import React, { useRef, useState } from 'react';
import './LoginView.scss';

import * as SETTINGS from '../../constants/settings.js';
import * as ALERTS from '../../constants/alerts.js';

import Form from 'react-bootstrap/Form';

import * as ROUTES from '../../constants/routes.js';

export default function LoginView(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [status, setStatus] = useState("");

    let navigate = useNavigate();
    let location = useLocation();
    let { auth } = useAppContext();

    // let from = location.state?.from?.pathname || "/"; // Redirect to previous url
    let from = "/"; // Always direct to home page

    const handleChange = event => {
        const val = event.target.value;
        const name = event.target.name;
        if (name === "username") { setUsername(val); setInputDisabled(false); }
        if (name === "password") { setPassword(val); setInputDisabled(false); }
        return val;
    }

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        }
        else {
            let data = {
                username: username,
                password: password
            };

            setStatus('');
            setAuthenticating(true);
            setInputDisabled(true);

            setTimeout(function () {
                auth.signIn(data, (response) => {
                    // console.log(auth);
                    // console.log(response);
                    if (response !== null && response.success) {
                        setStatus('success');
                        setTimeout(function () {
                            navigate(from, { replace: true });
                        }, 2000);
                    }
                    else {
                        setStatus('failed');
                        setInputDisabled(false);
                    }
                });
            }, 1000);
        }
    }

    return (
        <div id="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-form">
                        <div className="centerrr">
                            <img src="https://sporthelicopters.co.za/wp-content/uploads/2018/10/sport-helicopters-logo-new.png" alt="" />
                            <AuthLoader authenticating={authenticating} status={status} />
                            <Form noValidate validated={validated} className="form-signin" _lpchecked="1" onSubmit={handleSubmit}>
                                <label htmlFor="inputUsername" className="sr-only">Username / Email</label>
                                <input
                                    type="text"
                                    id="inputUsername"
                                    name="username"
                                    className="form-control"
                                    placeholder="Username / Email"
                                    autoFocus
                                    value={username}
                                    onChange={handleChange}
                                    required />
                                <label htmlFor="inputPassword" className="sr-only">Password</label>
                                <input
                                    type="password"
                                    id="inputPassword"
                                    name="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handleChange}
                                    required />
                                <div className="checkbox mb-3">
                                    <label>
                                    </label>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={inputDisabled}>Log in</button>
                            </Form>
                        </div>
                    </div>
                    <div className="col-md-8"></div>
                </div>
            </div>
        </div>
    );
}

function AuthLoader(props) {
    let visible = '';
    if (props.authenticating) {
        visible = 'loader-visible';
    }
    return (
        <div className={`circle-loader-wrapper ${visible}`}>
            <div className={`circle-loader ${props.status}`}>
                <div className="status draw"></div>
            </div>
        </div>
    );
}
import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class UsersDAL {
    constructor(token) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    getAll(callback) {
        axios.get(SETTINGS.API_BASE_URL + "/system/users", this.config)
        .then((response) => {
            if(typeof callback === "function") { callback(response) }
        });
    }
}
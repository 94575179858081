import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class BookingsDAL {
    constructor(token) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    dailyReport(query, callback) {
        axios.get(SETTINGS.API_BASE_URL + `/bookings/today${query}`, this.config)
        .then((response) => {
            if(typeof callback === "function") { callback(response) }
        });
    }

    delete(id) {
        return axios.delete(SETTINGS.API_BASE_URL + `/bookings/` + id, this.config);
    }

    get(page, rowsPerPage, query) {
        return axios.get(SETTINGS.API_BASE_URL + `/bookings/${page}/${rowsPerPage}${query}`, this.config);
    }

    getItem(id) {
        return axios.get(SETTINGS.API_BASE_URL + `/bookings/${id}`, this.config);
    }

    create(booking) {
        return axios.post(SETTINGS.API_BASE_URL + `/bookings`, booking, this.config);
    }

    update(booking) {
        return axios.put(SETTINGS.API_BASE_URL + `/bookings/${booking.id}`, booking, this.config);
    }

    createEvent(event) {
        return axios.post(SETTINGS.API_BASE_URL + `/bookings/event`, event, this.config);
    }

    invoice(id, data) {
        return axios.put(SETTINGS.API_BASE_URL + `/bookings/invoice/${id}`, data, this.config);
    }

    flightNumber() {
        return axios.get(SETTINGS.API_BASE_URL + `/bookings/flightNumber`, this.config);
    }

    board(data) {
        return axios.put(SETTINGS.API_BASE_URL + `/bookings/${data.id}/board/`, data, this.config);
    }

    boardSelected(data) {
        return axios.put(SETTINGS.API_BASE_URL + `/bookings/board-selected`, data, this.config);
    }
}
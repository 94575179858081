import React from 'react';
import './navbar.scss';

import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ReactMarkdown from 'react-markdown'
import changelogPath from '../../content/changelog.md'

function initSidebar(config) {
    if(window.innerWidth < 992) {
        return {
            show: false,
            className: "",
            modal: {
                show: false
            },
            changelog: "",
            config: config
        }
    } else {
        return {
            show: false,
            className: "",
            modal: {
                show: false
            },
            changelog: "",
            config: config
        }
    }
}

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = initSidebar(this.props.config);

        this.toggle = this.toggle.bind(this);
        this.callback = this.callback.bind(this);
        this.checkDimensions = this.checkDimensions.bind(this);
        this.switchTab = this.switchTab.bind(this);
    }

    switchTab(options, ci, c) {
        for (let i = 0; i < options.length; i++) {
            for (let x = 0; x < options[i].options.length; x++) {
                if(i === ci && x === c) {
                    options[i].options[x].active = true;    
                }
                else {
                    options[i].options[x].active = false;
                }
            }
        }

        return options;
    }

    toggle(close = null, ci, i) {

        // let switchedTabs = this.switchTab(this.state.config.options, ci, i);

        this.setState(state => {
            state.config.options = this.switchTab(state.config.options, ci, i);
            // state.config.options[ci].options[i].active = true;
            return state;
        });

        if(close && close === true) {
            if(window.innerWidth < 992) {
                this.setState(state => {
                    state = {
                        show: false,
                        className: ""
                    }
                    return state;
                });
                this.callback(false);
            }
            return;
        }
        
        if(this.state.show) {
            this.setState(state => {
                state = {
                    show: false,
                    className: ""
                }
                return state;
            });
            this.callback(false);
        } else {
            this.setState(state => {
                state = {
                    show: true,
                    className: "show"
                }
                return state;
            });
            this.callback(true);
        }
    }

    callback(show) {
        if(this.props.callback) {
            this.props.callback(show);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkDimensions);
    }

    checkDimensions() {
        if(window.innerWidth < 992) {
            this.setState(state => {
                state = {
                    show: false,
                    className: ""
                }
                return state;
            });
            this.callback(false);
        }
    }

    category(category, ci) {
        const classNames = "nav-link ";
        if(category.options.length > 0) {
            return (
                category.options.map((option, i) => (<li className="nav-item" key={option.label}><Link className={classNames+(this.state.config.options[ci].options[i].active?"active":"")} to={option.path} onClick={() => this.toggle(true, ci, i)}>{option.icon}{option.label}</Link></li>))
            );
        }
        else {
            return;
        }
    }

    render() {
        return (
            <>
            <ul className={"nav navbar-top nav-tabs "+this.state.className}>
                {this.props.config.options.map((category, ci) => (
                    this.category(category, ci)
                ))}
            </ul>
            </>
        );
    }
}

export default NavBar;
import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as LOOKUPS from '../../constants/lookups.js';
import './EventsView.scss';
import clsx from 'clsx'

import { Calendar, Views, Navigate, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import EventCell from "./EventCell";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { HubConnectionBuilder } from '@microsoft/signalr';

import BookingForm from './BookingForm';
import BoardingForm from './BoardingForm';
import PaymentForm from './PaymentForm';
import OptionsForm from './OptionsForm';

import PrivateCharterForm from '../Calendar/BookingForm';
import PrivateCharterBoardingForm from '../Calendar/BoardingForm';
import PrivateCharterPaymentForm from '../Calendar/PaymentForm';
import EventForm from './EventForm';

import SignalR from '../../components/signalR/signalR';

import * as SETTINGS from '../../constants/settings.js';
import * as ALERTS from '../../constants/alerts.js';
import * as VIEW from '../../constants/Views';
import * as LOOKUP from '../../utils/lookup.js';

import { eventsData } from './eventsData';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PassengerManifest from "./PassengerManifest";

import { AppContext } from '../../Contexts/AppContext';
import DataAccessLayer from '../../DAL';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

function initForm(officeId = 0,
    start = moment().toDate(),
    end = moment().add(30, 'minutes').toDate(),
    craft = '') {
    return {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        route_id: 0,
        route_range: 0,
        price_total: 0.0,
        price: {},
        price_merchandise: 0.0,
        paidCreditCard: 0.0,
        paidCash: 0.0,
        paidEFT: 0.0,
        paidInvoice: 0.0,
        paidPayFast: 0.0,
        paidDebt: 0.0,
        commissionPercent: 0,
        commission: 0,
        vat: 0.0,
        passengers: 1,
        voucher_number: 0,
        payment_type_id: 0,
        payment_reference: "",
        sales_agent: "",
        type_id: 0,
        office_id: officeId,
        pilot: "",
        craft: craft,
        flightNumber: "",
        notes: "",
        notesAccounting: "",
        status_id: 0,
        date: start,
        dateStart: start,
        dateEnd: end
    }
}

function initEvent(start, end) {
    return {
        "email": "",
        "phone": "",
        "route_id": 0,
        "route_range": 0,
        "price_total": 0.0,
        "price": {},
        "price_merchandise": 0.0,
        "paidCreditCard": 0.0,
        "paidCash": 0.0,
        "paidEFT": 0.0,
        "paidInvoice": 0.0,
        "paidPayFast": 0.0,
        "paidDebt": 0.0,
        "commissionPercent": 0,
        "commission": 0,
        "vat": 0.0,
        "passengers": 1,
        "voucher_number": 0,
        "payment_type_id": 0,
        "payment_reference": "",
        "sales_agent": "",
        "type_id": 0,
        "office_id": 0,
        "pilot": "",
        "craft": "",
        "flightNumber": "",
        "notes": "",
        "notesAccounting": "",
        "status_id": 0,
        "dateStart": start,
        "dateEnd": end
    }
}

export default class EventsView extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            mobile: false,
            modal: {
                show: false,
                action: "book",
                showOverride: false
            },
            isLoaded: false,
            inputDisabled: false,
            form: {
                "firstname": "",
                "lastname": "",
                "email": "",
                "phone": "",
                "route_id": 0,
                "route_range": 0,
                "price_total": 0.0,
                "price": {},
                "priceKids": 0,
                "price_merchandise": 0.0,
                "price_discount": 0.0,
                "paidCreditCard": 0.0,
                "paidCash": 0.0,
                "paidEFT": 0.0,
                "paidInvoice": 0.0,
                "paidPayFast": 0.0,
                "paidDebt": 0.0,
                "commissionPercent": 0,
                "commission": 0,
                "vat": 0.0,
                "passengers": 1,
                "passengersKids": 0,
                "voucher_number": 0,
                "payment_type_id": 0,
                "payment_reference": "",
                "sales_agent": "",
                "sales_agent_id": "",
                "type_id": 0,
                "office_id": 0,
                "pilot": "",
                "craft": "",
                "flightNumber": "",
                "notes": "",
                "notesAccounting": "",
                "status_id": 0,
                "eventId": 0,
                "externalId": 0,
                "date": "",
                // "scheduleRepeatEvery": 1,
                // "scheduleRepeatFor": 1
            },
            data: [],
            lookups: {
                booking_types: [],
                booking_routes: [],
                booking_offices: [],
                booking_payment_types: [],
                booking_sales_agents: []
            },
            formStatus: VIEW.FORM_STATUS.CREATE,
            formStatusModal: null,
            eventLast: -1,
            selectedEvent: {},
            calendarView: "month",
            calendarDate: moment().toDate(),
            calendarHeight: "85vh",
            filter: {
                resource: "All Craft",
                activeOnly: true
            },
            calendarDateStart: null,
            calendarDateEnd: null,
        };

        this.getData = this.getData.bind(this);
        this.getLookups = this.getLookups.bind(this);
        this.getFlightNumber = this.getFlightNumber.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.handleDateTime = this.handleDateTime.bind(this);
        this.DAL = new DataAccessLayer(this.context.auth.token);
    }

    async componentDidMount() {
        let form = initForm(this.context.auth.user.office_id);
        this.setState(state => { 
            let accountId = this.context.auth.user.accountId;
            state.form = form; 
            state.filter = { activeOnly: true, resource: accountId === 1 ? "All Craft" : "Une Belle Vie" };
            return state; 
        });
        await this.getLookups();
        await this.getData();
        this.checkDimensions();
        window.addEventListener('resize', this.checkDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reload) {
            this.props.toggleReload(false);
            this.getLookups();
            this.getData();
        }
    }

    checkDimensions = () => {
        if (window.innerWidth < 992) {
            this.setState(state => {
                state = {
                    mobile: true
                }
                return state;
            });
        } else {
            this.setState(state => {
                state = {
                    mobile: false
                }
                return state;
            });
        }
    }

    async getFlightNumber() {
        this.DAL.bookings.flightNumber()
            .then((response) => this.setFlightNumber(response.data.flightNumber))
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    // state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    async getLookups() {

        this.setState(state => {
            let crafts = LOOKUP.Crafts(this.context.auth);
            crafts.unshift({"id":"All Craft","name":"All Craft", "val1": "3"});
            state.lookups.booking_offices = LOOKUP.BookingOffices(this.context.auth);
            state.lookups.pilots = LOOKUP.Pilots(this.context.auth);
            state.lookups.booking_routes = LOOKUP.Routes(this.context.auth);
            state.lookups.crafts_filter = crafts;
            state.lookups.crafts = LOOKUP.Crafts(this.context.auth);
            state.lookups.agents_sales = LOOKUP.AgentsSales(this.context.auth);
            return state;
        });

        return;

        const lookups = ["crafts", "pilots", "booking_offices"];
        this.DAL.lookups.get(lookups)
            .then((response) => {
                this.setState(state => {
                    let crafts = LOOKUP.Crafts(this.context.auth);
                    crafts.unshift({"id":"All Craft","name":"All Craft", "val1": "3"});
                    let pilots = LOOKUP.Pilots(this.context.auth);
                    console.log("PILOTS");
                    console.log(pilots);
                    state.lookups = response.data;
                    state.lookups.booking_offices = LOOKUP.BookingOffices(this.context.auth);
                    state.lookups.pilots = LOOKUP.Pilots(this.context.auth);
                    state.lookups.booking_routes = LOOKUP.Routes(this.context.auth); // TODO: Fetch from API instead
                    state.lookups.crafts_filter = crafts;
                    state.lookups.crafts = LOOKUP.Crafts(this.context.auth);
                    return state;
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    async getData(start = null , end = null) {

        let resource = this.state.filter.resource === "All Craft" ? "" : this.state.filter.resource;

        let response = null;

        if ((start === null || end === null) && this.state.calendarDateStart !== null && this.state.calendarDateEnd !== null) {
            response = await this.DAL.events.getAll(resource, this.state.filter.activeOnly, this.state.calendarDateStart, this.state.calendarDateEnd);
        }
        else if(start === null || end === null) {
            response = await this.DAL.events.getAll(resource, this.state.filter.activeOnly);
        }
        else {
            response = await this.DAL.events.getAll(resource, this.state.filter.activeOnly, start, end);
        }

        // let response = { data: eventsData};
        let data = (response.data || []).map(item => {
            item['start'] = moment(item['dateStart']).toDate()
            if((item['range'] > 0 && item['range'] <= 0.5) || item['dateStart'] === item['dateEnd']) {
                item['end'] = moment(item['dateStart']).add(30, 'minutes').toDate()
            }
            else {
                item['end'] = moment(item['dateEnd']).toDate()
            }
            return item
        });

        console.log(data);

        this.setData(data);
        // this.setRowsTotal(response.data.total);
        // this.setLoading(false);
        return true;
    }

    setData = (data) => {
        this.setState(state => {
            state.data = data;
            state.isLoaded = true;
            return state;
        });
    }

    setRowsTotal = (rowsTotal) => {
        this.setState(state => {
            state.rowsTotal = rowsTotal;
            return state;
        });
    }

    async handleDateTime(event) {
        // Check if event is moment object, if so create event object with target.value as mysql timestamp
        if (moment.isMoment(event)) {
            event = {
                target: {
                    value: event.format('YYYY-MM-DD HH:mm:ss'),
                    name: 'date'
                }
            }
        }

        const val = event.target.value;
        const name = event.target.name;

        let date = moment(val).toDate();

        let form = { ...this.state.form };

        if (name === 'dateStart') {
            form.date = date;
            form.dateStart = date;
        } else if (name === 'dateEnd') {
            form.dateEnd = date;
        } else {
            form.date = date;
            form.dateStart = date;
            form.dateEnd = moment(val).add(30, 'minutes').toDate();
        }

        let dayStart = moment().set("hour", 7);
        let dayEnd = moment(date).set("hour", 18);
        
        if (this.context.auth.user.role !== ROLES.Admin && moment(date).isBefore(dayStart)) {
            alert("Cannot book before 07:00 or before today");
            return;
        }

        if (moment(date).isAfter(dayEnd)) {
            alert("Cannot book after 18:00");
            return;
        }

        if (this.state.eventLast > -1) {
            let slice = this.state.eventLast + 1;
            await this.setState((state) => {

                state.form.date = form.date;
                state.form.dateStart = form.dateStart;
                state.form.dateEnd = form.dateEnd;

                state.data = [
                    ...state.data.slice(0, this.state.eventLast),
                    {
                        ...state.data[this.state.eventLast],
                        start: form.dateStart,
                        end: form.dateEnd
                    },
                    ...state.data.slice(slice)
                ];

                return state;
            });
        } else {
            let title = "#" + this.state.data.length + " New Booking";
            this.addEvent(form.dateStart, form.dateEnd, title);
        }
        return val;
    }

    async addEvent(start, end, title) {
        await this.setState({
            data: [
                ...this.state.data,
                {
                    start,
                    end,
                    title,
                },
            ],
            eventLast: this.state.data.length,
            form: {
                ...this.state.form,
                date: start,
                dateStart: start,
                dateEnd: end
            }
        });
    }

    handleChange = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.form[name] = val; return state; });

        // if (name === "firstname") {
        //     let title = val + " " + this.state.form.lastname;
        //     if (this.state.eventLast > -1) { this.setState(state => { state.data[state.eventLast].title = title; return state; }); }
        //     else { this.addEvent(this.state.form.date,moment(this.state.form.date).add(30, "minutes").toDate(),title); }
        // }
        // if (name === "lastname") {
        //     let title = this.state.form.firstname + " " + val;
        //     if (this.state.eventLast > -1) { this.setState(state => { state.data[state.eventLast].title = title; return state; }); }
        //     else { this.addEvent(this.state.form.date,moment(this.state.form.date).add(30, "minutes").toDate(),title); }
        // }

        // if (name === "price") { this.updateCommissionValue(); this.updatePriceTotal(); }
        // if (name === "price_merchandise") { this.updatePriceTotal(); }
        // if (name === "commission") { this.updateCommissionValue(); this.updatePriceTotal(); }
        // if (name === "commission_value") { this.updateCommission(); this.updatePriceTotal(); }
        // if(name === "craft") {
        //     console.log(this.state.lookups.crafts.filter((x) => x.name === val)[0].value4);
        //     let passengers = parseInt(this.state.lookups.crafts.filter((x) => x.name === val)[0].value4);
        //     let event = {
        //       target: {
        //         value: passengers,
        //         name: "passengers"
        //       }
        //     }
        //     this.handleChange(event);
        //   }
          
        return val;
    }

    isDaySelected = (start, slots) => {
        let _start = moment(start);

        let isMidnight = 
        _start.hours() === 0 
        && _start.minutes() === 0 
        && _start.seconds() === 0;

        let result = isMidnight && slots.length === 1;

        // console.log("SELECTED DAY: "+result);

        return result;
    }

    onView = (view) => {
        this.setState(state => {
            state.calendarView = view;
            this.setCalendarHeight(view);
            return state;
        });
    }

    setCalendarHeight = (view) => {
        // this.setState(state => {
        //     state.calendarView = view;
        //     console.log("VIEW: "+view);
        //     if(view === "day") {
        //         state.calendarHeight = "1450px";
        //     } else if(view === "agenda") {
        //         state.calendarHeight = "75vh";
        //     } else {
        //         state.calendarHeight = "75vh";
        //     }
        //     return state;
        // });
    }


    onNavigate = (date) => {
        console.log("NAVIGATE: " + date);
        this.setState(state => {
            state.calendarDate = date;
            return state;
        });
    }

    onRangeChange = (range) => {
        console.log(range);

        let startDate = "";
        let endDate = "";

        if(range.start === undefined) {
            startDate = moment(range[0]).format("YYYY-MM-DD HH:mm:ss");
            endDate = moment(range[1]).add(1, "days").set("hour", 0).format("YYYY-MM-DD HH:mm:ss");
        }
        else {
            startDate = moment(range.start).format("YYYY-MM-DD HH:mm:ss");
            endDate = moment(range.end).format("YYYY-MM-DD HH:mm:ss");
        }

        this.setState(state => {
            state.calendarDateStart = startDate;
            state.calendarDateEnd = endDate;
            return state;
        });

        this.getData(startDate, endDate);
    }

    handleSelect = ({ start, end, slots }) => {
        // let dayStart = moment().set("hour", 5);
        // let dayEnd = moment(start).set("hour", 24);
        //
        // if(moment(start).isBefore(dayStart)) {
        //     alert("Cannot book before 05:00 or before today");
        //     return;
        // }
        //
        // if(moment(start).isAfter(dayEnd)) {
        //     alert("Cannot book after midnight");
        //     return;
        // }

        if(this.isDaySelected(start, slots)) {
            this.setState((state) => {
                state.calendarView = "day";
                state.calendarDate = start;
                this.setCalendarHeight("day");
                return state;
            });
        }
        // Select Event
        else if (this.state.eventLast > -1) {
            let slice = this.state.eventLast + 1;

            this.setState((state) => {
                state.data = [
                    ...state.data.slice(0, this.state.eventLast),
                    {
                        ...state.data[this.state.eventLast],
                        start: start,
                        end: end
                    },
                    ...state.data.slice(slice)
                ];
                state.form.date = start;
                state.form.dateStart = start;
                state.form.dateEnd = end;
                return state;
            });
        }
        // New Event
        else {
            let title = "#" + this.state.data.length + " New Event";

            this.setState({
                modal: { show: true, title: "Booking", action: "book" },
                formStatus: VIEW.FORM_STATUS.OPTIONS,
                data: [
                    ...this.state.data,
                    {
                        start,
                        end,
                        title,
                    },
                ],
                eventLast: this.state.data.length,
                // form: initForm(0, start, end, this.state.filter.resource)
                form: initForm(0, start, end, this.state.filter.resource)
            });
        }
    }

    handleCancel = (x = true) => {
        let confirmed = true;
        if (x) {
            confirmed = window.confirm("Cancel current booking?");
        }
        if (confirmed) {
            this.handleClose();
            if (this.state.eventLast > -1) {
                var data = [...this.state.data];
                data.splice(data.length - 1, 1);
                this.setState((state) => {
                    state.eventLast = -1;
                    state.data = data;
                    state.form = initForm();
                    state.formStatus = VIEW.FORM_STATUS.CREATE;
                    return state;
                });
            } else {
                this.setState((state) => {
                    state.eventLast = -1;
                    state.form = initForm();
                    state.formStatus = VIEW.FORM_STATUS.CREATE;
                    return state;
                });
            }

            return true;
        }
        return false;
    }

    handleSelectEvent = (event) => {

        if (this.state.eventLast > -1) {
            let response = this.handleCancel(true);
            if (!response) { return; }
        }

        // Scenic Flight = 1, Private Charter = 3
        if (event.type_id === 1 || event.type_id === 3) {
            this.setState((state) => {
                state.modal = { show: true, title: "Booking", action: "book" };
                let _event = { ...event };
                state.selectedEvent = { ...event };
                _event.seat_price = _event.price;
                _event.seat_price_kids = _event.priceKids;
                _event.priceKids = 0;
                // _event.eventId = _event.id;
                _event.date = _event.dateStart;
                // _event.passengers = 1;
                // _event.passengersKids = 0;
                // _event.flightNumber = "EB"+_event.id;
                //delete _event.id;
                state.form = _event;
                state.formStatus = VIEW.FORM_STATUS.VIEW_PRIVATE_CHARTER;
                return state;
            });

            return;
        }

        console.log(event);

        // this.setState({ modal: { show: true, title: "Board", action: "board", data: row } });
        this.setState((state) => {
            state.modal = { show: true, title: "Booking", action: "book" };
            let _event = { ...event };
            state.selectedEvent = { ...event };
            _event.seat_price = _event.price;
            _event.seat_price_kids = _event.priceKids;
            _event.priceKids = 0;
            _event.eventId = _event.id;
            _event.date = _event.dateStart;
            _event.passengers = 1;
            _event.passengersKids = 0;
            _event.flightNumber = "EB" + _event.id;
            delete _event.id;
            state.form = _event;
            state.formStatus = VIEW.FORM_STATUS.OPTIONS_EVENT;
            return state;
        });
    }

    handleClose = () => { 
        this.getData(); 
        this.setState((state) => { 
            state.modal.show = false;
            state.modal.showOverride = false;
            state.formStatusModal = null;
            return state;
        }); 
    }

    handleDeleteBooking = () => {
        let id = this.state.form.id > SETTINGS.BOOKING_ID_OFFSET ? this.state.form.id - SETTINGS.BOOKING_ID_OFFSET : this.state.form.id;

        if (!window.confirm('Are you sure you want to delete booking #' + id + '?')) { return; }

        // this.setState(state => {
        //     state.enabled = false;
        //     state.inputDisabled = true;
        //     return state;
        // });

        //this.setState(state => { state.form.status_id = 2; return state; })

        this.DAL.bookings.delete(id).then(() => {
            this.context.alert.send("Booking #" + id + " Deleted", ALERTS.SUCCESS)
            this.handleClose();
        }).catch(error => {
            console.log(error);
            this.setState(state => {
                state.isLoaded = true;
                state.error = error;
                return state;
            });
            this.context.alert.send(error.message, ALERTS.ERROR);
        });
    }

    getForm = (modal = false) => { 
        console.log(this.state.formStatus);

        if(this.state.formStatusModal && modal) {
            switch(this.state.formStatusModal) {
                case VIEW.FORM_STATUS.PASSENGER_MANIFEST:
                    return (<PassengerManifest key={this.state.form.ID} token={this.context.auth.token} lookups={this.state.lookups} data={this.state.data} board={this.board} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBookingEvent} cancelEvent={this.cancelEvent} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime} displayAlert={this.context.alert.send}></PassengerManifest>);
                default:
                    return (<></>);
            }
        }
        else {
            switch (this.state.formStatus) {
                case VIEW.FORM_STATUS.CREATE:
                    return (<BookingForm 
                        key={this.state.form.ID} 
                        lookups={this.state.lookups} 
                        data={this.state.data} 
                        board={this.board} 
                        payment={this.payment} 
                        user={this.context.auth.user} 
                        formData={this.state.form} 
                        status={this.state.formStatus} 
                        saveBooking={this.saveBookingEvent} 
                        cancelEvent={this.cancelEvent} 
                        handleCancel={this.handleCancel} 
                        handleChange={this.handleChange} 
                        handleDateTime={this.handleDateTime}></BookingForm>);
                case VIEW.FORM_STATUS.VIEW:
                    return (<BookingForm
                        key={this.state.form.ID}
                        lookups={this.state.lookups}
                        data={this.state.data}
                        goToBoard={this.goToBoard}
                        payment={this.payment}
                        user={this.context.auth.user}
                        formData={this.state.form}
                        status={this.state.formStatus}
                        saveBooking={this.saveBookingEvent}
                        updateBooking={this.updateBooking}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}></BookingForm>);
                case VIEW.FORM_STATUS.PAYMENT:
                    return (<PaymentForm
                        key={this.state.form.ID}
                        lookups={this.state.lookups}
                        data={this.state.data}
                        back={this.back}
                        payment={this.payment}
                        user={this.context.auth.user}
                        formData={this.state.form}
                        status={this.state.formStatus}
                        saveBooking={this.saveBookingEvent}
                        updateBooking={this.updateBooking}
                        invoiceBooking={this.invoiceBooking}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}
                        paymentPrintSlip={this.paymentPrintSlip}
                        paymentEmailInvoice={this.paymentEmailInvoice}></PaymentForm>);
                case VIEW.FORM_STATUS.BOARD:
                    return (<BoardingForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} back={this.back} getFlightNumber={this.getFlightNumber} board={this.board} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBookingEvent} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></BoardingForm>);
                case VIEW.FORM_STATUS.OPTIONS:
                    return (<OptionsForm navigateTo={this.navigateTo} status={VIEW.FORM_STATUS.OPTIONS} cancelEvent={this.cancelEvent} event={this.state.selectedEvent} displayAlert={this.context.alert.send}></OptionsForm>);
                case VIEW.FORM_STATUS.OPTIONS_EVENT:
                    return (<OptionsForm navigateTo={this.navigateTo} status={VIEW.FORM_STATUS.OPTIONS_EVENT} cancelEvent={this.cancelEvent} event={this.state.selectedEvent} displayAlert={this.context.alert.send}></OptionsForm>);
                case VIEW.FORM_STATUS.CREATE_EVENT:
                    return (<EventForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} board={this.board} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} save={this.saveEvent} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></EventForm>);
                case VIEW.FORM_STATUS.CREATE_PRIVATE_CHARTER:
                    return (<PrivateCharterForm
                        key={this.state.form.ID}
                        lookups={this.state.lookups}
                        data={this.state.data}
                        board={this.board}
                        payment={this.payment}
                        user={this.context.auth.user}
                        formData={this.state.form}
                        status={this.state.formStatus}
                        saveBooking={this.saveBooking}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}></PrivateCharterForm>);
                case VIEW.FORM_STATUS.VIEW_PRIVATE_CHARTER:
                    return (<PrivateCharterForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} goToBoard={this.goToBoard} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBooking} updateBooking={this.updateBooking} handleCancel={this.handleCancel} handleDeleteBooking={this.handleDeleteBooking} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></PrivateCharterForm>);
                case VIEW.FORM_STATUS.PAYMENT_PRIVATE_CHARTER:
                    return (<PrivateCharterPaymentForm
                        key={this.state.form.ID}
                        lookups={this.state.lookups}
                        data={this.state.data}
                        back={this.back}
                        payment={this.payment}
                        user={this.context.auth.user}
                        formData={this.state.form}
                        status={this.state.formStatus}
                        saveBooking={this.saveBooking}
                        updateBooking={this.updateBooking}
                        invoiceBooking={this.invoiceBooking}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}
                        paymentPrintSlip={this.paymentPrintSlip}
                        paymentEmailInvoice={this.paymentEmailInvoice}></PrivateCharterPaymentForm>);
                case VIEW.FORM_STATUS.BOARD_PRIVATE_CHARTER:
                    return (<PrivateCharterBoardingForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} back={this.back} getFlightNumber={this.getFlightNumber} board={this.board} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBooking} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></PrivateCharterBoardingForm>);
                case VIEW.FORM_STATUS.PASSENGER_MANIFEST:
                    return (<PassengerManifest key={this.state.form.ID} token={this.context.auth.token} lookups={this.state.lookups} data={this.state.data} board={this.board} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBookingEvent} cancelEvent={this.cancelEvent} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime} displayAlert={this.context.alert.send}></PassengerManifest>);
                default:
                    return (<></>);
            }
        }
    }

    navigateTo = (view, modal = false) => {
        if (modal) {
            this.navigateToModal(view);
        } else {
            this.setState((state) => {
                state.formStatus = view;
                return state;
            });
        }
    }

    navigateToModal = (view) => {
        this.setState((state) => {
            state.modal.showOverride = true;
            state.formStatusModal = view;
            return state;
        });
    }

    payment = () => {
        this.setState((state) => {
            state.formStatus = VIEW.FORM_STATUS.PAYMENT;
            return state;
        });
    }

    goToBoard = () => {
        this.setState((state) => {
            state.formStatus = VIEW.FORM_STATUS.BOARD;
            return state;
        });
    }

    back = () => {
        if (this.state.eventLast > -1) {
            this.setState((state) => {
                state.formStatus = VIEW.FORM_STATUS.CREATE;
                return state;
            });
        } else {
            this.setState((state) => {
                state.formStatus = VIEW.FORM_STATUS.VIEW;
                return state;
            });
        }
    }

    paymentPrintSlip = (id) => {

        id = this.state.form.id > SETTINGS.BOOKING_ID_OFFSET ? this.state.form.id - SETTINGS.BOOKING_ID_OFFSET : this.state.form.id;

        this.DAL.printService.print(id)
            .then(() => {
                this.context.alert.send("Printing Slip", ALERTS.SUCCESS);
            })
            .catch((error) => {
                console.log(error);
                // this.setState(state => {
                //     state.isLoaded = true;
                //     state.error = error;
                //     return state;
                // });
                // this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    paymentEmailInvoice = () => {
        this.context.alert.send("Emailed invoice and payment saved", ALERTS.SUCCESS);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkDimensions);
    }

    saveBooking = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        let formData = { ... this.state.form };
        formData.type_id = this.context.auth.user.accountId === 2 ? 3 : 1; // 1 - Scenic Flight, 3 - Private Charter
        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        this.DAL.bookings.create(formData)
            .then((response) => {
                this.context.alert.send("Booking Created & Invoiced", ALERTS.SUCCESS)
                this.getData();
                this.setState(state => {
                    state.form = response.data;
                    this.handleCancel(false);
                    return state;
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    saveBookingEvent = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        let formData = { ... this.state.form };

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');
        formData.priceSeat = formData.seat_price;
        formData.priceSeatKids = formData.seat_price_kids;
        formData.price_total = formData.price + formData.priceKids;

        this.DAL.bookings.createEvent(formData)
            .then((response) => {
                this.context.alert.send("Booking Created & Invoiced", ALERTS.SUCCESS);
                // this.paymentPrintSlip(response.data.id);
                // this.getData(1);
                this.setState(state => {
                    state.form = response.data;
                    // this.handleCancel(false);
                    return state;
                });
                this.handleClose();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    saveEvent = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        let typeId = this.context.auth.user.accountId === 2 ? 4 : 2;

        let formData = { ... this.state.form };

        delete formData.firstname;
        delete formData.lastname;
        delete formData.sales_agent;
        delete formData.sales_agent_id;
        formData.type_id = typeId;
        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');
        formData.dateStart = moment(formData.dateStart).format('YYYY-MM-DDTHH:mm:ss');
        formData.dateEnd = moment(formData.dateEnd).format('YYYY-MM-DDTHH:mm:ss');
        formData.priceSeat = formData.seat_price;
        formData.priceSeatKids = formData.seat_price_kids;
        formData.price_total = formData.price + formData.priceKids;

        this.DAL.events.create(formData)
            .then((response) => {
                this.context.alert.send("Event Created", ALERTS.SUCCESS);
                this.getData();
                this.setState(state => {
                    state.form = response.data;
                    this.handleCancel(false);
                    return state;
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    cancelEvent = () => {
        if (this.state.selectedEvent.seatsBooked > 0) {
            this.context.alert.send("This event has bookings, please contact the admin to delete.", ALERTS.ERROR);
            return;
        }

        if (!window.confirm("Are you sure you want to cancel this scheduled event?")) {
            return;
        }

        this.setState(state => {
            state.enabled = false;
            state.isLoaded = false;
            return state;
        });

        this.DAL.events.cancel(this.state.selectedEvent.id)
            .then((response) => {
                this.context.alert.send("Event Cancelled", ALERTS.SUCCESS);
                // this.getData(1);
                this.setState(state => {
                    state.form = response.data;
                    state.isLoaded = true;
                    this.handleCancel(false);
                    return state;
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    invoiceBooking = () => {
        let formData = { ... this.state.form };

        if(formData.invoiced > 0 ) {
            if(!window.confirm(`Are you sure you want to send another invoice for booking #${formData.id} to ${formData.email}?`)) { return; }
        } else {
            if(!window.confirm(`Are you sure you want to invoice booking #${formData.id}. No further updates can be made to the price after invoiced.`)) { return; }
        }

        this.setState(state => {
            state.enabled = false;
            return state;
        });

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        this.DAL.bookings.invoice(formData.id, formData)
            .then((response) => {
                this.context.alert.send("Booking Invoiced", ALERTS.SUCCESS)
                this.getData();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    updateBooking = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        let formData = { ... this.state.form };
        formData.id = formData.id - SETTINGS.BOOKING_ID_OFFSET;
        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        this.DAL.bookings.update(formData)
            .then((response) => {
                this.context.alert.send("Booking Updated", ALERTS.SUCCESS);
                this.getData();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    setFlightNumber = (flightNumber) => {
        this.setState(state => {
            state.form.flightNumber = flightNumber;
            return state;
        });
    }

    board = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        this.setState(state => { state.form.status_id = 2; return state; })
        this.DAL.bookings.board(this.state.form)
            .then((response) => {
                this.context.alert.send("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
                this.back();
                this.getData();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    sideForm = () => {
        let eventDesc = "";
        let route = "";
        let date = moment(this.state.form.date).format('HH:mm');

        if (this.state.form.route_id > 0) {
            let booking_routes = LOOKUP.Routes(this.context.auth);
            let range = this.state.form.route_range !== null && this.state.form.route_range > 0 ? ` (${this.state.form.route_range})` : "";
            route = booking_routes.filter((x) => x.id === parseInt(this.state.form.route_id))[0].name;
        }

        if (date.length > 0 && route.length > 0) { eventDesc = `${route} - ${date}` }

        if (this.state.modal.show && !this.state.mobile) {
            return (<div className="col-md-4 col-lg-4 height100">
                <div className="card mb-4 shadow-sm card-active events-card-side-form">
                    <h6 className="card-header">{this.state.formStatus}<span>{eventDesc}</span></h6>
                    <div className="card-body">
                        {this.getForm()}
                    </div>
                </div>
            </div>);
        }
        else {
            return (<></>);
        }
    }

    handleChangeFilter = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.filter[name] = val; state.isLoaded = false; return state; }, () => this.getData());
        // if(val === "All Craft") {
        //     this.setState(state => { state.filter[name] = ""; state.isLoaded = false; return state; }, () => this.getData(1));
        // } else {
        //     this.setState(state => { state.filter[name] = val; state.isLoaded = false; return state; }, () => this.getData(1));
        // }
    }

    onNotification = (res) => {
        let event = JSON.parse(res);
        event['start'] = moment(event['dateStart']).toDate();
        event['end'] = moment(event['dateEnd']).toDate();
        event['updatedNotif'] = moment().add(7, 'seconds');

        this.setState((state) => {
            let events = [...state.data];
            let eventIndex = events.findIndex((obj => obj.id === event.id));

            if (eventIndex > 0) {
                events[eventIndex] = event;
                state.data = events;
                return state;
            }
        });
    }

    render() {
        const { loading, data, rowsTotal } = this.state;
        const inputDisabled = this.state.inputDisabled ? true : false;
        const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 } };
        const views=['month', 'day', 'agenda'];

        let loadingClass = this.state.isLoaded ? "calendar-loader" : "calendar-loader calendar-loading";

        return (
            <div id="cruise-calendar-view" className="height100">
                <div className="container-fluid height100">
                    <div className="row height100">
                        <div className="col-12 col-md-12 col-lg-8 col-sm-12 col-xs-12 calendar-object height100">
                            <div className="card mb-4 shadow-sm card-active height100" >
                                {/* <h6 className="card-header">Events Calendar</h6> */}
                                <div className="card-body height100">
                                    <Form.Row className="select-vessel">
                                        <InputGroup className="mb-2">
                                            <Select
                                                name="resource"
                                                options={this.state.lookups.crafts_filter}
                                                required="true"
                                                value={this.state.filter.resource}
                                                onChange={this.handleChangeFilter}
                                                disabled={inputDisabled}></Select>
                                        </InputGroup>
                                    </Form.Row>
                                    <DnDCalendar
                                        selectable
                                        views={views}
                                        onSelectEvent={this.handleSelectEvent}
                                        // onSelectSlot={this.handleSelect}
                                        onSelectSlot={(slotInfo) => {
                                            console.log(slotInfo);
                                            this.handleSelect(slotInfo);
                                        }}
                                        // defaultDate={moment().toDate()}
                                        onView={this.onView}
                                        view={this.state.calendarView}
                                        events={this.state.data}
                                        components={{
                                            event: CustomEvent
                                        }}
                                        localizer={localizer}
                                        // onEventDrop={this.onEventDrop}
                                        // onEventResize={this.onEventResize}
                                        // resizable
                                        // style={{ height: this.state.calendarHeight }}
                                        eventPropGetter={eventStyleGetter}
                                        date={this.state.calendarDate}
                                        onNavigate={this.onNavigate}
                                        onRangeChange={this.onRangeChange}
                                    />
                                    <div className={loadingClass}>
                                        <div className="spinner-border" role="status"><span
                                            className="sr-only">Loading...</span></div>
                                        &nbsp;&nbsp;Loading...
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.sideForm()}
                    </div>
                </div>
                <Modal show={(this.state.modal.show && this.state.mobile) || this.state.modal.showOverride} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.getForm(true)}
                    </Modal.Body>
                </Modal>
                <SignalR onNotification={this.onNotification} topics={["ReceiveMessage"]} token={this.context.auth.token}></SignalR>
            </div>
        )
    }
}

function eventStyleGetter(event, start, end, isSelected) {
    let color;
    let style = {};

    if (event.id === null || typeof event.id === 'undefined') {
        color = "#28a745";
    }
    else if (event.seatsTotal > 0 && event.seatsBooked < event.seatsTotal && event.status_id !== 2)
    {
        color = isSelected ? "rgba(0, 123, 255, 0.7)" : "rgba(0, 123, 255, 1)";
    }
    else {
        color = "#999";
    }

    // let ts = moment().add(10 'seconds');
    let ts = moment();

    let boxShadow = isSelected ? "inset 0px 0px 0px 4px #80bdff !important" : "inset 0px 0px 0px 0px #80bdff !important";

    if (typeof event.updatedNotif !== 'undefined' && ts.isBefore(event.updatedNotif)) {
        style = {
            animation: "updated-anim 5s ease-out",
            transition: "background-color 0.5s ease",
            backgroundColor: color,
            boxShadow: boxShadow
        };
    } else {
        style = {
            transition: "background-color 0.5s ease",
            backgroundColor: color,
            boxShadow: boxShadow
        };
    }


    return {
        style: style
    };
}

const CustomEvent = (event) => {
    let start = event.event.start;
    let time = moment(start).format("HHmm");
    let passengers = event.event.passengers;
    // let pax = (typeof passengers === 'undefined' || passengers === 0)  ? null : `PAX ${passengers}`
    let eventId = typeof event.event.id === "undefined" ? event.event.id : "EB" + event.event.id;
    let pax = "";
    let craft = event.event.craft;

    let seatsCnt = event.event.seatsTotal - event.event.seatsBooked;

    let seats = "";
    if(event.event.seatsTotal === 0 && event.event.seatsBooked >= 0) {
        seats = `PAX ${event.event.seatsBooked}`;
    } else if (event.event.seatsTotal > 0 && event.event.seatsBooked >= 0) {
        seats = `PAX ${event.event.seatsBooked}/${event.event.seatsTotal}`;
    }

    let fullybooked = typeof event.event.seatsTotal === 'undefined' || seatsCnt > 0 || event.event.seatsTotal === 0 ? "" : `FULLY BOOKED ${event.event.seatsTotal}`;

    pax = fullybooked.length > 0 ? fullybooked : seats;

    let tags = [eventId, pax, craft];
    let route = "";

    if (event.event.route_id >= 0) {
        // let booking_routes = LOOKUPS.ROUTES;
        // let range = event.event.route_range !== null && event.event.route_range > 0 ? ` (${event.event.route_range})` : "";
        // route = booking_routes.filter((x) => x.id === parseInt(event.event.route_id))[0].name;
        route = event.event.route?.length > 0 ? event.event.route : "New Booking";
    }

    let title = event.title !== null && event.title.length > 0 ? event.title : route;

    return (
        <>
            <div><strong>{title}</strong></div>
            <Tags tags={tags}></Tags>
        </>
    )
}

function Tags(props) {
    let types = ["badge-dark", "badge-info", "badge-warning", "badge-success", "badge-secondary"];
    return (
        <div className="tags">
            {props.tags.map((item, i) => { if (typeof item !== 'undefined' && item.length > 0) { return (<span className={clsx('badge', types[i])} key={i}>{item}</span>); } })}
        </div>
    );
}

function Select(props) {
    if (props.options) {
        if (props.required) {
            return (
                <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
                    <option value="">Choose...</option>
                    {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
                </select>
            );
        }
        else {
            return (
                <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
                    <option value="">Choose...</option>
                    {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
                </select>
            );
        }
    }
    else {
        return (
            <div className="custom-select d-block w-100">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}
import { useState, React, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import UsersDAL from '../../DAL/UsersDAL';

import Nav from 'react-bootstrap/Nav';
import './Users.scss';
import UserForm from './UserForm';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GroupIcon from '@mui/icons-material/Group';

import { Navigate } from "react-router-dom";
import * as ROUTES from '../../constants/routes.js';
import * as ROLES from '../../constants/roles.js';

import { useAppContext } from "../../Contexts/AppContext";

const columns = action => [
    {
        name: '#',
        selector: row => row.id,
        width: "80px",
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
    },
    {
        name: 'Office',
        selector: row => row.office_id,
        sortable: true,
        omit: true
    },
    {
        name: 'Updated',
        selector: row => row.updated_at,
        sortable: true,
    },
    {
        name: 'Created',
        selector: row => row.created_at,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        minWidth: "150px",
        cell: row => <Actions row={row} action={action}></Actions>
    },
];

function Actions(props) {
    return (<><button className="btn btn-sm btn-primary" onClick={() => props.action(props.row, "update")} >Update</button></>);
}

export default function UsersView(props) {
    let app = useAppContext();
    let auth = app.auth;
    let alert = app.alert;
    let usersDAL = new UsersDAL(auth.token);

    const [grid, setGrid] = useState({
        data: [],
        loading: true,
        totalRows: 0,
        perPage: 10,
    });
    const [modal, setModal] = useState({
        show: false,
        action: "book",
        data: null
    });

    useEffect(() => {
        getData();
    }, []);

    let authh = [ROLES.Admin, ROLES.OfficeManager];
    if (!authh.includes(auth.user.role)) {
        return <Navigate to={ROUTES.LOGIN} />
    }

    function getData() {
        let response = usersDAL.getAll((response) => {
            setGrid({
                data: response.data,
                totalRows: 100,
                loading: false,
                perPage: 10
            });
        });
    }

    function action(row, action) {
        if (action) {
            if (action === "update") {
            }
            setModal({ show: true, title: "Update", action: "update", data: row });
        }
    }

    const handleClose = () => { setModal({ show: false }) };
    const create = () => setModal({ show: true, title: "Create User", action: "create", data: null });

    return (
        <div id="bookings-view">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                <h1 className="h4"><GroupIcon></GroupIcon>Users</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group mr-2">
                        <button type="button" className="btn btn-sm btn-secondary" onClick={create}>Add User</button>
                    </div>
                </div>
            </div>
            <DataTable
                className="shadow-sm"
                columns={columns(action)}
                data={grid.data}
                progressPending={grid.loading}
                onRowClicked={action}
            />
            <Modal show={modal.show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserForm action={modal.action} data={modal.data} displayAlert={alert.send} token={auth.token} close={handleClose} />
                </Modal.Body>
            </Modal>
        </div>
    );
}
import { useState } from 'react';
import * as Constants from '../constants'
import * as SETTINGS from '../constants/settings';
import axios from 'axios';

export const AuthProvider = () => {
    let [success, setSuccess] = useState(false);
    let [message, setMessage] = useState(null);
    let [user, setUser] = useState(null);
    let [token, setToken] = useState(null);
    let [settings, setSettings] = useState(null);
    let [lookups, setLookups] = useState(null);
    let [lookupTables, setLookupTables] = useState(null);
    let [views, setViews] = useState(null);

    const signIn = (login, callback) => {
        axios.post(SETTINGS.API_BASE_URL + "/login", login)
            .then((res) => {
                let response = {
                    success: true,
                    message: null,
                    data: res.data
                }
                setSuccess(true);
                setMessage(null);
                setUser(res.data.user);
                setToken(res.data.token);
                setSettings(res.data.settings);
                setLookups(res.data.lookups);
                setLookupTables(res.data.lookupTables);
                initViews(res.data.user);
                if (typeof callback === 'function') { callback(response) }
            })
            .catch((error) => {
                let response = {
                    success: false,
                    message: null,
                    data: null
                }
                setSuccess(false);
                setMessage(null);
                setUser(null);
                setToken(null);
                setSettings(null);
                setLookups(null);
                setLookupTables(null);
                console.log(error);
                if (typeof callback === 'function') { callback(response) }
            });
    };

    const signOut = (callback) => {
        setSuccess(false);
        setMessage(null);
        setUser(null);
        setToken(null);
        setSettings(null);
        setLookups(null);
        setLookupTables(null);
        setViews(null);
        if (typeof callback === 'function') { callback() }
    };

    const switchAccount = (callback) => {
        let config = { headers: { Authorization: `Bearer ${token}` } };

        if (user.accountId === 1) {
            axios.get(SETTINGS.API_BASE_URL + `/system/users/switchaccount/2`, config)
                .then(function (res) {
                    user.accountId = 2;
                    setUser(user);
                    if (typeof callback === "function") { callback(true) }
                })
                .catch(function (error) {
                    if (typeof callback === "function") { callback(false) }
                });
        }
        else if (user.accountId === 2) {
            axios.get(SETTINGS.API_BASE_URL + `/system/users/switchaccount/1`, config)
                .then(function (res) {
                    user.accountId = 1;
                    setUser(user);
                    if (typeof callback === "function") { callback(true) }
                })
                .catch(function (error) {
                    if (typeof callback === "function") { callback(false) }
                });
        }
    }

    const authorizedView = (_user, category, view) => {
        let i = Constants.VIEWS.findIndex(x => x.label === category);
        let _view = Constants.VIEWS[i].options.find(z => z.label === view);
        let accAuth = (_view.accounts && _view.accounts.includes(_user.accountId)) || !_view.accounts ? true : false;
        if (accAuth && (_view.roles === "*" || _view.roles.includes(_user.role))) {
            return true;
        } else {
            return false;
        }
    }

    const initViews = (_user) => {
        let _views = [];

        for (let i = 0; i < Constants.VIEWS.length; i++) {
            let category = Constants.VIEWS[i];
            let optionss = [];
            for (let ii = 0; ii < category.options.length; ii++) {
                let option = category.options[ii];
                if (authorizedView(_user, category.label, option.label)) {
                    optionss.push(option);
                }
            }

            if (optionss.length > 0) {
                category.options = optionss;
                _views.push(category);
            }
        }

        let result = {
            logo: "https://sporthelicopters.co.za/wp-content/uploads/2018/10/sport-helicopters-logo-new.png",
            state: {
                show: false,
                className: ""
            },
            options: _views
        };

        setViews(result);
    }

    const refreshLookups = (callback) => {
        axios.get(SETTINGS.API_BASE_URL + "/lookups/refresh")
            .then((res) => {
                let response = {
                    success: true,
                    message: null,
                    data: res.data
                }
                setSettings(res.data.settings);
                setLookups(res.data.lookups);
                setLookupTables(res.data.lookupTables);
                if (typeof callback === 'function') { callback(response) }
            })
            .catch((error) => {
                let response = {
                    success: false,
                    message: null,
                    data: null
                }
                console.log(error);
                if (typeof callback === 'function') { callback(response) }
            });
    }

    const lookupUpdate = (entity) => {
        let _entity = JSON.parse(entity);
        let index = lookups.findIndex(x => x["id"] === _entity.id);
        
        if (index === -1) { 
            console.log("LOOKUP ADDED");
            lookups.push(_entity); // add
        } else { 
            lookups[index] = _entity; // update
            console.log("LOOKUP UPDATED");
        }

        console.log(lookups);

        setLookups(lookups);
    }

    let values = { user, token, settings, lookups, lookupTables, success, message, views, signIn, signOut, switchAccount, refreshLookups, lookupUpdate };

    return values;
}
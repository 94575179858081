import React from 'react';
import './PassengerManifest.scss';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as VIEW from '../../constants/Views.js';

import "../../components/react-datetime/react-datetime.css";
import Form from 'react-bootstrap/Form';

import DataTable from 'react-data-table-component';
import axios from "axios";
import * as SETTINGS from "../../constants/settings";
import * as LOOKUPS from "../../constants/lookups";
import moment from "moment";
import * as ALERTS from "../../constants/alerts";
import {total} from "../../utils/dates";
import DataAccessLayer from "../../DAL";

export default class PassengerManifest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            rowsTotal: 0,
            rowsPerPage: 100,
            selectedRows: [],
            actionQuery: "",
            filters: [`eventId=${this.props.formData.eventId}`],
        };

        this.getData = this.getData.bind(this);
        this.DAL = new DataAccessLayer(this.props.token);
    }

    async componentDidMount() {
        this.getData(1);
    }

    getData(page) {
        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?"+query : "";

        this.DAL.bookings.get(page, this.state.rowsPerPage, query).then((response) => {
            this.setState(state => {
                state.selectedRows = response.data.data.filter(function(obj) {
                    if (obj.status_id === 2) {
                        return true;
                    } else {
                        return false;
                    }
                }).map((obj) => obj.id);
                state.rowsTotal = response.data.total;
                state.data = response.data.data;
                state.loading = false;
                return state;
            });
        });
    }

    boardSelected = (e) => {
        e.preventDefault();

        if(this.state.selectedRows.length > 0) {
            this.setState(state => {
                state.enabled = false;
                state.loading = true;
                return state;
            });
    
            this.DAL.bookings.boardSelected(this.state.selectedRows)
                .then((response) => {
                    this.props.displayAlert("Selection Boarded", ALERTS.SUCCESS)
                    this.setState(state => {
                        state.enabled = true;
                        state.loading = false;
                        return state;
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState(state => {
                        state.isLoaded = true;
                        state.error = error;
                        return state;
                    });
                    this.props.displayAlert(error.message, ALERTS.ERROR);
                });
        }
        else {
            window.alert("No rows selected");
        }
    }



    boardingFinalisation = (e) => {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to finalise the boarding process? You wont be able to make any changes after this.");

        if(confirmed) {
            this.setState(state => {
                state.enabled = false;
                state.loading = true;
                return state;
            });

            let data = { ...this.props.formData };

            data.id = this.props.formData.eventId;

            this.DAL.bookings.board(data)
                .then((response) => {
                    this.props.displayAlert(`#${this.props.formData.eventId} Boarding Finalised`, ALERTS.SUCCESS)
                    this.setState(state => {
                        state.enabled = true;
                        state.loading = false;
                        return state;
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState(state => {
                        state.isLoaded = true;
                        state.error = error;
                        return state;
                    });
                    this.props.displayAlert(error.message, ALERTS.ERROR);
                });
        }
    }

    handleChangeRowsSelected = ({ selectedRows }) => {
        let arr = selectedRows.map((obj) => obj.id);
        this.setState(state => {
            state.selectedRows = arr;
        });
    };

    render() {
        const { loading, data, rowsTotal, selectedRows } = this.state;
        const rowSelectCriteria = row => row.status_id === 2;

        let totalAdults = 0;
        let totalKids = 0;
        let totalToddlers = 0;
        let totalGuides = 0;

        data.map((obj) => { totalAdults += obj.passengers; totalKids += obj.passengersKids; totalToddlers += obj.passengersToddlers; totalGuides += obj.passengersGuides; })

        let totalPax = totalAdults + totalKids + totalToddlers + totalGuides;

        return (
            <Form className="passenger-manifest-form" onSubmit={this.boardSelected}>
                <DataTable
                    className="datagrid"
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    selectableRows
                    selectableRowSelected={rowSelectCriteria}
                    selectableRowDisabled={rowSelectCriteria}
                    onSelectedRowsChange={this.handleChangeRowsSelected}
                />
                <div className="passenger-manifest-totals"><strong>TOTAL PAX<br/>{totalPax}</strong><br/><small>Adults: {totalAdults}, Kids: {totalKids}, Toddlers: {totalToddlers}, Guides: {totalGuides}</small></div>
                <Actions loading={loading} rowsTotal={rowsTotal} boardSelected={this.boardSelected} selectedRows={selectedRows} />
            </Form>
        )
    }
}

function Actions (props) {
    const { loading, rowsTotal, selectedRows } = props;
    if(!loading && rowsTotal > 0 && selectedRows.length > 0) {
        return (
        <>
        <button type='submit' className="btn btn-primary btn-block btn-lg">Board Selected</button>
        {/* <button type="submit" className="btn btn-success btn-block btn-lg">Finalise Boarding</button> */}
        </>);
    }
    else if(!loading && rowsTotal > 0 && selectedRows.length === 0) {
        return (<>
        <button type='submit' className="btn btn-primary btn-block btn-lg">Board Selected</button>
        {/* <div className="alert alert-warning" role="alert">
            Select bookings to board
        </div> */}
        {/* <button type="submit" className="btn btn-success btn-block btn-lg">Finalise Boarding</button> */}
        </>);
    }
    else if(loading) {
        return (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>);
    }
    else {
        return (<></>);
    }
}

const columns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.firstname + ' ' + row.lastname,
        sortable: true,
    },
    {
        name: 'Adults',
        selector: row => row.passengers,
        sortable: true,
    },
    {
        name: 'Kids',
        selector: row => row.passengersKids,
        sortable: true,
    },
    {
        name: 'Toddlers',
        selector: row => row.passengersToddlers,
        sortable: true,
    },
    {
        name: 'Guides',
        selector: row => row.passengersGuides,
        sortable: true,
    }
];

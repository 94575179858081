import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as LOOKUPS from '../../constants/lookups.js';
import './CalendarView.scss';
import clsx from 'clsx'

import { AppContext } from '../../Contexts/AppContext';
import DataAccessLayer from '../../DAL';

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import EventCell from "./EventCell";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import BookingForm from './BookingForm';
import BoardingForm from './BoardingForm';
import PaymentForm from './PaymentForm';

import * as SETTINGS from '../../constants/settings.js';
import * as BOOKING_FORM from '../../constants/bookingForm.js';
import axios from 'axios';

import * as ALERTS from '../../constants/alerts.js';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

export default class CalendarView extends React.Component { 
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            modal: {
                show: false,
                action: "book"
            },
            isLoaded: false,
            inputDisabled: false,
            form: {
                "firstname": "",
                "lastname": "",
                "email": "",
                "phone": "",
                "route_id": 0,
                "route_range": 0,
                "price_total": 0.0,
                "price": {},
                "price_merchandise": 0.0,
                "paidCreditCard": 0.0,
                "paidCash": 0.0,
                "paidEFT": 0.0,
                "paidInvoice": 0.0,
                "paidPayFast": 0.0,
                "paidDebt": 0.0,
                "commissionPercent": 0,
                "commission": 0,
                "vat": 0.0,
                "passengers": 1,
                "voucher_number": 0,
                "payment_type_id": 0,
                "payment_reference": "",
                "sales_agent": "",
                "sales_agent_id": "",
                "type_id": 0,
                "office_id": 0,
                "pilot": "",
                "craft": "",
                "flightNumber": "",
                "invoiced": 0,
                "notes": "",
                "notesAccounting": "",
                "status_id": 0,
                "date": moment().toDate()
            },
            data: [],
            lookups: {
                booking_types: [],
                booking_routes: LOOKUPS.ROUTES.filter(x => x.id  < 11 || x.id > 20), // TODO: Fetch from API instead
                booking_offices: [],
                booking_payment_types: [],
                booking_sales_agents: []
            },
            formStatus: BOOKING_FORM.STATUS.CREATE,
            eventLast: -1
        };

        this.getData = this.getData.bind(this);
        this.getLookups = this.getLookups.bind(this);
        this.getFlightNumber = this.getFlightNumber.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.handleDateTime = this.handleDateTime.bind(this);
        this.DAL = new DataAccessLayer(this.context.auth.token);
    }

    async componentDidMount() {
        let form = BOOKING_FORM.initForm(this.context.auth.user.office_id);
        this.setState(state => { state.form = form; return state; });
        await this.getLookups();
        await this.getData(1);
    }

    componentDidUpdate(prevProps) {
        // TODO: Implement reload function
        // if(this.props.reload) {
        //     this.props.toggleReload(false);
            // this.getLookups();
            // this.getData(1);
        // }
    }

    async getFlightNumber() {
        let config = { headers: { Authorization: `Bearer ${this.context.auth.token}` } };
        await axios.get(SETTINGS.API_BASE_URL + "/bookings/flightnumber", config)
        .then((response) => this.setFlightNumber(response.data.flightNumber))
        .catch((error) => {
        console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.context.alert.send(error.message, ALERTS.ERROR);
        });
    }

    async getLookups() {
        let config = { headers: { Authorization: `Bearer ${this.context.auth.token}` } };
        const lookups = ["crafts", "pilots", "booking_offices", "agents_sales"];

        await axios.post(SETTINGS.API_BASE_URL + "/lookups/select", lookups, config)
          .then((response) => {
            this.setState(state => {
              state.lookups = response.data;
              state.lookups.booking_routes = LOOKUPS.ROUTES.filter(x => x.id  < 11 || x.id > 20); // TODO: Fetch from API instead
              state.lookups.crafts = response.data.crafts.filter(x => x.value3  === '1');
              return state;
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState(state => {
              state.error = error;
              return state;
            });
            this.context.alert.send(error.message, ALERTS.ERROR);
          });  
    }

    async getData(page) {
        let query = 1
        // this.setLoading(true);
    
        let config = { headers: { Authorization: `Bearer ${this.context.auth.token}` } };
        // let query = this.state.filters.join('&');
        query = query !== "" ? "&"+query : "";
        const response = await axios.get(SETTINGS.API_BASE_URL + `/calendar`, config);
        let data = (response.data || []).map(item => {
            item['start']  = moment(item['date']).toDate()
            item['end'] = moment(item['date']).add((item['range'] ? item['range'] * 60 : 30), 'minutes').toDate()
            return item
          });     
          
        // console.log(data);

        this.setData(data);
        // this.setRowsTotal(response.data.total);
        // this.setLoading(false);
        return true;
    }

    setData = (data) => {
        this.setState(state => {
            state.data = data;
            return state;
        });
    }

    setRowsTotal = (rowsTotal) => {
        this.setState(state => {
            state.rowsTotal = rowsTotal;
            return state;
        });
    }

    async handleDateTime(val) {
        let start = moment(val).toDate();
        let end = moment(val).add(30, 'minutes').toDate();

        let dayStart = moment().set("hour", 7);
        let dayEnd = moment(start).set("hour", 18);

        if(moment(start).isBefore(dayStart)) {
            alert("Cannot book before 07:00 or before today");
            return;
        }

        if(moment(start).isAfter(dayEnd)) {
            alert("Cannot book after 18:00");
            return;
        }

        if(this.state.eventLast > -1) {
            let slice = this.state.eventLast + 1;
            await this.setState((state) => {
                state.data = [
                    ...state.data.slice(0, this.state.eventLast),
                    {
                        ...state.data[this.state.eventLast],
                        start: start,
                        end: end
                    },
                    ...state.data.slice(slice)
                ];
                state.form.date = start;
                return state;
            });
        } else {
            let title = "#" + this.state.data.length + " New Booking";
            this.addEvent(start,end,title);
        }
        return val;
    }

    async addEvent(start, end, title) {
        await this.setState({
            data: [
                ...this.state.data,
                {
                    start,
                    end,
                    title,
                },
            ],
            eventLast: this.state.data.length,
            form: {
                ...this.state.form,
                date: start
            }
        });
    }

    handleChange = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.form[name] = val; return state; });

        if (name === "firstname") { 
            let title = val + " " + this.state.form.lastname;
            if (this.state.eventLast > -1) { this.setState(state => { state.data[state.eventLast].title = title; return state; }); }
            else { this.addEvent(this.state.form.date,moment(this.state.form.date).add(30, "minutes").toDate(),title); }
        }
        if (name === "lastname") { 
            let title = this.state.form.firstname + " " + val;
            if (this.state.eventLast > -1) { this.setState(state => { state.data[state.eventLast].title = title; return state; }); }
            else { this.addEvent(this.state.form.date,moment(this.state.form.date).add(30, "minutes").toDate(),title); }
        }

        // if (name === "price") { this.updateCommissionValue(); this.updatePriceTotal(); }
        // if (name === "price_merchandise") { this.updatePriceTotal(); }
        // if (name === "commission") { this.updateCommissionValue(); this.updatePriceTotal(); }
        // if (name === "commission_value") { this.updateCommission(); this.updatePriceTotal(); }
        return val;
    }

    async handleSelect({ start, end }) {
        let dayStart = moment().set("hour", 7);
        let dayEnd = moment(start).set("hour", 18);

        if(moment(start).isBefore(dayStart)) {
            alert("Cannot book before 07:00 or before today");
            return;
        }

        if(moment(start).isAfter(dayEnd)) {
            alert("Cannot book after 18:00");
            return;
        }

        if (this.state.eventLast > -1) {
            let slice = this.state.eventLast + 1;

            await this.setState((state) => {
                state.data = [
                    ...state.data.slice(0, this.state.eventLast),
                    {
                        ...state.data[this.state.eventLast],
                        start: start,
                        end: end
                    },
                    ...state.data.slice(slice)
                ];
                state.form.date = start;
                return state;
            });
        }
        else {
            let title = "#" + this.state.data.length + " New Booking";

            await this.setState((state) => {
                state.form = BOOKING_FORM.initForm();
                state.formStatus = BOOKING_FORM.STATUS.CREATE;
                this.addEvent(start,end,title);
                return state;
            });
        }
    }

    handleCancel = (x = true) => {
        let confirmed = true;
        if(x) {
            confirmed = window.confirm("Cancel current booking?");
        }
        if(confirmed) {
            if(this.state.eventLast > -1) {
                var data = [...this.state.data];
                data.splice(data.length-1, 1);
                this.setState((state) => {
                    state.eventLast = -1;
                    state.data = data;
                    state.form = BOOKING_FORM.initForm();
                    state.formStatus = BOOKING_FORM.STATUS.CREATE;
                    return state;
                });
            } else {
                this.setState((state) => {
                    state.eventLast = -1;
                    state.form = BOOKING_FORM.initForm();
                    state.formStatus = BOOKING_FORM.STATUS.CREATE;
                    return state;
                });
            }

            return true;
        }  
        return false;        
    }

    handleSelectEvent = (event) => {
        if(this.state.eventLast > -1) {
            let response = this.handleCancel(true);
            if(!response) { return; }
        }

        console.log(event);

        this.setState((state) => {
            state.form = event;
            state.form.date = moment(event.date).toDate();
            state.formStatus = BOOKING_FORM.STATUS.VIEW;
            return state;
        });
    }

    getForm = () => {
        switch(this.state.formStatus) {
            case BOOKING_FORM.STATUS.CREATE:
                return (<BookingForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} board={this.board} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBooking} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></BookingForm>);
            case BOOKING_FORM.STATUS.VIEW:
                return (<BookingForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} goToBoard={this.goToBoard} payment={this.payment} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBooking} updateBooking={this.updateBooking} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDeleteBooking={this.handleDeleteBooking} handleDateTime={this.handleDateTime}></BookingForm>);
            case BOOKING_FORM.STATUS.PAYMENT:
                return (<PaymentForm
                    key={this.state.form.ID}
                    lookups={this.state.lookups}
                    data={this.state.data}
                    back={this.back}
                    payment={this.payment}
                    user={this.context.auth.user}
                    formData={this.state.form}
                    status={this.state.formStatus}
                    saveBooking={this.saveBooking}
                    updateBooking={this.updateBooking}
                    invoiceBooking={this.invoiceBooking}
                    handleCancel={this.handleCancel}
                    handleChange={this.handleChange}
                    handleDateTime={this.handleDateTime}
                    paymentPrintSlip={this.paymentPrintSlip}
                    paymentEmailInvoice={this.paymentEmailInvoice}></PaymentForm>);
            case BOOKING_FORM.STATUS.BOARD:
                return (<BoardingForm key={this.state.form.ID} lookups={this.state.lookups} data={this.state.data} back={this.back} getFlightNumber={this.getFlightNumber} board={this.board} user={this.context.auth.user} formData={this.state.form} status={this.state.formStatus} saveBooking={this.saveBooking} handleCancel={this.handleCancel} handleChange={this.handleChange} handleDateTime={this.handleDateTime}></BoardingForm>);
            default:
                return(<></>);
        }
    }

    payment = () => {
        this.setState((state) => {
            state.formStatus = BOOKING_FORM.STATUS.PAYMENT;
            return state;
        });
    }

    goToBoard = () => {
        this.setState((state) => {
            state.formStatus = BOOKING_FORM.STATUS.BOARD;
            return state;
        });
    }

    back = () => {
        if(this.state.eventLast > -1) {
            this.setState((state) => {
                state.formStatus = BOOKING_FORM.STATUS.CREATE;
                return state;
            });
        } else {
            this.setState((state) => {
                state.formStatus = BOOKING_FORM.STATUS.VIEW;
                return state;
            });
        }
    }

    paymentPrintSlip = () => {
        this.context.alert.send("Printing slip (Coming Soon)", ALERTS.SUCCESS);
    }

    paymentEmailInvoice = () => {
        this.context.alert.send("Emailed invoice and payment saved", ALERTS.SUCCESS);
    }

    saveBooking = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });
    
        let config = {
            headers: { Authorization: `Bearer ${this.context.auth.token}` }
        };

        let formData = {... this.state.form};

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        axios.post(SETTINGS.API_BASE_URL + "/bookings", formData, config)
        .then((response) => {
            this.context.alert.send("Booking Created & Invoiced", ALERTS.SUCCESS)
            this.getData(1);
            this.setState(state => {
                state.form = response.data;
                this.handleCancel(false);
                return state;
            });
        })
        .catch((error) => {
            console.log(error);
            this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
            });
            this.context.alert.send(error.message, ALERTS.ERROR);
        });
    }

    invoiceBooking = () => {
        let config = {
            headers: { Authorization: `Bearer ${this.context.auth.token}` }
        };

        let formData = {... this.state.form};

        if(formData.invoiced > 0 ) {
            if(!window.confirm(`Are you sure you want to send another invoice for booking #${formData.id} to ${formData.email}?`)) { return; }
        } else {
            if(!window.confirm(`Are you sure you want to invoice booking #${formData.id}. No further updates can be made to the price after invoiced.`)) { return; }
        }

        this.setState(state => {
            state.enabled = false;
            return state;
        });

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        axios.put(SETTINGS.API_BASE_URL + "/bookings/invoice/" + formData.id, formData, config)
            .then((response) => {
                this.context.alert.send("Booking Invoiced", ALERTS.SUCCESS)
                this.getData(1);
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    updateBooking = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });
    
        let config = {
            headers: { Authorization: `Bearer ${this.context.auth.token}` }
        };

        let formData = {... this.state.form};

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        axios.put(SETTINGS.API_BASE_URL + "/bookings/" + formData.id, formData, config)
        .then((response) => {
            this.context.alert.send("Booking Updated", ALERTS.SUCCESS)
            this.getData(1);
        })
        .catch((error) => {
            console.log(error);
            this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
            });
            this.context.alert.send(error.message, ALERTS.ERROR);
        });
    }

    setFlightNumber = (flightNumber) => {
        this.setState(state => {
            state.form.flightNumber = flightNumber;
            return state;
          });
    }

    board = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });
        
        let config = {
        headers: { Authorization: `Bearer ${this.context.auth.token}` }
        };

        this.setState(state => { state.form.status_id = 2; return state; })

        axios.put(SETTINGS.API_BASE_URL + "/bookings/" + this.state.form.id + "/board", this.state.form, config)
        .then((response) => {
            this.context.alert.send("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
            this.back();
            this.getData(1);
        })
        .catch((error) => {
            console.log(error);
            this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
            });
            this.context.alert.send(error.message, ALERTS.ERROR);
        });
    }

    handleDeleteBooking = () => {

        let id = this.state.form.id > SETTINGS.BOOKING_ID_OFFSET ? this.state.form.id - SETTINGS.BOOKING_ID_OFFSET : this.state.form.id;

        if(!window.confirm('Are you sure you want to delete booking #'+id+'?')) { return; }

        // this.setState(state => {
        //     state.enabled = false;
        //     state.inputDisabled = true;
        //     return state;
        // });

        let config = {
            headers: { Authorization: `Bearer ${this.context.auth.token}` }
        };

        //this.setState(state => { state.form.status_id = 2; return state; })

        axios.delete(SETTINGS.API_BASE_URL + "/bookings/" + id, config)
            .then((response) => {
                console.log(response);
                this.context.alert.send("Booking #" + id + " Deleted", ALERTS.SUCCESS)
                this.handleClose();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    render() {
        const { loading, data, rowsTotal } = this.state;
        const inputDisabled = this.state.inputDisabled ? true : false;
        const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};

        return (
            <div id="calendar-view">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8 col-md-8 col-sm-12 col-xs-12 calendar-object">
                        <div className="card mb-4 shadow-sm card-active" >
                        <h6 className="card-header">Calendar</h6>
                            <div className="card-body">
                                <DnDCalendar
                                selectable
                                onSelectEvent={this.handleSelectEvent}
                                onSelectSlot={(slotInfo) => {
                                    console.log(slotInfo);
                                    this.handleSelect();
                                }}
                                defaultDate={moment().toDate()}
                                defaultView="month"
                                events={this.state.data}
                                components={{
                                    event: CustomEvent
                                }}
                                localizer={localizer}
                                // onEventDrop={this.onEventDrop}
                                // onEventResize={this.onEventResize}
                                // resizable
                                style={{ height: "100vh" }}
                                eventPropGetter={eventStyleGetter}
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="card mb-4 shadow-sm card-active" >
                        <h6 className="card-header">{this.state.formStatus}</h6>
                            <div className="card-body">
                                {this.getForm()}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

function eventStyleGetter(event, start, end, isSelected) {
    let color;
    
    if (event.id === null || typeof event.id === 'undefined') {
        color = "#28a745";
    }
    else if (event.boarded === null || typeof event.boarded === 'undefined' || event.boarded === "") {
        color = "#007bff";
    }
    else {
        color = "#999";
    }


    var style = {
        backgroundColor: color
    };
    return {
        style: style
    };
}

const CustomEvent = (event) => {
    let start = event.event.start;
    let time = moment(start).format("HHmm");
    let passengers = event.event.passengers;
    let pax = (typeof passengers === 'undefined' || passengers === 0)  ? null : `PAX ${passengers}`;
    let craft = event.event.craft;

    let tags = [pax, craft];
    let route = "";

    if(event.event.route_id >= 0) {
        let booking_routes = LOOKUPS.ROUTES;
        let range = event.event.route_range !== null && event.event.route_range > 0 ? ` (${event.event.route_range})` : "";
        route = booking_routes.filter((x) => x.id === parseInt(event.event.route_id))[0].name;
        route = route.length > 0 ? ` - ${route}` : " - New Booking";
    }

    return ( 
        <>
        <div><strong>{time}H{route}</strong></div>
        <Tags tags={tags}></Tags>
        </>
    ) 
  }

function Tags(props) {
    let types = ["badge-info", "badge-warning"];
      return (
        <div className="tags">
          {props.tags.map((item, i) => <span className={clsx('badge', types[i])} key={i}>{item}</span>)}
        </div>
      );
  }
import React from 'react';
import { AppContext } from '../../Contexts/AppContext.js';
import BookingsDAL from '../../DAL/BookingsDAL';
import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import './DailyReportView2.scss';
import TableChart from '@mui/icons-material/TableChart';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import EventIcon from '@mui/icons-material/Event';
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import FilterForm from "../../components/FilterForm/FilterForm";
import * as ALERTS from "../../constants/alerts";
import * as ROLES from "../../constants/roles";

export default class DailyReport2 extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: "",
            data: [],
            loading: true,
            rowsTotal: 0,
            rowsPerPage: 10,
            actionQuery: "",
            filters: [],
            form: {
                groupBy: 1,
                boardedOnly: true,
                dateTimeStart: moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0
                }).toDate(),
                dateTimeEnd: moment().set({
                    hour: 23,
                    minute: 59,
                    second: 59
                }).toDate()
            },
            modal: {
                show: false,
                action: "book",
                data: null
            },
            date: "",
            day: ""
        };
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.BookingsDAL = new BookingsDAL(this.context.auth.token);
    }

    print = () => window.print();

    async componentDidMount() {
        this.setLoading(true);
        await this.initState();
        this.setFilters();
        this.setLoading(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reload) {
            this.props.toggleReload(false);
            this.setFilters();
        }
    }

    async initState() {
        let action = (new URLSearchParams(window.location.search)).get("action");

        let title = "Report 2";
        await this.setState({ title: title });
    }

    async getData(page) {
        this.setLoading(true);
        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this.BookingsDAL.dailyReport(query, (response) => {
            // console.log(response.data);
            this.setData(response.data);
            this.setLoading(false);
        });

        return true;
    }

    setData(data) {
        this.setState(state => {
            state.data = data;
            return state;
        });
    }

    handleDateTime = (val, name) => {
        let start = this.state.form.dateTimeStart;
        let end = this.state.form.dateTimeEnd;
        let value = moment(val);

        if (name === "dateTimeStart") {
            start = value;
            if (start.isAfter(end)) {
                end = moment(val).add(1, 'days');
            }
        } else if (name === "dateTimeEnd") {
            end = value;
            if (end.isBefore(start)) {
                start = moment(val).add(-1, 'days');
            }
        }

        this.setState(state => {
            state.form.dateTimeStart = start;
            state.form.dateTimeEnd = end;
            return state;
        });
        return val;
    }

    handleChange(event) {
        let val = event.target.value;
        let name = event.target.name;
        if (name === "boardedOnly") {
            val = event.target.checked ? true : false;
        }
        this.setState(state => { state.form[name] = val; return state; });
        return val;
    }

    setLoading(loading) {
        this.setState(state => {
            state.loading = loading;
            return state;
        });
    }

    updateFilters = (event) => {
        event.preventDefault();
        this.setFilters();
        this.handleClose();
        if (this.props.displayAlert) {
            this.props.displayAlert("Updated filters", ALERTS.SUCCESS);
        } else {
            // console.warn("displayAlert is not defined");
        }
    }

    async setFilters() {
        let result = [];
        let keys = Object.keys(this.state.form);
        let form = this.state.form;

        keys.forEach(function (key) {
            let val = form[key];
            if (val && val !== '' && val !== 0) {
                if (key === 'dateTimeStart' || key === 'dateTimeEnd') { val = encodeURI(moment(val).format("YYYY-MM-DDTHH:mm:ss")); }
                result.push(`${key}=${val}`);
            }
        });

        this.setState(state => { state.filters = result; return state; }, () => {
            this.getData(1);
        });
    }

    ModalBody = () => {
        if (this.state.modal.action === "filter") {
            return <FilterForm action={this.state.modal.action} handleChange={this.handleChange} handleDateTime={this.handleDateTime} lookups={this.state.lookups} data={this.state.modal.data} displayAlert={this.props.displayAlert} form={this.state.form} token={this.props.token} user={this.context.auth.user} close={this.handleClose} updateFilters={this.updateFilters} />
        }
    }

    handleClose = () => { this.setState({ modal: { show: false } }) };

    filter = () => this.setState(state => { state.modal = { show: true, title: "Filter", action: "filter", data: null }; return state; });

    render() {
        const { loading, data, rowsTotal } = this.state;

        let today = moment.isMoment(this.state.form.dateTimeStart) ? this.state.form.dateTimeStart.toDate() : this.state.form.dateTimeStart;
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let endDate = moment.isMoment(this.state.form.dateTimeEnd) ? this.state.form.dateTimeEnd.toDate() : this.state.form.dateTimeEnd;
        let ddEnd = String(endDate.getDate()).padStart(2, '0');
        let mmEnd = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyyEnd = endDate.getFullYear();

        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let day = days[today.getDay()];

        let date = `${dd}/${mm}/${yyyy}`;
        let dateEnd = `${ddEnd}/${mmEnd}/${yyyyEnd}`;

        let dateTitle = `DATE: ${date} DAY: ${day}`;

        if (ddEnd > dd) {
            dateTitle = `DATE: ${date} to ${dateEnd}`;
        }

        let daily = dd === ddEnd ? 'DAILY ' : '';

        let name = "";
        if (this.context.auth.user.role === ROLES.OfficeStaff) {
            name = <> FOR <b className="daily-report-person">{this.context.auth.user.name.toUpperCase()}</b></>;
        }

        return (
            <div id="daily-report-view-2">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                    <h1 className="h4"><TableChart></TableChart>{this.state.title}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="bd-example">
                            <div className="btn-group mr-2">
                                <button type="button" className="btn btn-sm btn-secondary" onClick={this.print}>Print</button>
                                <OverlayTrigger
                                    key="filter"
                                    placement="bottom"
                                    overlay={<Tooltip id={`tooltip-bottom`}>Filter</Tooltip>}>
                                    <button type="button" className="btn btn-sm btn-secondary" onClick={this.filter}>
                                        <FilterListIcon />&nbsp;
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card flight-report-card">
                    <div className="card-body">
                        <p><strong className="float-left">SPORT AVIATION: {daily}REPORT OF TURNOVER{name}</strong><strong className="float-right">{dateTitle}</strong></p>
                        <FlightReportGenerator data={this.state.data} groupBy={this.state.form.groupBy} loading={this.state.loading}></FlightReportGenerator>
                    </div>
                </div>
                <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.ModalBody()}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function FlightReportGenerator(props) {
    let grouped = {};
    let crafts = [];
    let groupByCss = "group-craft";
    let groupBy = parseInt(props.groupBy);

    if (groupBy === 1) { groupByCss = "group-craft"; }
    if (groupBy === 2) { groupByCss = "group-pilot"; }
    if (groupBy === 3) { groupByCss = "group-agent"; }
    if (groupBy === 4) { groupByCss = "group-event"; }

    if (props.loading) {
        return (<div className={`table-wrapper ${groupByCss}`}><div className="flight-report-status">Loading...</div></div>)
    } else if (props.data.length < 1) {
        return (<div className={`table-wrapper ${groupByCss}`}><div className="flight-report-status">No completed bookings for today.</div></div>)
    }

    if (groupBy === 1) {
        props.data.forEach(value => {
            if (crafts.indexOf(value.craft) === -1) {
                crafts.push(value.craft);
                grouped[value.craft] = [];
                grouped[value.craft].push(value);
            } else {
                grouped[value.craft].push(value);
            }
        });
    } else if (groupBy === 2) {
        props.data.forEach(value => {
            if (crafts.indexOf(value.pilot) === -1) {
                crafts.push(value.pilot);
                grouped[value.pilot] = [];
                grouped[value.pilot].push(value);
            } else {
                grouped[value.pilot].push(value);
            }
        });
    } else if (groupBy === 3) {
        props.data.forEach(value => {
            if (crafts.indexOf(value.agent) === -1) {
                crafts.push(value.agent);
                grouped[value.agent] = [];
                grouped[value.agent].push(value);
            } else {
                grouped[value.agent].push(value);
            }
        });
    } else if (groupBy === 4) {
        props.data.forEach(value => {
            if (crafts.indexOf(value.eventId) === -1) {
                crafts.push(value.eventId);
                grouped[value.eventId] = [];
                grouped[value.eventId].push(value);
            } else {
                grouped[value.eventId].push(value);
            }
        });
    }

    let groupsCnt = Object.keys(grouped).length;

    let grand_total = 0;
    let grand_total_pax = 0;
    let grand_total_cc = 0;
    let grand_total_cash = 0;
    let grand_total_eft = 0;
    let grand_total_payFast = 0;
    let grand_total_debt = 0;

    return (
        <div className={`table-wrapper ${groupByCss}`}>
            {Object.keys(grouped).map((keyName, i) => {
                let craftGroup = grouped[keyName];
                let total = 0;
                let total_pax = 0;
                let total_cc = 0;
                let total_cash = 0;
                let total_eft = 0;
                let total_payFast = 0;
                let total_debt = 0;

                let rows = craftGroup.map(booking => {
                    let pax = booking.passengers + booking.passengersKids + booking.passengersToddlers + booking.passengersGuides;

                    total += booking.price_total;
                    total_pax += pax;
                    total_cc += booking.paidCreditCard;
                    total_cash += booking.paidCash;
                    total_eft += booking.paidEFT;
                    total_payFast += booking.paidPayFast;
                    total_debt += booking.paidDebt;

                    grand_total += booking.price_total;
                    grand_total_pax += pax;
                    grand_total_cc += booking.paidCreditCard;
                    grand_total_cash += booking.paidCash;
                    grand_total_eft += booking.paidEFT;
                    grand_total_payFast += booking.paidPayFast;
                    grand_total_debt += booking.paidDebt;

                    let price_full = 0;
                    let price_charged = 0;

                    if (booking.source === "WooCommerce") {
                        price_full = booking.price_total;
                    } else {
                        price_full = booking.price + booking.priceKids + booking.price_merchandise; // TODO: Add Full Price to DB
                    }

                    price_charged = price_full - booking.price_discount;

                    return (
                        <tr key={booking.id}>
                            <td>{booking.id > SETTINGS.BOOKING_ID_OFFSET ? booking.id - SETTINGS.BOOKING_ID_OFFSET : booking.id}</td>
                            <td>{booking.flightNumber}</td>
                            <td>{booking.craft}</td>
                            <td>{booking.pilot}</td>
                            <td>{booking.agent}</td>
                            <td>{moment(booking.date).format("HH:mm")}</td>
                            <td>{booking.route_range}</td>
                            <td>{booking.voucher_number}</td>
                            <td>{pax}</td>
                            <td>{price_full.toFixed(2)}</td> {/* TODO: Add Full Price to DB */}
                            <td>{booking.price_discount}</td>
                            <td>{price_charged.toFixed(2)}</td>
                            <td>{booking.paidCreditCard.toFixed(2)}</td>
                            <td>{booking.paidCash.toFixed(2)}</td>
                            <td>{booking.paidEFT.toFixed(2)}</td>
                            <td>{booking.paidPayFast.toFixed(2)}</td>
                            <td>{booking.paidDebt.toFixed(2)}</td>
                            <td>{booking.price_total.toFixed(2)}</td>
                            <td>{booking.notes}</td>
                            <td>{booking.sales_agent}</td>
                            <td>{booking.commissionPercent}</td>
                        </tr>
                    );
                });

                let totalRow = <></>;
                if ((i + 1) === groupsCnt) {
                    totalRow = (
                        <tr>
                            <td colSpan="9"></td>
                            <td></td>
                            <td></td>
                            <td className="visible-cell"><strong>TOTALS:</strong></td>
                            <td className="visible-cell"><strong>{grand_total_cc.toFixed(2)}</strong></td>
                            <td className="visible-cell"><strong>{grand_total_cash.toFixed(2)}</strong></td>
                            <td className="visible-cell"><strong>{grand_total_eft.toFixed(2)}</strong></td>
                            <td className="visible-cell"><strong>{grand_total_payFast.toFixed(2)}</strong></td>
                            <td className="visible-cell"><strong>{grand_total_debt.toFixed(2)}</strong></td>
                            <td className="visible-cell"><strong>{grand_total.toFixed(2)}</strong></td>
                            <td colSpan="3"></td>
                        </tr>
                    );
                }

                return (
                    <table className="table table-bordered table-striped" key={keyName}>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">FLIGHT#</th>
                                <th scope="col">CRAFT</th>
                                <th scope="col">PILOT</th>
                                <th scope="col">AGENT</th>
                                <th scope="col">TIME</th>
                                <th scope="col">ROUTE</th>
                                <th scope="col">V#</th>
                                <th scope="col">PAX</th>
                                <th scope="col">FULL PRICE</th>
                                <th scope="col">DISCOUNT</th>
                                <th scope="col">CHARGED</th>
                                <th scope="col">CREDIT CARDS</th>
                                <th scope="col">CASH</th>
                                <th scope="col">EFT</th>
                                <th scope="col">PAYFAST</th>
                                <th scope="col">DEBT</th>
                                <th scope="col">TOTAL</th>
                                <th scope="col">NOTES</th>
                                <th scope="col">NAME & HOTEL</th>
                                <th scope="col">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="8"></td>
                                <td className="visible-cell"><strong>{total_pax}</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="visible-cell"><strong>{total_cc.toFixed(2)}</strong></td>
                                <td className="visible-cell"><strong>{total_cash.toFixed(2)}</strong></td>
                                <td className="visible-cell"><strong>{total_eft.toFixed(2)}</strong></td>
                                <td className="visible-cell"><strong>{total_payFast.toFixed(2)}</strong></td>
                                <td className="visible-cell"><strong>{total_debt.toFixed(2)}</strong></td>
                                <td className="visible-cell"><strong>{total.toFixed(2)}</strong></td>
                                <td colSpan="3"></td>
                            </tr>
                            {totalRow}
                        </tfoot>
                    </table>
                );
            })}
        </div>
    );
}                    
import { Outlet } from "react-router-dom";
import React from 'react';
import Alert from './../components/alert'

export default function LayoutPublic() {
    return (
        <div id="layout-public" className="text-center">
            <Outlet/>
        </div>
    );
}
import { useState } from 'react';
import {
    Link,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";
import SideBar from './../components/sidebar/sidebar'
import TitleBar from './../components/titlebar/titlebar'
import * as ROUTES from '../constants/routes.js';
import * as ALERTS from '../constants/alerts.js';
import * as PERM from '../constants/permissions.js';
import * as SETTINGS from '../constants/settings.js';
import './LayoutPrivate.scss';

import SignalR from '../components/signalR/signalR';

import { useAppContext } from "../Contexts/AppContext";

import * as Constants from '../constants'

export default function LayoutPrivate() {
    const [reload, toggleReload] = useState(false);
    const [sidebar, setSidebar] = useState("");
    let location = useLocation();
    let { auth } = useAppContext();

    if (!auth?.success) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        auth.signOut();
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    
    function toggleSidebar(show) {
        setSidebar(show ? "show" : "");
    }

    function onNotification(res) {
        auth.lookupUpdate(res);
    }

    return (
        <div id="layout-private">
            <TitleBar config={auth.views}></TitleBar>
            <SideBar config={auth.views} callback={toggleSidebar}></SideBar>
            <div className={"content-wrapper container-fluid " + sidebar + "-sidebar"}>
                <div className="row">
                    <main role="main" className="col-md-12">
                        <Outlet/>
                    </main>
                </div>
            </div>
            <App.Environment environment={auth.settings.ENVIRONMENT} />
            <App.Version />
            <App.Update settings={auth.settings} />
            <SignalR onNotification={onNotification} topics={["ReceiveMessageLookupsUpdated"]} token={auth.token}></SignalR>
        </div>
    );
}

let App = {
    Environment: function (props) {
        if (props.environment === 'DEV') {
            return (<div className='app-environment'>DEV</div>);
        }
        else {
            return (<></>);
        }
    },
    Version: function () {
        return (<div className='app-version'>v{SETTINGS.VERSION}</div>)
    },
    Update: function (props) {
        let app = SETTINGS.VERSION.split('.');
        let api = props.settings.VERSION.split('.');

        let available =
            parseInt(api[0]) > parseInt(app[0]) ||
            parseInt(api[1]) > parseInt(app[1]) ||
            parseInt(api[2]) > parseInt(app[2]);

        if (available) {
            return (<div className="alert alert-success app-update" role="alert">
                <div><strong>New Version Available (v{props.settings.VERSION})</strong></div>
                Please restart all application and browser instances.
            </div>);
        }
        else {
            return (<></>);
        }
    }
}
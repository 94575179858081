import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './titlebar.scss';
import AccountModal from './account-modal.js';
import * as ROUTES from '../../constants/routes.js';
import NavBar from '../../components/navbar/navbar'

import Modal from 'react-bootstrap/Modal';

import {
    Link,
  } from "react-router-dom";

class TitleBar extends React.Component {
    render() {
        return (
            <nav id="titlebar" className="navbar sticky-top flex-md-nowrap p-0 shadow">
                <NavBar config={this.props.config}></NavBar>
                <ul className="navbar-nav px-3">
                    <li id="account" className="nav-item text-nowrap">
                    </li>
                </ul>
                <ul className="navbar-nav px-3 navbar-account">
                    <li id="account" className="nav-item text-nowrap">
                        <AccountModal></AccountModal>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default TitleBar;

import * as React from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { AppContextProvider } from "./Contexts/AppContextProvider";

import './App.scss';

import * as Constants from './constants'

// VIEWS
import LayoutPrivate from "./views/LayoutPrivate";
import LayoutPublic from "./views/LayoutPublic";
import LoginView from "./views/LoginView";


export default function App() {
  return (
    <AppContextProvider>
      <Routes>
        <Route element={<LayoutPrivate />}>
          {Constants.VIEWS.map((category, i) => (category.options.map((route, ii) => (
            <Route key={ii} path={route.path} element={route.element} />
          ))))}
        </Route>
        <Route element={<LayoutPublic />}>
          <Route path="/login" element={<LoginView />} />
        </Route>
      </Routes>
    </AppContextProvider>
  );
}
// Import packages
import React from 'react';
// import './BookingForm.scss';
import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../constants/alerts.js';
import * as LOOKUPS from '../../constants/lookups.js';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

export default class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      lookups: {
      },
      form: {
        name: "",
        username: "",
        email: "",
        accountId: "",
        password: "",
        role: "",
        office: "",
        office_id: 0
      },
      enabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
  }

  async componentDidMount() {
    switch (this.props.action) {
      case "create":
        this.setState({ inputDisabled: false, action: "Submit", submitFunction: this.create });
        break;
      case "update":
        this.setState({ inputDisabled: false, action: "Update", submitFunction: this.update });
        break;
    }

    this.getLookups();

    if (this.props.data) {
      let data = this.props.data;
      // data.role = "";
      this.setState({ form: data });
    }
  }

  getLookups = () => {
    let lookups = ["booking_offices", "auth_roles"];

    let config = {
      headers: { Authorization: `Bearer ${this.props.token}` }
    };

    return axios.post(SETTINGS.API_BASE_URL + "/lookups/select", lookups, config)
      .then((response) => {
        this.setState(state => {
          state.isLoaded = true;
          state.lookups = response.data;
          return state;
        });
        console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.setState(state => {
          state.isLoaded = true;
          state.error = error;
          return state;
        });
        this.props.displayAlert(error.message, ALERTS.ERROR);
      });
  }

  handleChange(event) {
    const val = event.target.value;
    const name = event.target.name;
    this.setState(state => { state.form[name] = val; return state; });
    return val;
  }

  create(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      let config = {
        headers: { Authorization: `Bearer ${this.props.token}` }
      };

      axios.post(SETTINGS.API_BASE_URL + "/system/users", this.state.form, config)
        .then((response) => {
          // console.log(response);
          this.props.displayAlert("User Created", ALERTS.SUCCESS);
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          if (error.message.includes("403")) { error.message = "User not authorized to create this Role" }
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  update(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      let config = {
        headers: { Authorization: `Bearer ${this.props.token}` }
      };

      axios.put(SETTINGS.API_BASE_URL + "/system/users/" + this.state.form.id, this.state.form, config)
        .then((response) => {
          // console.log(response);
          this.props.displayAlert("User Updated", ALERTS.SUCCESS);
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          if (error.message.includes("403")) { error.message = "User not authorized to update this Role" }
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  render() {
    const { error, isLoaded, data } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="booking-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.state.submitFunction}>
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <input type="text" className="form-control" id="name" name="name" placeholder="" value={this.state.form.name} onChange={this.handleChange} required />
              </Form.Group>
            </Form.Row>
                        
            <Form.Row>
              <Form.Group as={Col} controlId="username">
                <Form.Label>Username (Login)</Form.Label>
                <input type="text" className="form-control" minLength="3" id="username" name="username" placeholder="" value={this.state.form.username} onChange={this.handleChange} required />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>Email</Form.Label>
                <input type="email" className="form-control" id="email" name="email" placeholder="you@example.com" value={this.state.form.email} onChange={this.handleChange} disabled={this.props.action !== "create"} required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>Password</Form.Label>
                <input type="password" className="form-control" id="password" name="password" value={this.state.form.password} onChange={this.handleChange} />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="accountId">
                <Form.Label>Account</Form.Label>
                <Select name="accountId" options={LOOKUPS.ACCOUNT} required="true" value={this.state.form.accountId} onChange={this.handleChange}></Select>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="role">
                <Form.Label>Role</Form.Label>
                <Select name="role" options={this.state.lookups.auth_roles} valueIsName="true" required="true" value={this.state.form.role} onChange={this.handleChange}></Select>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="office_id">
                <Form.Label>Office</Form.Label>
                <Select name="office_id" options={this.state.lookups.booking_offices} required="true" value={this.state.form.office_id} onChange={this.handleChange}></Select>
              </Form.Group>
            </Form.Row>

            <hr className="mb-4" />
            <Submit enabled={this.state.enabled}></Submit>
          </Form>
        </div>
      );
    }
  }
}

function Submit(props) {
  if (props.enabled) {
    return (<button className="btn btn-primary btn-lg btn-block" type="submit">Submit</button>);
  }
  else {
    return (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>);
  }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange}>
          <option value="">Choose...</option>
          <SelectOptions options={props.options} valueIsName={props.valueIsName}></SelectOptions>
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange}>
          <option value="">Choose...</option>
          <SelectOptions options={props.options} valueIsName={props.valueIsName}></SelectOptions>
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

function SelectOptions(props) {
  if (props.valueIsName) {
    return (<>{props.options.map((item, i) => <option value={item.name} key={i}>{item.name}</option>)}</>);
  }
  else {
    return (<>{props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}</>);
  }
}
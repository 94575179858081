import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as LOOKUPS from '../../constants/lookups.js';

import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";

import moment from "moment";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'
import EventIcon from '@mui/icons-material/Event';

export default class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      isLoaded: false,
      inputDisabled: false,
      data: {
      }
    };
  }

  handleChange = (e) => {
    e.persist();

    const val = e.target.value;
    const name = e.target.name;

    if(name === "route_id") {
      let event = {
        target: {
          value: this.props.lookups.booking_routes.filter((x) => x.id === parseInt(val))[0].val1,
          name: "route_range"
        }
      }
      this.props.handleChange(event);
    }

    if(name === "craft") {
      let passengers = parseInt(this.props.lookups.crafts.filter((x) => x.name === val)[0].value4);
      let event = {
        target: {
          value: passengers,
          name: "seatsTotal"
        }
      }
      this.props.handleChange(event);
    }

    this.props.handleChange(e);
  }

  submitFormUpdate = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.updateBooking();
    }
  }

    submitForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState(state => { state.validated = true; return state; });
        }
        else {
            this.props.save();
        }
    }

  render() {
    const constraints = { hours: { min: 5, max: 24, step: 1 }, minutes: { step: 30 }};
    let inputDisabled = this.state.inputDisabled ? true : false;
    let messageDialog = (<></>);
    let buttons = (<></>);
    let messageHint = (<></>);
    let price = (<></>);
    let inputDisabledForm = inputDisabled;

    if(!PERM.Bookings.UpdateAfterClosed.includes(this.props.user.role) && this.props.formData.status_id === 2) {
      inputDisabled = true; 
      messageDialog = (<Form.Row><Form.Group as={Col}><div className="alert alert-primary" role="alert">Only Office Managers and Admin can update bookings after boarding.</div></Form.Group></Form.Row>);
    }

    if(this.props.formData.invoiced > 0) {
        inputDisabledForm = true;
    }

    if(inputDisabled) { buttons = (<></>); }
    else if (this.props.formData.id > 0) {
      buttons = (<>
        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitFormUpdate}>Update</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.handleCancel}>Cancel</button></>);
    }
    else {
        messageHint = (<Form.Row>

        </Form.Row>);
      buttons = (<>
        <button type="submit" className="btn btn-primary btn-block btn-lg">Confirm</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.handleCancel}>Cancel</button></>);
      price = (<>
          <hr className="mb-4"/>
          <Form.Row>
              <Form.Group as={Col} controlId="price">
                  <Form.Label>Adult Price</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="price"
                          name="price"
                          placeholder=""
                          value={this.props.formData.price}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabledForm}/>
                  </div>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col} controlId="priceKids">
                  <Form.Label>Kids Price</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="priceKids"
                          name="priceKids"
                          placeholder=""
                          value={this.props.formData.priceKids}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabledForm}/>
                  </div>
              </Form.Group>
          </Form.Row>
          </>);
    }

    const dateStart = moment(this.props.formData.dateStart);
    const dateEnd = moment(this.props.formData.dateEnd);

    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.submitForm}>
      <Form.Row>
      <Form.Group as={Col} controlId="dateStart">
          <Form.Label>Date Start</Form.Label>
          <div className="input-group input-group-datetimepicker">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><EventIcon/></span>
              </div>
              <Datetime readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="dateStart" value={dateStart} onChange={this.props.handleDateTime} disabled={true} required/>
          </div>
      </Form.Group>
      <Form.Group as={Col} controlId="dateEnd">
          <Form.Label>Date End</Form.Label>
          <div className="input-group input-group-datetimepicker">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><EventIcon/></span>
              </div>
              <Datetime readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="dateEnd" value={dateEnd} onChange={this.props.handleDateTime} disabled={true} required/>
          </div>
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="route_id">
          <Form.Label>Route</Form.Label>
          <Select
          name="route_id"
          // options={this.state.data.booking_routes}
          required="true"
          value={this.props.formData.route_id}
          options={this.props.lookups.booking_routes}
          onChange={this.handleChange}
          disabled={inputDisabledForm}></Select>
      </Form.Group>
      <Form.Group as={Col} controlId="route_range">
          <Form.Label>Range</Form.Label>
          <input
          type="text"
          className="form-control"
          id="route_range"
          name="route_range"
          placeholder=""
          value={this.props.formData.route_range}
          onChange={this.handleChange}
          required
          disabled={inputDisabledForm} />
      </Form.Group>
      </Form.Row>
      <Form.Row>
          <Form.Group as={Col} controlId="craft">
              <Form.Label>Select Craft</Form.Label>
              <Select
                  name="craft"
                  options={this.props.lookups.crafts}
                  required="true"
                  valueName={true}
                  value={this.props.formData.craft}
                  onChange={this.handleChange}
                  disabled={inputDisabled}></Select>
          </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col}>
          <Form.Label>Seats Total</Form.Label>
          <input type="number" className="form-control"
          id="seatsTotal"
          name="seatsTotal"
          placeholder=""
          value={this.props.formData.seatsTotal}
          onChange={this.handleChange}
          required
          disabled={inputDisabledForm}>
          </input>
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="notes">
          <Form.Label>Notes</Form.Label>
          <textarea 
          value={this.props.formData.notes ?? ""}
          onChange={this.handleChange}
          className="form-control"
          id="notes"
          name="notes"
          rows="4"
          disabled={inputDisabled}></textarea>
      </Form.Group>
      </Form.Row>
      {price}
      <hr className="mb-4" />
      {messageDialog}
      {messageHint}
      {buttons}
      </Form>
    )
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Submit(props) {

}

function Select(props) {
  if (props.options) {
    if (props.required && props.valueName) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.name} key={i}>{item.name}</option>)}
        </select>
      );
    }
    if (props.required) {
      return (
          <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
              <option value="">Choose...</option>
              {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
          </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

const formStatus = {
  CREATE: "New Booking",
  VIEW: "View Booking",
  PAYMENT: "Payment",
  BOARD: "Boarding"
}
// Import packages
import React from 'react';
import './ProductForm.scss';
import * as SETTINGS from '../../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../../constants/alerts.js';
import * as ROLES from '../../../constants/roles.js';
import moment from "moment";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import EventIcon from '@mui/icons-material/Event';
import Datetime from '../../../components/react-datetime/DateTime';
import "../../../components/react-datetime/react-datetime.css";

const entityController = "products";
const entityName = "Product";

export default class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      inputDisabled: false,
      data: {
        booking_types: [],
        booking_routes: [],
        booking_offices: [],
        booking_payment_types: [],
        booking_sales_agents: []
      },
        booking_routes: [
          {"id":0,"name":"Other","val1":"0.3"},
          {"id":1,"name":"Hopper", "val1": "0.2"},
          {"id":2,"name":"Camps bay & Hout bay", "val1": "0.3"},
          {"id":3,"name":"Robbin Island", "val1": "0.3"},
          {"id":4,"name":"Robbin Island & Hopper", "val1": "0.5"},
          {"id":5,"name":"Two Oceans", "val1": "0.5"},
          {"id":7,"name":"Combat", "val1": "0.5"},
          {"id":8,"name":"Combat Lite", "val1": "0.3"},
          {"id":9,"name":"City Explorer", "val1": "0.1"},
          {"id":10,"name":"Full Peninsula", "val1": "1.0"},
          {"id":11,"name":"(PVT Charter) Clifton Shores", "val1": "2"},
          {"id":12,"name":"(PVT Charter) Sundowners", "val1": "2"},
          {"id":13,"name":"(PVT Charter) Sunset Cruise & Dine", "val1": "3"},
          {"id":14,"name":"(PVT Charter) Grand Clifton Experience", "val1": "3"},
          {"id":15,"name":"(PVT Charter) Half Day", "val1": "4"},
          {"id":16,"name":"(PVT Charter) Full Day", "val1": "8"},
          {"id":17,"name":"(PVT Charter) Sun & Sea Safari", "val1": "2"}],
      form: {
        "name": "",
        "craft": "",
        "routeId": 0,
        "priceAdult": 0.0,
        "priceKid": 0.0,
        "wcProductId": 0,
        "wcAdultPriceId": 0,
        "wcKidPriceId": 0,
        "updateDate": null
      },
      enabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  async componentDidMount() {
    switch (this.props.action) {
      case "view":
        this.setState({ inputDisabled: false, action: ACTIONS.UPDATE, submitFunction: this.update });
        break;
    }

    if (this.props.lookups) {
      this.setState({data: this.props.lookups});
    }

    if (this.props.data) {
      this.setState({ form: this.props.data });
    }
  }

  handleChange(e) {
    const val = e.target.value;
    const name = e.target.name;
    this.setState(state => { state.form[name] = val; return state; });
    return val;
  }

  update(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      // TODO: Add to DAL (220827)

      let config = {
        headers: { Authorization: `Bearer ${this.props.token}` }
      };

      axios.put(`${SETTINGS.API_BASE_URL}/${entityController}/${this.state.form.id}`, this.state.form, config)
        .then((response) => {
          console.log(response);
          this.props.displayAlert(entityName+" Updated", ALERTS.SUCCESS)
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  delete (event) {
    const form = event.currentTarget;
    event.preventDefault();

    if(!window.confirm(`Are you sure you want to delete ${entityName.toLowerCase()} #${this.state.form.id}?`)) { return; }

    this.setState(state => {
      state.enabled = false;
      return state;
    });
    
    let config = {
      headers: { Authorization: `Bearer ${this.props.token}` }
    };

    this.setState(state => { state.form.status_id = 2; return state; })

    axios.delete(`${SETTINGS.API_BASE_URL}/${entityController}/${this.state.form.id}`, config)
      .then((response) => {
        console.log(response);
        this.props.displayAlert(entityName+" #" + this.state.form.id + " Deleted", ALERTS.SUCCESS)
        this.props.close();
      })
      .catch((error) => {
        console.log(error);
        this.setState(state => {
          state.isLoaded = true;
          state.error = error;
          return state;
        });
        this.props.displayAlert(error.message, ALERTS.ERROR);
      });
  }

  render() {
    const { error, isLoaded, data } = this.state;

    const inputDisabled = this.state.inputDisabled ? true : false;

    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};

    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="booking-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.state.submitFunction}>
            <div className="row">
              
            </div>
            <div className="row">
              <div className="col-md-6">
              <h4 className="mb-3">Product Details</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>Product Name</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder=""
                      value={this.state.form.name}
                      onChange={this.handleChange} required
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="routeId">
                    <Form.Label>RouteId</Form.Label>
                    <input
                      type="number"
                      className="form-control"
                      id="routeId"
                      name="routeId"
                      placeholder=""
                      value={this.state.form.routeId}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="craft">
                    <Form.Label>Craft</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="craft"
                      name="craft"
                      placeholder=""
                      value={this.state.form.craft}
                      onChange={this.handleChange} required
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="priceAdult">
                    <Form.Label>Price Adults</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="priceAdult"
                      name="priceAdult"
                      placeholder=""
                      value={this.state.form.priceAdult}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="priceKid">
                    <Form.Label>Price Kids</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="priceKid"
                      name="priceKid"
                      placeholder=""
                      value={this.state.form.priceKid}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
              </div>
              <div className="col-md-6">
              <h4 className="mb-3">Woo Commerce</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="wcProductId">
                    <Form.Label>ProductId</Form.Label>
                    <input
                      type="number"
                      className="form-control"
                      id="wcProductId"
                      name="wcProductId"
                      placeholder=""
                      value={this.state.form.wcProductId}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="wcAdultPriceId">
                    <Form.Label>AdultPriceId</Form.Label>
                    <input
                      type="number"
                      className="form-control"
                      id="wcAdultPriceId"
                      name="wcAdultPriceId"
                      placeholder=""
                      value={this.state.form.wcAdultPriceId}
                      onChange={this.handleChange}
                      disabled={true} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="wcKidPriceId">
                    <Form.Label>KidsPriceId</Form.Label>
                    <input
                      type="number"
                      className="form-control"
                      id="wcKidPriceId"
                      name="wcKidPriceId"
                      placeholder=""
                      value={this.state.form.wcKidPriceId}
                      onChange={this.handleChange}
                      disabled={true} />
                  </Form.Group>
                </Form.Row>
              </div>
            </div>
            <hr className="mb-4" />
            <Submit enabled={this.state.enabled} action={this.state.action} role={this.props.user.role} delete={this.delete}></Submit>
          </Form>
        </div>
      );
    }
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Submit(props) {
  let auth = [ROLES.Admin, ROLES.OfficeManager, ROLES.Accountant];

  if(props.enabled) {
    if(auth.includes(props.role)) {
      if(props.action === ACTIONS.UPDATE) {
        return (<div className="form-row"><div className="col"><button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button></div><div className="col"><button disabled={true} type="button" onClick={props.delete} className="btn btn-danger btn-lg btn-block">Delete</button></div></div>);
      }
      else {
        return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
      }
    }
    else
    {
      return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
    }
  }
  else {
    return (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>);
  }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
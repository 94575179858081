import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';

import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../constants/alerts.js';

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// import Autocomplete from 'react-autocomplete'; // TODO: React-bootstrap-typeahead
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'

import EventIcon from '@mui/icons-material/Event';

export default class BoardingForm extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            enabled: true,
            isLoaded: false,
            inputDisabled: false
        };
    }

    async componentDidMount() {
      await this.props.getFlightNumber();
    }

    handleChange = (e) => {
        this.props.handleChange(e);
    }

    handleSelect = (e) => {
      this.props.handleChange(e);
    }

    handleDateTime = (val) => {
        this.props.handleDateTime(val);
    }

    board = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
          event.stopPropagation();
          this.setState(state => { state.validated = true; return state; });
      }
      else {
          this.setState(state => {
          state.enabled = false;
          return state;
          });
          
          let config = {
          headers: { Authorization: `Bearer ${this.props.token}` }
          };
  
          this.setState(state => { state.form.status_id = 2; return state; })
  
          axios.put(SETTINGS.API_BASE_URL + "/bookings/" + this.props.formData.id + "/board", this.props.formData, config)
          .then((response) => {
              console.log(response);
              this.props.displayAlert("Booking #" + this.props.formData.id + " Boarded", ALERTS.SUCCESS)
              this.props.close();
          })
          .catch((error) => {
              console.log(error);
              this.setState(state => {
              state.isLoaded = true;
              state.error = error;
              return state;
              });
              this.props.displayAlert(error.message, ALERTS.ERROR);
          });
      }
    }

    // board(event) {
    //   const form = event.currentTarget;
    //   event.preventDefault();
    //   if (form.checkValidity() === false) {
    //     event.stopPropagation();
    //     this.setState(state => { state.validated = true; return state; });
    //   }
    //   else {
    //     this.setState(state => {
    //       state.enabled = false;
    //       return state;
    //     });
        
    //     let config = {
    //       headers: { Authorization: `Bearer ${this.props.token}` }
    //     };
  
    //     this.setState(state => { state.form.status_id = 2; return state; })
  
    //     axios.put(SETTINGS.API_BASE_URL + "/bookings/" + this.state.form.id + "/board", this.state.form, config)
    //       .then((response) => {
    //         console.log(response);
    //         this.props.displayAlert("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
    //         this.props.close();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.setState(state => {
    //           state.isLoaded = true;
    //           state.error = error;
    //           return state;
    //         });
    //         this.props.displayAlert(error.message, ALERTS.ERROR);
    //       });
    //   }
    // }

    submitForm = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
        this.setState(state => { state.validated = true; return state; });
      }
      else {
        this.props.board();
      }
    }

    render() {
        const { loading, data, rowsTotal } = this.state;
        const inputDisabled = this.state.inputDisabled ? true : false;
        const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};
        const buttons = this.state.enabled ? (<><button className="btn btn-primary btn-block btn-lg" type="submit">Board</button>
        <button type="button" onClick={this.props.back} class="btn btn-secondary btn-block btn-lg">Back</button></>) : (<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>)
        
        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.submitForm}>
                <Form.Row>
                  <Form.Group as={Col} controlId="craft">
                    <Form.Label>Select Craft</Form.Label>
                    <Select
                      name="craft"
                      options={this.props.lookups.crafts}
                      required="true"
                      value={this.props.formData.craft}
                      onChange={this.props.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                  <Form.Group as={Col} controlId="pilot">
                    <Form.Label>Select Pilot</Form.Label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="pilot"
                      name="pilot"
                      placeholder=""
                      value={this.props.formData.pilot}
                      onChange={this.props.handleChange}
                      disabled={inputDisabled} />
                    {/* <Autocomplete className="form-control"
                    getItemValue={(item) => item.name}
                    items={this.props.lookups.pilots}
                    renderItem={(item, isHighlighted) =>
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name}
                      </div>
                    }
                    value={this.props.formData.pilot}
                    onChange={this.props.handleChange}
                  /> */}
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="flightNumber">
                    <Form.Label>Flight Number</Form.Label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="flightNumber"
                      name="flightNumber"
                      placeholder=""
                      value={this.props.formData.flightNumber}
                      onChange={this.props.handleChange}
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
            <hr className="mb-4" />
            {buttons}
        </Form>
        )
    }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.name} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.name} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
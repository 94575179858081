import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import * as SETTINGS from '../../constants/settings.js';
import * as signalR from '@microsoft/signalr';

class SignalR extends PureComponent {
    constructor (props) {
        super(props);

        this.connection = null;
    }

    componentDidMount () {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${SETTINGS.API_BASE_URL_NOTIFICATION}?token=${this.props.token}`)
            .withAutomaticReconnect()
            .build();

        this.props.topics.map((item, i) => this.connection.on(item, this.onNotifReceived));

        this.connection.start()
            .then(() => console.info('SignalR Connected'))
            .catch(err => console.error('SignalR Connection Error: ', err));
    }

    componentWillUnmount () {
        this.connection.stop();
    }

    onNotifReceived = (res) => {
        this.props.onNotification(res);
    }

    render () {
        return <></>;
    };
};

export default SignalR;
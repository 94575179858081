// Import packages
import React from 'react';
import './ProductFilterForm.scss';
import * as SETTINGS from '../../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../../constants/alerts.js';
import * as ROLES from '../../../constants/roles.js';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'

export default class ProductFilterFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      inputDisabled: false,
      data: {
        booking_types: [],
        booking_routes: [],
        booking_offices: [],
        booking_payment_types: [],
        booking_sales_agents: []
      },
      form: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        type_id: "",
        route_id: "",
        passengers: 0,
        sales_agent_id: "",
        office_id: "",
        voucher_number: 0,
        payment_type: 0,
        payment_reference: "",
      },
      enabled: true
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    switch (this.props.action) {
      case "book":
        this.setState({ inputDisabled: false, action: ACTIONS.BOOK, submitFunction: this.create });
        break;
      case "view":
        this.setState({ inputDisabled: false, action: ACTIONS.UPDATE, submitFunction: this.update });
        break;
      case "board":
        this.setState({ inputDisabled: true, action: ACTIONS.BOARD, submitFunction: this.board });
        break;
    }

    let config = {
      headers: { Authorization: `Bearer ${this.props.token}` }
    };

    if (this.props.data) {
      this.setState({ form: this.props.data });
    }
  }

  handleChange(event) {
    const val = event.target.value;
    const name = event.target.name;
    this.setState(state => { state.form[name] = val; return state; });
    return val;
  }

  update = (event) => {
    event.preventDefault();

    var result = [];
    var keys = Object.keys(this.state.form);
    var form = this.state.form;
    keys.forEach(function(key){
      let val = form[key];
      if(val && val !== '' && val !== 0) {
        result.push(`${key}=${val}`);
      }
    });

    console.log(result);

    this.props.filtersUpdate(result);
  }

  render() {
    const { error, isLoaded, data } = this.state;

    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="booking-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.update}>
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-3">Client</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      name="firstname"
                      placeholder=""
                      value={this.state.form.firstname}
                      onChange={this.handleChange}/>
                  </Form.Group>
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      name="lastname"
                      placeholder=""
                      value={this.state.form.lastname}
                      onChange={this.handleChange} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <input
                      type="tel"
                      minLength="10"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="+27713034000"
                      value={this.state.form.phone}
                      onChange={this.handleChange}/>
                    <Form.Control.Feedback type="invalid">
                      Please provide a phone number.
                      </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="you@example.com"
                      value={this.state.form.email}
                      onChange={this.handleChange} />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                      </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {/* <hr className="mb-4" />
                <h4 className="mb-3">Payment</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="payment_type_id">
                    <Form.Label>Payment Type</Form.Label>
                    <Select
                      name="payment_type_id"
                      options={this.state.data.booking_payment_types}
                      value={this.state.form.payment_type_id}
                      onChange={this.handleChange}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="payment_reference">
                    <Form.Label>Payment Reference</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="payment_reference"
                      name="payment_reference"
                      placeholder="UPS1011"
                      value={this.state.form.payment_reference}
                      onChange={this.handleChange}/>
                  </Form.Group>
                </Form.Row> */}
              </div>
              {/* <div className="col-md-6">
                <h4 className="mb-3">Booking</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="type_id">
                    <Form.Label>Booking Type</Form.Label>
                    <Select
                      name="type_id"
                      options={this.state.data.booking_types}
                      value={this.state.form.type_id}
                      onChange={this.handleChange}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="route_id">
                    <Form.Label>Route</Form.Label>
                    <Select
                      name="route_id"
                      options={this.state.data.booking_routes}
                      value={this.state.form.route_id}
                      onChange={this.handleChange}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="passengers">
                    <Form.Label>Passengers</Form.Label>
                    <input
                      type="number"
                      min="1"
                      className="form-control"
                      id="passengers"
                      name="passengers"
                      placeholder="Number of passengers"
                      value={this.state.form.passengers}
                      onChange={this.handleChange} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="sales_agent_id">
                    <Form.Label>Sales Agent</Form.Label>
                    <Select
                      name="sales_agent_id"
                      options={this.state.data.booking_sales_agents}
                      value={this.state.form.sales_agent_id}
                      onChange={this.handleChange}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="office_id">
                    <Form.Label>Booking Office</Form.Label>
                    <Select
                      name="office_id"
                      options={this.state.data.booking_offices}
                      value={this.state.form.office_id}
                      onChange={this.handleChange}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="voucher_number">
                    <Form.Label>Voucher Number</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="voucher_number"
                      name="voucher_number"
                      placeholder=""
                      value={this.state.form.voucher_number} />
                  </Form.Group>
                </Form.Row>
              </div> */}
            </div>
            <hr className="mb-4" />
            <button className="btn btn-primary btn-lg btn-block" type="submit">Filter</button>
          </Form>
        </div>
      );
    }
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
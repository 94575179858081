export const ROUTES = [
    {"id":0,"name":"Other","val1":"0.3"},
    {"id":1,"name":"Hopper", "val1": "0.2"},
    {"id":2,"name":"Camps bay & Hout bay", "val1": "0.3"},
    {"id":3,"name":"Robben Island", "val1": "0.3"},
    {"id":4,"name":"Robben Island & Hopper", "val1": "0.5"},
    {"id":21,"name":"Robben Island & Cape Point", "val1": "1.3"},
    {"id":5,"name":"Two Oceans", "val1": "0.5"},
    {"id":6,"name":"Cape Point", "val1": "0.5"},
    {"id":7,"name":"Combat", "val1": "0.5"},
    {"id":8,"name":"Combat Lite", "val1": "0.3"},
    {"id":9,"name":"City Explorer", "val1": "0.1"},
    {"id":10,"name":"Full Peninsula", "val1": "1.0"},
    {"id":11,"name":"(PVT Charter) Clifton Shores", "val1": "2"},
    {"id":12,"name":"(PVT Charter) Sundowners", "val1": "2"},
    {"id":13,"name":"(PVT Charter) Sunset Cruise & Dine", "val1": "3"},
    {"id":14,"name":"(PVT Charter) Grand Clifton Experience", "val1": "3"},
    {"id":15,"name":"(PVT Charter) Half Day", "val1": "4"},
    {"id":16,"name":"(PVT Charter) Full Day", "val1": "8"},
    {"id":17,"name":"(PVT Charter) Sun & Sea Safari", "val1": "2"},
    {"id":18,"name":"30Min Harbour Cruise - Une Belle Vie", "val1": "0.5"},
    {"id":19,"name":"90Min Catamaran Sunset Cruise - A Beautiful Life", "val1": "1.5"},
    {"id":20,"name":"60Min Sailing in the Bay - A Beautiful Life", "val1": "1"},
    {"id":22,"name":"(PVT Charter) 1 Hour", "val1": "1"},
    {"id":23,"name":"60Min Sunset Cruise - Une Belle Vie", "val1": "1"},
    {"id":24,"name":"90Min Sunset Cruise - Une Belle Vie", "val1": "1.5"}];

export const CRAFT_BOAT = [
    {"id":"Une Belle Vie","name":"Une Belle Vie", "val1": "3"},
    {"id":"A Beautiful Life","name":"A Beautiful Life", "val1": "3"},
    {"id":"Princess Skye","name":"Princess Skye", "val1": "3"}];

export const CRAFT = [
    {"id":"All Craft","name":"All Craft", "val1": "3"},
    {"id":"Une Belle Vie","name":"Une Belle Vie", "val1": "3"},
    {"id":"A Beautiful Life","name":"A Beautiful Life", "val1": "3"},
    {"id":"Princess Skye","name":"Princess Skye", "val1": "3"}];

export const ACCOUNT = [
    {"id":"1","name":"Sport Helicopters", "val1": "1"},
    {"id":"2","name":"Waterfront Boat Tours", "val1": "2"}];

export const SCHEDULE_FOR = [
    {"id":"0","name":"Never"},
    {"id":"1","name":"Day"},
    {"id":"2","name":"Week"},
    {"id":"3","name":"Month"}];
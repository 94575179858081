import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class ProductsDAL {
    constructor(token) {
        this.token = token;
    }

    get(page, rowsPerPage, query, callback) {
        let config = { headers: { Authorization: `Bearer ${this.token}` } };
        // let url = SETTINGS.API_BASE_URL + `/products?page=${page}&rowsPerPage=${rowsPerPage}${query}`;
        let url = SETTINGS.API_BASE_URL + `/products/${page}/${rowsPerPage}/${query}`
        axios.get(url, config)
        .then((response) => {
            console.log(response); // TODO: Why is 4 requests happening??? Rerenders due to context?
            if(typeof callback === "function") { callback(response) }
        });
    }
}
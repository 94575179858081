import React from 'react';
import './AnalyticsView.scss';
import { Navigate } from "react-router-dom";
import { useAppContext } from '../../Contexts/AppContext';
import * as ROUTES from '../../constants/routes.js';
import * as ROLES from '../../constants/roles.js';

export default function AnalyticsView() {
    let { auth } = useAppContext();
    let authRoles = [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager];
    let authOffice = [ROLES.BookingAgent, ROLES.OfficeStaff];

    if (authRoles.includes(auth.user.role)) {
        return (
            <div id="report-page">
                <iframe className="dashboard-iframe" height="2520" src="https://datastudio.google.com/embed/reporting/647d9ac4-61e7-400f-8697-8df71fa25f01/page/zdWl" frameBorder="0" allowFullScreen=""></iframe>
            </div>
        );
    }
    else if (auth.user.role === ROLES.OfficeStaff) {
        let uri = "https://datastudio.google.com/embed/u/0/reporting/70117dcc-c773-4b31-8edd-614425edb258/page/NjLfB"
        return (
            <div id="report-page">
                <iframe className="dashboard-iframe" height="2520" src={uri} frameBorder="0" allowFullScreen=""></iframe>
            </div>
        );
    }
    else if (auth.user.role === ROLES.BookingAgent) {
        let uri = "https://datastudio.google.com/embed/u/0/reporting/70117dcc-c773-4b31-8edd-614425edb258/page/NjLfB?params=%7B%22df27%22:%22include%25EE%2580%25801%25EE%2580%2580EQ%25EE%2580%2580" + auth.user.office_id + "%22%7D"
        return (
            <div id="report-page">
                <iframe className="dashboard-iframe" height="2520" src={uri} frameBorder="0" allowFullScreen=""></iframe>
            </div>
        );
    }
    else {
        return <Navigate to={ROUTES.HOME} />
    }
}
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './HomeView.scss';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import BookingFormView from '../BookingsView/BookingForm';

// ICONS
import Home from '@mui/icons-material/Home';
import Info from '@mui/icons-material/Info';
import Storage from '@mui/icons-material/Storage';
import People from '@mui/icons-material/People';
import Widgets from '@mui/icons-material/Widgets';
import Business from '@mui/icons-material/Business';
import Contacts from '@mui/icons-material/Contacts';
import Message from '@mui/icons-material/Message';
import Email from '@mui/icons-material/Email';
import SimCard from '@mui/icons-material/SimCard';
import Dialpad from '@mui/icons-material/Dialpad';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import PieChart from '@mui/icons-material/PieChart';
import Assessment from '@mui/icons-material/Assessment';
import TableChart from '@mui/icons-material/TableChart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Event from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import * as ROUTES from '../../constants/routes.js';
import * as PERM from '../../constants/permissions.js';

import { AppContext } from "../../Contexts/AppContext";

class HomeView extends React.Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
        action: "book"
      }
    };
  }

  bookingCard = () => {
    let auth = PERM.Bookings.Create;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="card-booking-flight col-6 col-md-4 col-sm-6">
            <Link className="card mb-4 shadow-sm card-active" to={ROUTES.CALENDAR}>
              <div className="card-body">
                  <Event></Event>
                <h5 className="card-title text-center">BOOK</h5>
              </div>
            </Link>
          </div>
        );
    }
  }

  boardCard = () => {
    let auth = PERM.Bookings.Board;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="col-6 col-md-4 col-sm-6 card-board-flight">
            <Link className="card mb-4 shadow-sm" to={ROUTES.BOOKINGS + "?action=board"}>
              <div className="card-body">
                  <EventAvailableIcon></EventAvailableIcon>
                <h5 className="card-title text-center">BOARD</h5>
              </div>
            </Link>
          </div>
        );
    }
  }

    bookingCardYacht = () => {
        let auth = PERM.BookingsYachts.Create;

        if(auth.includes(this.context.auth.user.role)) {
            return (
                <div className="card-booking-yacht col-6 col-md-4 col-sm-6">
                    <Link className="card mb-4 shadow-sm card-active" to={ROUTES.CALENDAR}>
                        <div className="card-body">
                            <Event></Event>
                            <h5 className="card-title text-center">BOOK</h5>
                        </div>
                    </Link>
                </div>
            );
        }
    }

    boardCardYacht = () => {
        let auth = PERM.BookingsYachts.Board;

        if(auth.includes(this.context.auth.user.role)) {
            return (
                <div className="col-6 col-md-4 col-sm-6 card-board-yacht">
                    <Link className="card mb-4 shadow-sm" to={ROUTES.BOOKINGS + "?action=board"}>
                        <div className="card-body">
                            <EventAvailableIcon></EventAvailableIcon>
                            <h5 className="card-title text-center">BOARD</h5>
                        </div>
                    </Link>
                </div>
            );
        }
    }

  groupCard = () => {
    let auth = PERM.Bookings.Group;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="col-6 col-md-4 col-sm-6">
            <div className="card mb-4 shadow-sm card-disabled">
              <div className="card-img-top img3"></div>
              <div className="card-body">
                <h5 className="card-title text-center">Group Bookings</h5>
              </div>
            </div>
          </div>
        );
    }
  }

  users = () => {
    let auth = PERM.Users.Read;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="col-6 col-md-4 col-sm-6 card-users">
          <Link className="card mb-4 shadow-sm" to={ROUTES.USERS}>
            <div className="card-body">
                <People></People>
              <h5 className="card-title text-center">USERS</h5>
            </div>
          </Link>
        </div>
        );
    }
  }

  report = () => {
    let auth = PERM.Report.Read;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="col-6 col-md-4 col-sm-6 card-reporting">
            <Link className="card mb-4 shadow-sm" to={ROUTES.REPORT}>
              <div className="card-body">
                  <TableChart></TableChart>
                <h5 className="card-title text-center">DAILY REPORT</h5>
              </div>
            </Link>
          </div>
        );
    }
  }

  issueVoucher = () => {
    let auth = PERM.Vouchers.Read;

    if(auth.includes(this.context.auth.user.role)) {
        return (
          <div className="col-6 col-md-4 col-sm-6">
            <div className="card mb-4 shadow-sm card-disabled">
              <div className="card-img-top img4"></div>
              <div className="card-body">
                <h5 className="card-title text-center">Issue Voucher</h5>
              </div>
            </div>
          </div>
        );
    }
  }

  book = (e) => { e.preventDefault(); this.setState({ modal: { show: true, title: "New Booking", action: "book" } }); }

  render() {
    const handleClose = () => this.setState({ modal: { show: false } });
    let title = this.context.auth.user.accountId === 2 ? "SPORT MOTOR YACHTS" : "SPORT HELICOPTERS";
    let actions = this.context.auth.user.accountId === 2 ? <>{this.bookingCardYacht()}{this.boardCardYacht()}</> : <>{this.bookingCard()}{this.boardCard()}</>
    return (
      <div id="home-page">
        <section className="jumbotron text-center">
          <div className="container">
            <h1>{title}</h1>
          </div>
        </section>
        <div className="container">
          <div className="row">
            {actions}
            {this.report()}
            {this.users()}
          </div>
        </div>
        <Modal show={this.state.modal.show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookingFormView action={this.state.modal.action} displayAlert={this.context.displayAlert} token={this.context.token} user={this.context.auth.user} close={handleClose} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default HomeView;
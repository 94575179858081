import * as ROLES from './roles';

/***************************
 * PERMISSIONS
 ***************************/

export const AllRoles = ROLES.AllRoles;

export const ReportAdmin = {
    Read: [ROLES.Admin, ROLES.Accountant]
};

export const Report = {
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff, ROLES.BookingAgent]
};

export const Analytics = {
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff, ROLES.BookingAgent]
};

export const Calendar = {
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff],
};

export const Bookings = {
    Create: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff, ROLES.BookingAgent],
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Update: [ROLES.Admin, ROLES.Accountant],
    Delete: [ROLES.Admin, ROLES.Accountant],
    Board: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff],
    Group: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    UpdateAfterClosed: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff]
};

export const Products = {
    Read: [ROLES.Admin, ROLES.Accountant],
    Create: [ROLES.Admin, ROLES.Accountant]
};

export const BookingsYachts = {
    Create: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff, ROLES.BookingAgent],
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Update: [],
    Delete: [],
    Board: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff, ROLES.BookingAgent],
    Group: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    UpdateAfterClosed: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager, ROLES.OfficeStaff]
};

export const Users = {
    Create: [],
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Update: [],
    Delete: [],
    Board: []
};

export const Vouchers = {
    Create: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Update: [],
    Delete: [],
    Board: []
};

export const Lookups = {
    Create: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
    Update: [],
    Delete: [],
    Board: []
};

export const AccountSwitcher = {
    Read: [ROLES.Admin, ROLES.Accountant, ROLES.OfficeManager],
}
// Import packages
import React from 'react';
import './Scanner.scss';
import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import * as ALERTS from '../../constants/alerts.js';
import * as ROLES from '../../constants/roles.js';
import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";
import moment from "moment";
import EventIcon from '@mui/icons-material/Event';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import BarcodeScannerComponent from "../../components/react-qr-barcode-scanner/BarcodeScannerComponent";
import CheckIcon from '@mui/icons-material/Check';

export default class BookingFormView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: 'No result',
            status: 'Scanning...',
            error: null,
            isLoaded: true,
            inputDisabled: false,
            form: {
                "firstname": "",
                "lastname": "",
                "email": "",
                "phone": "",
                "route_id": 0,
                "route_range": 0,
                "price_total": 0.0,
                "price": {},
                "price_merchandise": 0.0,
                "paidCreditCard": 0.0,
                "paidCash": 0.0,
                "paidEFT": 0.0,
                "paidInvoice": 0.0,
                "paidPayFast": 0.0,
                "paidDebt": 0.0,
                "commissionPercent": 0,
                "commission": 0,
                "vat": 0.0,
                "passengers": 1,
                "voucher_number": 0,
                "payment_type_id": 0,
                "payment_reference": "",
                "sales_agent": "",
                "type_id": 0,
                "office_id": 0,
                "pilot": "",
                "craft": "",
                "flightNumber": "",
                "notes": "",
                "notesAccounting": "",
                "status_id": 0,
                "date": moment().toDate(),
                "result": ""
            },
            enabled: true
        };

        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.setState({ inputDisabled: false, action: ACTIONS.BOARD });
    }

    handleChange(e) {
        const val = e.target.value;
        const name = e.target.name;
        this.setState(state => { state.form[name] = val; return state; });
        return val;
    }

    handleScan = (data) =>{
        this.props.getBooking(data);
        this.setState( state => {
            state.status = "found";
            state.form["result"] = data;
            return state;
        });
    }

    handleError = (err) => {
        this.setState( state => {
            state.status = "scanning";
            return state;
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.getBooking(this.state.form.result);
    }

    render() {
        const { error, isLoaded, data } = this.state;

        let status = <div className="alert alert-info" role="alert"><div className="spinner-border" role="status"></div>&nbsp;&nbsp;Scanning...</div>

        if(this.state.status === "found") {
            status = <div className="alert alert-success" role="alert"><CheckIcon/>&nbsp;&nbsp;Found</div>
        }

        if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div id="booking-form-scanner">
                    <Form>
                        {status}
                        <div className="mb-2 scanner-wrapper">
                            <hr />
                            <BarcodeScannerComponent
                                onUpdate={(err, result) => {
                                    if (result) this.handleScan(result.text);
                                    else this.handleError("Not Found");
                                }}
                            />
                        </div>
                        <Form className="form-inline" noValidate validated={this.state.validated} onSubmit={this.onSubmit}>
                            <div className="mb-2 mr-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="result"
                                    name="result"
                                    placeholder="Enter booking ID"
                                    onChange={this.handleChange}
                                    value={this.state.form.result}
                                    disabled={false} />
                            </div>
                            <button type="submit" className="btn btn-primary mb-2">Submit</button>
                        </Form>
                    </Form>
                </div>
            );
        }
    }
}

const ACTIONS = {
    BOARD: "Board",
    BOOK: "Book",
    UPDATE: "Update",
    SCANNER: "Scanner"
}
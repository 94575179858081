import { useState } from 'react';
import * as ALERTS from '../constants/alerts.js';

export const AlertProvider = () => {
    let [data, setData] = useState(null);

    const send = (message, type, callback) => {
        setData({
            show: true,
            type: type,
            message: message
        });

        if (type !== ALERTS.OFFLINE) {
            setTimeout(() => {
                setData({
                    show: false,
                    type: type,
                    message: ""
                });
            }, 4000);
        }

        if (typeof callback === 'function') {
            callback();
        }
    };

    let values = { data, send };

    return values;
}
import moment from "moment";

export function initForm(officeId = 0) {
    return {
        "firstname": "",
        "lastname": "",
        "email": "",
        "phone": "",
        "route_id": 0,
        "route_range": 0,
        "price_total": 0.0,
        "price": {},
        "price_merchandise": 0.0,
        "paidCreditCard": 0.0,
        "paidCash": 0.0,
        "paidEFT": 0.0,
        "paidInvoice": 0.0,
        "paidPayFast": 0.0,
        "paidDebt": 0.0,
        "commissionPercent": 0,
        "commission": 0,
        "vat": 0.0,
        "passengers": 1,
        "passengersKids": 0,
        "passengersToddlers": 0,
        "voucher_number": 0,
        "payment_type_id": 0,
        "payment_reference": "",
        "sales_agent": "",
        "type_id": 0,
        "office_id": officeId,
        "pilot": "",
        "craft": "",
        "flightNumber": "",
        "notes": "",
        "notesAccounting": "",
        "status_id": 0,
        "date": moment().toDate()
    }
}

export const STATUS = {
    UPDATE: "Update",
    CREATE: "New Booking",
    VIEW: "View Booking",
    PAYMENT: "Payment",
    BOARD: "Boarding",
    FILTER: "Filter",
    SCANNER: "Scanner"
}
export function Crafts(auth) {
    if(auth.user.accountId === 1) {
        return auth.lookups.filter((item) => item.lookupName === "crafts" && item.value3 === "1").map(function(i){
            return {
                "id": i.name,
                "name": i.name + " - " + i.value2,
                "val1": "3",
                "value4": i.value4
            }
        });
    } else if (auth.user.accountId === 2) {
        return auth.lookups.filter((item) => item.lookupName === "crafts" && item.value3 === "2").map(function(i){
            return {
                "id": i.name,
                "name": i.name,
                "val1": "3",
                "value4": i.value4
            }
        });
    }
}

export function Routes(auth) {
    if(auth.user.accountId === 1) {
        return auth.lookups.filter((item) => item.lookupName === "booking_routes" && item.value4 === "1").map(function(i){
            return {
                "id": i.value,
                "name": i.name,
                "val1": i.value2
            }
        });
    } else if (auth.user.accountId === 2) {
        return auth.lookups.filter((item) => item.lookupName === "booking_routes" && item.value4 === "2").map(function(i){
            return {
                "id": i.value,
                "name": i.name,
                "val1": i.value2
            }
        });
    }
}

export function Pilots(auth) {
    if(auth.user.accountId === 1) {
        return auth.lookups.filter((item) => item.lookupName === "pilots" && item.value3 === "1").map(function(i){
            return {
                "id": i.value,
                "name": i.name
            }
        });
    } else if (auth.user.accountId === 2) {
        return auth.lookups.filter((item) => item.lookupName === "pilots" && item.value3 === "2").map(function(i){
            return {
                "id": i.value,
                "name": i.name
            }
        });
    }
}

export function BookingOffices(auth) {
    return auth.lookups.filter((item) => item.lookupName === "booking_offices").map(function(i){
        return {
            "id": i.value,
            "name": i.name
        }
    });
}

export function AgentsSales(auth) {
    return auth.lookups.filter((item) => item.lookupName === "agents_sales").map(function(i){
        return {
            "id": i.value,
            "name": i.name
        }
    });
}

export function Lookups(auth) {
    return auth.lookupTables;
}
import React from 'react';
import './sidebar.scss';

import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ReactMarkdown from 'react-markdown'
import changelogPath from './../../content/changelog.md'

function initSidebar() {
    if(window.innerWidth < 992) {
        return {
            show: false,
            className: "",
            modal: {
                show: false
            },
            changelog: ""
        }
    } else {
        return {
            show: false,
            className: "",
            modal: {
                show: false
            },
            changelog: ""
        }
    }
}

class SideNav extends React.Component {
    constructor(props) {
        super(props);

        this.state = initSidebar();

        this.toggle = this.toggle.bind(this);
        this.callback = this.callback.bind(this);
        this.checkDimensions = this.checkDimensions.bind(this);
    }

    toggle(close = null) {
        if(close && close === true) {
            if(window.innerWidth < 992) {
                this.setState(state => {
                    state = {
                        show: false,
                        className: ""
                    }
                    return state;
                });
                this.callback(false);
            }
            return;
        }
        
        if(this.state.show) {
            this.setState(state => {
                state = {
                    show: false,
                    className: ""
                }
                return state;
            });
            this.callback(false);
        } else {
            this.setState(state => {
                state = {
                    show: true,
                    className: "show"
                }
                return state;
            });
            this.callback(true);
        }
    }

    callback(show) {
        if(this.props.callback) {
            this.props.callback(show);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkDimensions);

        fetch(changelogPath).then((response) => response.text()).then((text) => {
            this.setState({ changelog: text });
            let regExp = /\(([^)]+)\)/;
            let matches = regExp.exec(text);
    
            if (matches) {
                var match = matches[1];
                this.setState({ version: match })
            }
        });

        // Get current version

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkDimensions);
    }

    checkDimensions() {
        if(window.innerWidth < 992) {
            this.setState(state => {
                state = {
                    show: false,
                    className: ""
                }
                return state;
            });
            this.callback(false);
        }
    }

    category(i, category) {
        if(category.options.length > 0) {
            return (
                <>
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>{category.label}</span>
                </h6>
                <ul className="nav flex-column">
                {category.options.map((option, ii) => (<Link key={ii} className="nav-link" to={option.path} onClick={() => this.toggle(true)}>{option.icon}{option.label}</Link>))}
                </ul>
                <hr/>
                </>
            );
        }
        else {
            return;
        }
    }

    handleOpen = () => this.setState(state => { state.modal = { show: true, title: "Change Log" }; return state; });
    handleClose = () => { this.setState({ modal: { show: false } })};

    render() {
        return (
            <>
            <div className={"screen-overlay "+this.state.className} onClick={this.toggle}></div>
            <nav id="sidebar" className={"col-md-3 col-lg-2 d-md-block sidebar "+this.state.className}>
            <div className="sidebar-toggle" onClick={this.toggle}>
                <div className="sidebar-toggle-inner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
                <div className="brand-logo">
                    <div id="logo" style={{backgroundImage: "url("+this.props.config.logo+")"}}></div>
                </div>
                <div className="sidebar-sticky pt-3">
                {this.props.config.options.map((category, i) => (
                    this.category(i, category)
                ))}
                </div>
                <div className="sidebar-sticky pt-3 sidebar-version">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={this.handleOpen}>v{this.state.version}</button>
                </div>
            </nav>
            <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="changelog-markdown"><ReactMarkdown>{this.state.changelog}</ReactMarkdown></div>
                    </Modal.Body>
            </Modal>
            </>
        );
    }
}

export default SideNav;
import React from 'react';
import DataTable from 'react-data-table-component';
import * as SETTINGS from '../../constants/settings.js';
import * as LOOKUPS from '../../constants/lookups.js';
import * as BOOKING_FORM from '../../constants/bookingForm.js';
import { AppContext } from '../../Contexts/AppContext';
import DataAccessLayer from '../../DAL';

import Nav from 'react-bootstrap/Nav';
import './Bookings.scss';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import BookingFormView from '../Calendar/BookingForm';
import Scanner from './Scanner';
import BoardingForm from '../Calendar/BoardingForm';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BookingFilterFormView from './BookingFilterForm.js';

import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import GetApp from '@mui/icons-material/GetApp';
import Apps from '@mui/icons-material/Apps';

import * as ALERTS from '../../constants/alerts.js';
import * as VIEW from "../../constants/Views";
import * as LOOKUP from '../../utils/lookup.js';

import BookingForm from "../EventsView/BookingForm";
import PaymentForm from "../EventsView/PaymentForm";
import Form from "react-bootstrap/Form";
import moment from "moment";

export default class BookingsView extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: "",
            rowsTotal: 0,
            rowsPerPage: 10,
            page: 1,
            sortColumn: "id",
            sortDirection: "desc",
            actionQuery: "",
            filters: [],
            global_search: "",
            mobile: false,
            modal: {
                show: false,
                action: "book",
                data: {
                    "firstname": "",
                    "lastname": "",
                    "email": "",
                    "phone": "",
                    "route_id": 0,
                    "route_range": 0,
                    "price_total": 0.0,
                    "price": {},
                    "priceKids": 0,
                    "price_merchandise": 0.0,
                    "paidCreditCard": 0.0,
                    "paidCash": 0.0,
                    "paidEFT": 0.0,
                    "paidInvoice": 0.0,
                    "paidPayFast": 0.0,
                    "paidDebt": 0.0,
                    "commissionPercent": 0,
                    "commission": 0,
                    "vat": 0.0,
                    "passengers": 1,
                    "passengersKids": 0,
                    "voucher_number": 0,
                    "payment_type_id": 0,
                    "payment_reference": "",
                    "sales_agent": "",
                    "type_id": 0,
                    "office_id": 0,
                    "pilot": "",
                    "craft": "",
                    "flightNumber": "",
                    "notes": "",
                    "notesAccounting": "",
                    "status_id": 0,
                    "eventId": 0,
                    "externalId": 0,
                    "date": ""
                }
            },
            isLoaded: false,
            inputDisabled: false,
            form: {
                "firstname": "",
                "lastname": "",
                "email": "",
                "phone": "",
                "route_id": 0,
                "route_range": 0,
                "price_total": 0.0,
                "price": {},
                "priceKids": 0,
                "price_merchandise": 0.0,
                "paidCreditCard": 0.0,
                "paidCash": 0.0,
                "paidEFT": 0.0,
                "paidInvoice": 0.0,
                "paidPayFast": 0.0,
                "paidDebt": 0.0,
                "commissionPercent": 0,
                "commission": 0,
                "vat": 0.0,
                "passengers": 1,
                "passengersKids": 0,
                "voucher_number": 0,
                "payment_type_id": 0,
                "payment_reference": "",
                "sales_agent": "",
                "type_id": 0,
                "office_id": 0,
                "pilot": "",
                "craft": "",
                "flightNumber": "",
                "notes": "",
                "notesAccounting": "",
                "status_id": 0,
                "eventId": 0,
                "externalId": 0,
                "date": ""
            },
            data: [],
            lookups: {
                booking_types: [],
                booking_routes: [],
                booking_offices: [],
                booking_payment_types: [],
                booking_sales_agents: []
            },
            formStatus: VIEW.FORM_STATUS.CREATE,
        };
        this.action = this.action.bind(this);
        this.getData = this.getData.bind(this);
        this.filtersUpdate = this.filtersUpdate.bind(this);
        this.DAL = new DataAccessLayer(this.context.auth.token);
    }

    async componentDidMount() {
        this.setLoading(true);
        await this.initState();
        this.getData(1);
        await this.getLookups();
        // this.setLoading(false);
    }

    componentDidUpdate(prevProps) {
        if(this.props.reload) {
            this.props.toggleReload(false);
            this.getLookups();
            this.getData(1);
        }
    }

    filtersUpdate(filters) {
        this.setState(state => { state.filters = filters; return state; });
        console.log(this.state.filters);
        this.context.alert.send("Updated filters", ALERTS.SUCCESS);
        this.getData(1);
    }

    async initState() {
        let action = (new URLSearchParams(window.location.search)).get("action");
        
        if (action && action === "board") {
            let actionQuery = "status=Booked"
            let title = "Board";
            await this.setState({title: title, filters: [...this.state.filters, actionQuery] });
        }
        else {
            let title = "Bookings";
            await this.setState({title: title});
        }
    }

    getData(page) {
        // this.setLoading(true);
    
        let filters = [...this.state.filters]
        filters.push(`global_search=${this.state.global_search}`);
        filters.push(`sort=${this.state.sortColumn}-${this.state.sortDirection}`);
        let query = filters.join('&');
        query = query.length > 0 ? "?"+query : "";

        this.DAL.bookings.get(page, this.state.rowsPerPage, query).then((response) => {
            this.setData(response.data.data);
            this.setRowsTotal(response.data.total);
            // this.setLoading(false);
        });
    }

    
    getDataSort = (page, sortColumn, sortDirection) => {
        // this.setLoading(true);
    
        let filters = [...this.state.filters]
        filters.push(`global_search=${this.state.global_search}`);
        filters.push(`sort=${sortColumn}-${sortDirection}`);
        let query = filters.join('&');
        query = query.length > 0 ? "?"+query : "";

        this.DAL.bookings.get(page, this.state.rowsPerPage, query).then((response) => {
            this.setData(response.data.data);
            this.setRowsTotal(response.data.total);
            // this.setLoading(false);
        });
    }


    action(row, action) {
        if(action) {
            if(action === "board") {
                console.log("BOARD BOOKING");
                this.setState({ modal: { show: true, title: "Board", action: "board"}, form: row, formStatus: BOOKING_FORM.STATUS.BOARD});
            }
            else {
                this.setState({ modal: { show: true, title: "View Booking", action: "view" }, form: row });
            }
        }
        
        console.log(row);
    }

    handlePageChange = page => {
        this.getData(page);
    };

    setData(data) {
        this.setState(state => {
            state.data = data;
            state.loading = false;
            return state;
        });
    }

    setLoading(loading) {
        this.setState(state => {
            state.loading = loading;
            return state;
        });
    }

    setRowsPerPage(perPage) {
        this.setState(state => {
            state.rowsPerPage = perPage;
            return state;
        });
    }

    setRowsTotal(rowsTotal) {
        this.setState(state => {
            state.rowsTotal = rowsTotal;
            return state;
        });
    }
    
    handlePerRowsChange = async (newPerPage, page) => {
        // this.setLoading(true);

        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?"+query : "";

        this.DAL.bookings.get(page, newPerPage, query).then((response) => {
            this.setData(response.data.data);
            this.setRowsPerPage(newPerPage);
            // this.setLoading(false);
        });
    };

    getLookups = async () => {
        this.setState(state => {
            let crafts = LOOKUP.Crafts(this.context.auth);
            // crafts.unshift({"id":"All Craft","name":"All Craft", "val1": "3"});
            state.lookups.booking_offices = LOOKUP.BookingOffices(this.context.auth);
            state.lookups.pilots = LOOKUP.Pilots(this.context.auth);
            state.lookups.booking_routes = LOOKUP.Routes(this.context.auth);
            state.lookups.crafts_filter = crafts;
            state.lookups.crafts = LOOKUP.Crafts(this.context.auth);
            state.lookups.agents_sales = LOOKUP.AgentsSales(this.context.auth);
            return state;
        });
        // const lookups = ["crafts", "pilots", "booking_types", "booking_routes", "booking_offices", "booking_payment_types", "booking_sales_agents"];


        // this.DAL.lookups.get(lookups)
        //   .then((response) => {
        //     this.setState(state => {
        //       state.lookups = response.data;
        //       return state;
        //     });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     this.setState(state => {
        //       state.error = error;
        //       return state;
        //     });
        //     this.context.alert.send(error.message, ALERTS.ERROR);
        //   });  
    }

    handleChange = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.form[name] = val; return state; });
        return val;
    }

    getFlightNumber = async () => {
        this.DAL.bookings.flightNumber()
            .then((response) => this.setFlightNumber(response.data.flightNumber))
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    // state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    setFlightNumber = (flightNumber) => {
        this.setState(state => {
            state.form.flightNumber = flightNumber;
            return state;
        });
    }

    // getFlightNumber = async () => {
    //     this.DAL.bookings.getFlightNumber()
    //     .then((response) => this.setState(state => {
    //         state.modal.data.flightNumber = response.data.flightNumber;
    //         return state;
    //       }))
    //     .catch((error) => {
    //       console.log(error);
    //       this.setState(state => {
    //         state.isLoaded = true;
    //         state.error = error;
    //         return state;
    //       });
    //       this.context.alert.send(error.message, ALERTS.ERROR);
    //     });
    // }

    getScannerBooking = (id) => {
        id = id.startsWith("202100") && id.length > 6 ? id.substring(6) : id;
        this.DAL.bookings.getItem(id)
            .then((response) => {
                this.setState({ modal: { show: true, title: "Board", action: "board", data: response.data } });
            })
            .catch((error) => {
                console.log(error);
                this.context.alert.send(error.message.includes("404") ? `Booking ID ${id} not found!` : error.message, ALERTS.ERROR);
            });
    }

    payment = () => {
        this.setState((state) => {
            state.formStatus = BOOKING_FORM.STATUS.PAYMENT;
            return state;
        });
    }

    goToBoard = () => {
        console.log("goToBoard");
        this.setState((state) => {
            state.formStatus = BOOKING_FORM.STATUS.BOARD;
            return state;
        });
    }

    back = () => {
        this.setState((state) => {
            state.formStatus = BOOKING_FORM.STATUS.VIEW;
            return state;
        });
    }

    handleDeleteBooking = (id = null) => {
        if(id !== null && id > 0) {}
        else {
            id = this.state.form.id > SETTINGS.BOOKING_ID_OFFSET ? this.state.form.id - SETTINGS.BOOKING_ID_OFFSET : this.state.form.id;
        }

        if(!window.confirm('Are you sure you want to delete booking #'+id+'?')) { return; }

        // this.setState(state => {
        //     state.enabled = false;
        //     state.inputDisabled = true;
        //     return state;
        // });

        //this.setState(state => { state.form.status_id = 2; return state; })

        this.DAL.bookings.delete(id)
            .then((response) => {
                console.log(response);
                this.context.alert.send("Booking #" + id + " Deleted", ALERTS.SUCCESS)
                this.handleClose();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    ModalBody = () => {
        switch(this.state.formStatus) {
            case BOOKING_FORM.STATUS.SCANNER:
                return <Scanner action={this.state.modal.action}  lookups={this.state.lookups} data={this.state.modal.data} displayAlert={this.context.alert.send} token={this.context.auth.token} user={this.context.auth.user} getBooking={this.getScannerBooking} close={this.handleClose} />
            case BOOKING_FORM.STATUS.CREATE:
            case BOOKING_FORM.STATUS.VIEW:
                // let x = (<BookingFormView key={this.state.form.ID}
                //                          lookups={this.state.lookups}
                //                          data={this.state.data}
                //                          board={this.board}
                //                          payment={this.payment}
                //                          user={this.context.auth.user}
                //                          formData={this.state.form}
                //                          status={this.state.formStatus}
                //                          saveBooking={this.saveBookingEvent}
                //                          cancelEvent={this.cancelEvent}
                //                          handleCancel={this.handleCancel}
                //                          handleChange={this.handleChange}
                //                           handleDeleteBooking={this.handleDeleteBooking}
                //                          handleDateTime={this.handleDateTime}></BookingFormView>);
                return <BookingFormView status={this.state.formStatus}
                                        payment={this.payment}
                                        goToBoard={this.goToBoard}
                                        lookups={this.state.lookups}
                                        formData={this.state.form}
                                        displayAlert={this.context.alert.send}
                                        token={this.context.auth.token}
                                        user={this.context.auth.user}
                                        handleChange={this.handleChange}
                                        handleDeleteBooking={this.handleDeleteBooking}
                                        updateBooking={this.updateBooking}
                                        handleClose={this.handleClose} />
            case BOOKING_FORM.STATUS.PAYMENT:
                return (<PaymentForm key={this.state.form.ID}
                                     lookups={this.state.lookups}
                                     data={this.state.data}
                                     back={this.back}
                                     payment={this.payment}
                                     user={this.context.auth.user}
                                     formData={this.state.form}
                                     status={this.state.formStatus}
                                     saveBooking={this.saveBookingEvent}
                                     updateBooking={this.updateBooking}
                                     handleCancel={this.handleCancel}
                                     handleChange={this.handleChange}
                                     handleDateTime={this.handleDateTime}
                                     paymentPrintSlip={this.paymentPrintSlip}
                                     paymentEmailInvoice={this.paymentEmailInvoice}></PaymentForm>);
            case BOOKING_FORM.STATUS.FILTER:
                return <BookingFilterFormView status={this.state.formStatus} lookups={this.state.lookups} data={this.state.modal.data} displayAlert={this.context.alert.send} token={this.context.auth.token} user={this.context.auth.user} close={this.handleClose} filtersUpdate={this.filtersUpdate} />
            case BOOKING_FORM.STATUS.BOARD:
                return (<BoardingForm
                    key={this.state.form.ID}
                    lookups={this.state.lookups}
                    getFlightNumber={this.getFlightNumber}
                    board={this.board}
                    user={this.context.auth.user}
                    formData={this.state.form}
                    handleCancel={this.handleClose}
                    handleChange={this.handleChange}></BoardingForm>);
        }
    }

    board = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        this.setState(state => { this.state.form.status_id = 2; return state; })

        this.DAL.bookings.board(this.state.form)
            .then((response) => {
                this.context.alert.send("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
                this.handleClose();
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    book = () => this.setState(state => { state.modal = { show: true, title: "New Booking" }; state.form = BOOKING_FORM.initForm(); state.formStatus = BOOKING_FORM.STATUS.CREATE; return state; });

    scanner = () => this.setState(state => { state.modal = { show: true, title: "Scanner" }; state.formStatus = BOOKING_FORM.STATUS.SCANNER; return state; });

    filter = () => this.setState(state => { state.modal = { show: true, title: "Filter Bookings" };  state.formStatus = BOOKING_FORM.STATUS.FILTER; return state; });

    handleClose = () => { this.getData(1); this.setState({ modal: { show: false }, formStatus: BOOKING_FORM.STATUS.VIEW, form: BOOKING_FORM.initForm() })};

    handleChange_GlobalSearch = (event) => {
        const val = event.target.value;
        this.setState(state => { state.global_search = val; return state; });

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setLoading(true);
            this.getData(1);
        }, 1000);

        return val;
    }

    updateBooking = () => {
        this.setState(state => {
            state.enabled = false;
            return state;
        });

        let formData = {... this.state.form};

        formData.date = moment(formData.date).format('YYYY-MM-DDTHH:mm:ss');

        this.DAL.bookings.update(formData)
            .then((response) => {
                this.context.alert.send("Booking Updated", ALERTS.SUCCESS)
                this.getData(1);
            })
            .catch((error) => {
                console.log(error);
                this.setState(state => {
                    state.isLoaded = true;
                    state.error = error;
                    return state;
                });
                this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    paymentPrintSlip = (id) => {
        id = this.state.form.id > SETTINGS.BOOKING_ID_OFFSET ? this.state.form.id - SETTINGS.BOOKING_ID_OFFSET : this.state.form.id;

        // https://local.printservice:6264
        this.DAL.printService.print(id)
            .then((response) => {
                this.context.alert.send("Printing Slip", ALERTS.SUCCESS);
            })
            .catch((error) => {
                console.log(error);
                // this.setState(state => {
                //     state.isLoaded = true;
                //     state.error = error;
                //     return state;
                // });
                // this.context.alert.send(error.message, ALERTS.ERROR);
            });
    }

    handleSort = (column, sortDirection) => {
        let col = String(column.selector).split('.')[1];
        this.setState(state => {
            state.sortColumn = col;
            state.sortDirection = sortDirection;
            return state;
        });
        this.getDataSort(1, col, sortDirection);
    }


    render() {
        const { loading, data, rowsTotal } = this.state;

        let action = (this.state.title ==="Board" ?
            <button type="button" className="btn btn-sm btn-secondary" onClick={this.scanner}>Scanner</button>:<></>);
            //<button type="button" className="btn btn-sm btn-secondary" onClick={this.book}>New Booking</button>);

        // TODO: Re-enable New Booking form (Disabled for refactor)
        // TODO: Re-enable Filter Form (Disabled for refactor)

        return (
            <div id="bookings-view">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                    <h1 className="h4"><EventAvailableIcon></EventAvailableIcon>{this.state.title}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="bd-example">
                            <input
                                type="text"
                                className="form-control mr-2"
                                id="global_search"
                                name="global_search"
                                placeholder="Search..."
                                value={this.state.global_search}
                                onChange={this.handleChange_GlobalSearch} />
                        {action}
                        </div>
                    </div>
                </div>
                <DataTable
                    defaultSortAsc={false}
                    defaultSortField="id"
                    className="shadow-sm"
                    columns={columns(this.action)}
                    data={data}
                    onRowClicked={this.action}
                    progressPending={loading}
                    onSort={this.handleSort}
                    sortServer
                    pagination
                    paginationServer
                    paginationTotalRows={rowsTotal}
                    selectableRows
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                />
                <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.ModalBody()}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const columns = action => [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'First Name',
        selector: row => row.firstname,
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: row => row.lastname,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Craft',
        selector: row => row.craft,
        sortable: true,
    },
    {
        name: 'Pilot / Captain',
        selector: row => row.pilot,
        sortable: true,
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Boarded',
        selector: row => row.boarded,
        sortable: true,
    },
    {
        name: 'Created',
        selector: row => row.created_at,
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => <Actions row={row} status={row.status_id} action={action}></Actions>,
    },
];

function Actions(props) {
    if (props.row.status_id === 1) {
        return (<><button className="btn btn-sm btn-primary" onClick={() => props.action(props.row, "board")} >Board</button></>);
    }
    return "";
}
import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class EventsDAL {
    constructor(token) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    create(event) {
        return axios.post(SETTINGS.API_BASE_URL + `/events`, event, this.config);
    }

    cancel(id) {
        return axios.delete(SETTINGS.API_BASE_URL + `/events/cancel/${id}`, this.config);
    }

    getAll(resource, activeOnly, start = null, end = null) {
        if(start === null || end === null) {
            return axios.get(`${SETTINGS.API_BASE_URL}/events?resource=${resource}&activeOnly=${activeOnly}`, this.config);
        }
        return axios.get(`${SETTINGS.API_BASE_URL}/events?resource=${resource}&activeOnly=${activeOnly}&start=${start}&end=${end}`, this.config);
    }
}
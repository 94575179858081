// Import packages
import React from 'react';
import './BookingForm.scss';
import * as SETTINGS from '../../constants/settings.js';
import * as ALERTS from '../../constants/alerts.js';
import * as ROLES from '../../constants/roles.js';
import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";
import moment from "moment";
import EventIcon from '@mui/icons-material/Event';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import DataAccessLayer from '../../DAL';

export default class BookingFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      inputDisabled: false,
      data: {
        booking_types: [],
        booking_routes: [],
        booking_offices: [],
        booking_payment_types: [],
        booking_sales_agents: []
      },
        booking_routes: [
          {"id":0,"name":"Other","val1":"0.3"},
          {"id":1,"name":"Hopper", "val1": "0.2"},
          {"id":2,"name":"Camps bay & Hout bay", "val1": "0.3"},
          {"id":3,"name":"Robbin Island", "val1": "0.3"},
          {"id":4,"name":"Robbin Island & Hopper", "val1": "0.5"},
          {"id":5,"name":"Two Oceans", "val1": "0.5"},
          {"id":7,"name":"Combat", "val1": "0.5"},
          {"id":8,"name":"Combat Lite", "val1": "0.3"},
          {"id":9,"name":"City Explorer", "val1": "0.1"},
          {"id":10,"name":"Full Peninsula", "val1": "1.0"},
          {"id":11,"name":"(PVT Charter) Clifton Shores", "val1": "2"},
          {"id":12,"name":"(PVT Charter) Sundowners", "val1": "2"},
          {"id":13,"name":"(PVT Charter) Sunset Cruise & Dine", "val1": "3"},
          {"id":14,"name":"(PVT Charter) Grand Clifton Experience", "val1": "3"},
          {"id":15,"name":"(PVT Charter) Half Day", "val1": "4"},
          {"id":16,"name":"(PVT Charter) Full Day", "val1": "8"},
          {"id":17,"name":"(PVT Charter) Sun & Sea Safari", "val1": "2"}],
      form: {
        "firstname": "",
        "lastname": "",
        "email": "",
        "phone": "",
        "route_id": 0,
        "route_range": 0,
        "price_total": 0.0,
        "price": {},
        "price_merchandise": 0.0,
        "paidCreditCard": 0.0,
        "paidCash": 0.0,
        "paidEFT": 0.0,
        "paidInvoice": 0.0,
        "paidPayFast": 0.0,
        "paidDebt": 0.0,
        "commissionPercent": 0,
        "commission": 0,
        "vat": 0.0,
        "passengers": 1,
        "voucher_number": 0,
        "payment_type_id": 0,
        "payment_reference": "",
        "sales_agent": "",
        "sales_agent_id": "",
        "type_id": 0,
        "office_id": 0,
        "pilot": "",
        "craft": "",
        "flightNumber": "",
        "notes": "",
        "notesAccounting": "",
        "status_id": 0,
        "date": moment().toDate()
      },
      enabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.board = this.board.bind(this);
    this.DAL = new DataAccessLayer(this.props.token);
  }

  async componentDidMount() {
    switch (this.props.action) {
      case "book":
        this.setState( state => { state.inputDisabled = false; state.form.date = moment().toDate(); state.action = ACTIONS.BOOK; state.submitFunction = this.create; return state; });
        break;
      case "view":
        this.setState({ inputDisabled: false, action: ACTIONS.UPDATE, submitFunction: this.update });
        break;
      case "board":
        this.setState({ inputDisabled: true, action: ACTIONS.BOARD, submitFunction: this.board });
        break;
    }

    if (this.props.lookups) {
      this.setState({data: this.props.lookups});
    }

    if (this.props.data) {
      this.setState({ form: this.props.data });
    }
  }

  handleChange(e) {
    const val = e.target.value;
    const name = e.target.name;
    this.setState(state => { state.form[name] = val; return state; });
    // if(name === "price") { this.updateCommissionValue(); this.updatePriceTotal(); }
    // if(name === "price_merchandise") { this.updatePriceTotal(); }
    if(name === "commission") { this.updateCommissionValue(); this.updatePriceTotal(); }
    if(name === "commission_value") { this.updateCommission(); this.updatePriceTotal(); }
    if(name === "route_id") {
      let event = {
        target: {
          value: this.state.booking_routes.filter((x) => x.id === parseInt(val))[0].val1,
          name: "route_range"
        }
      }
      console.log(event);
      this.handleChange(event);
    }
    return val;
  }

  create(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {

      this.setState(state => {
        state.enabled = false;
        return state;
      });

      this.DAL.bookings.create(this.state.form)
        .then((response) => {
          console.log(response);
          this.props.displayAlert("Booking Created", ALERTS.SUCCESS)
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  update(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      this.DAL.bookings.update(this.state.form)
        .then((response) => {
          console.log(response);
          this.props.displayAlert("Booking Updated", ALERTS.SUCCESS)
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  board(event) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.setState(state => {
        state.enabled = false;
        return state;
      });

      this.setState(state => { state.form.status_id = 2; return state; })

      this.DAL.bookings.board(this.state.form)
        .then((response) => {
          console.log(response);
          this.props.displayAlert("Booking #" + this.state.form.id + " Boarded", ALERTS.SUCCESS)
          this.props.close();
        })
        .catch((error) => {
          console.log(error);
          this.setState(state => {
            state.isLoaded = true;
            state.error = error;
            return state;
          });
          this.props.displayAlert(error.message, ALERTS.ERROR);
        });
    }
  }

  delete (event) {
    event.preventDefault();

    if(!window.confirm('Are you sure you want to delete booking #'+this.state.form.id+'?')) { return; }

    this.setState(state => {
      state.enabled = false;
      return state;
    });

    this.setState(state => { state.form.status_id = 2; return state; })

    this.DAL.bookings.delete(this.state.form.id)
      .then((response) => {
        console.log(response);
        this.props.displayAlert("Booking #" + this.state.form.id + " Deleted", ALERTS.SUCCESS)
        this.props.close();
      })
      .catch((error) => {
        console.log(error);
        this.setState(state => {
          state.isLoaded = true;
          state.error = error;
          return state;
        });
        this.props.displayAlert(error.message, ALERTS.ERROR);
      });
  }

  updateCommission = () => {
    if(this.state.form.commission && this.state.form.commission > 0 && this.state.form.price && this.state.form.price > 0) {
      this.setState(state => { state.form.commission = (Number(state.form.commission_value) / Number(state.form.price) * 100).toFixed(2); return state; });
    }
  }

  updateCommissionValue = () => {
    if(this.state.form.commission && this.state.form.commission > 0 && this.state.form.price && this.state.form.price > 0) {
      this.setState(state => { state.form.commission_value = (Number(state.form.commission) / 100 * Number(state.form.price)).toFixed(2); return state; });
    }
  }

  updatePriceTotal = () => {
    this.setState(state => { state.form.price_total = (Number(this.state.form.price) + Number(this.state.form.price_merchandise)); return state; });
  }

  handleDateTime = (val) => {
    let date = moment(val).format('YYYY-MM-DDTHH:mm:ss');
    this.setState(state => {
      state.form.date = date;
      return state;
    });
    return val;
  }

  render() {
    const { error, isLoaded, data } = this.state;

    const inputDisabled = this.state.inputDisabled ? true : false;

    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};

    const date = moment(this.state.form.date);

    let totalToPay = (parseInt(this.state.form.price)+parseInt(this.state.form.price_merchandise));
    let debt = totalToPay - parseInt(this.state.form.paidCreditCard) - parseInt(this.state.form.paidCash) - parseInt(this.state.form.paidEFT) - parseInt(this.state.form.paidInvoice) - parseInt(this.state.form.paidPayFast);
    
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="booking-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.state.submitFunction}>
            <div className="row">
              
            </div>
            <div className="row">
              <div className="col-md-6">
                <h4 className="mb-3">Booking</h4>
                <Form.Row>
                <Form.Group as={Col} controlId="date">
                    <Form.Label>Date and Time</Form.Label>
                    <div className="input-group input-group-datetimepicker">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><EventIcon/></span>
                        </div>
                        <Datetime readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="date" value={this.state.form.date} onChange={this.props.handleDateTime} disabled={true} required/>
                    </div>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>PAX Adult</Form.Label>
                    <select className="form-control"
                    id="passengers"
                    name="passengers"
                    placeholder=""
                    value={this.state.form.passengers}
                    onChange={this.handleChange}
                    required
                    disabled={inputDisabled}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                    <option>32</option>
                    <option>33</option>
                    <option>34</option>
                    </select>
                </Form.Group>
                </Form.Row>
                  <Form.Row>
                      <Form.Group as={Col}>
                          <Form.Label>PAX Kids</Form.Label>
                          <select className="form-control"
                                  id="passengersKids"
                                  name="passengersKids"
                                  placeholder=""
                                  value={this.state.form.passengersKids}
                                  onChange={this.handleChange}
                                  required
                                  disabled={inputDisabled}>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                              <option>11</option>
                              <option>12</option>
                              <option>13</option>
                              <option>14</option>
                              <option>15</option>
                              <option>16</option>
                              <option>17</option>
                              <option>18</option>
                              <option>19</option>
                              <option>20</option>
                              <option>21</option>
                              <option>22</option>
                              <option>23</option>
                              <option>24</option>
                              <option>25</option>
                              <option>26</option>
                              <option>27</option>
                              <option>28</option>
                              <option>29</option>
                              <option>30</option>
                              <option>31</option>
                              <option>32</option>
                              <option>33</option>
                              <option>34</option>
                          </select>
                      </Form.Group>
                  </Form.Row>
                  <Form.Row>
                      <Form.Group as={Col}>
                          <Form.Label>PAX Toddlers</Form.Label>
                          <select className="form-control"
                                  id="passengersToddlers"
                                  name="passengersToddlers"
                                  placeholder=""
                                  value={this.state.form.passengersToddlers}
                                  onChange={this.handleChange}
                                  required
                                  disabled={inputDisabled}>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                              <option>11</option>
                              <option>12</option>
                              <option>13</option>
                              <option>14</option>
                              <option>15</option>
                              <option>16</option>
                              <option>17</option>
                              <option>18</option>
                              <option>19</option>
                              <option>20</option>
                              <option>21</option>
                              <option>22</option>
                              <option>23</option>
                              <option>24</option>
                              <option>25</option>
                              <option>26</option>
                              <option>27</option>
                              <option>28</option>
                              <option>29</option>
                              <option>30</option>
                              <option>31</option>
                              <option>32</option>
                              <option>33</option>
                              <option>34</option>
                          </select>
                      </Form.Group>
                  </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="route_id">
                  <Form.Label>Route</Form.Label>
                    <Select
                    name="route_id"
                    required="true"
                    value={this.state.form.route_id}
                    options={this.state.booking_routes}
                    onChange={this.handleChange}
                    disabled={inputDisabled}></Select>
                </Form.Group>
                <Form.Group as={Col} controlId="route_range">
                    <Form.Label>Range</Form.Label>
                    <input
                    type="text"
                    className="form-control"
                    id="route_range"
                    name="route_range"
                    placeholder=""
                    value={this.state.form.route_range}
                    onChange={this.handleChange}
                    required
                    disabled={inputDisabled} />
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    placeholder=""
                    value={this.state.form.firstname}
                    onChange={this.handleChange}
                    required
                    disabled={inputDisabled} />
                </Form.Group>
                <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    placeholder=""
                    value={this.state.form.lastname}
                    onChange={this.handleChange}
                    required
                    disabled={inputDisabled} />
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <input
                    type="tel"
                    minLength="10"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="+27713034000"
                    value={this.state.form.phone}
                    onChange={this.handleChange}
                    disabled={inputDisabled} />
                    <Form.Control.Feedback type="invalid">
                    Please provide a phone number.
                    </Form.Control.Feedback>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="you@example.com"
                    value={this.state.form.email}
                    onChange={this.handleChange}
                    required
                    disabled={inputDisabled} />
                    <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="office_id">
                    <Form.Label>Booking Office</Form.Label>
                    <Select
                      name="office_id"
                      options={this.state.data.booking_offices}
                      required="true"
                      value={this.state.form.office_id}
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="sales_agent_id">
                  <Form.Label>Sales Agent</Form.Label>
                    <Select
                      name="sales_agent_id"
                      options={this.state.data.agents_sales}
                      required="true"
                      value={this.state.form.sales_agent_id}
                      default="0"
                      showId="true"
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="notes">
                      <Form.Label>Booking Notes</Form.Label>
                      <textarea 
                      value={this.state.form.notes ?? ""}
                      onChange={this.handleChange}
                      className="form-control"
                      id="notes"
                      name="notes"
                      rows="4"
                      disabled={inputDisabled}></textarea>
                  </Form.Group>
                </Form.Row>
               
                {/* <Form.Row>
                  <Form.Group as={Col} controlId="payment_type_id">
                    <Form.Label>Payment Type</Form.Label>
                    <Select
                      name="payment_type_id"
                      options={this.state.data.booking_payment_types}
                      required="true"
                      value={this.state.form.payment_type_id}
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="payment_reference">
                    <Form.Label>Payment Reference / Location</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="payment_reference"
                      name="payment_reference"
                      placeholder="UPS1011"
                      value={this.state.form.payment_reference}
                      onChange={this.handleChange} required
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row> */}
                {/* <h4 className="mb-3">Booking</h4>
                <Form.Row>
                <Form.Group as={Col} controlId="date">
                  <Form.Label>Date and Time</Form.Label>
                  <div class="input-group input-group-datetimepicker">
                      <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><EventIcon/></span>
                      </div>
                      <Datetime readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="date" value={date} onChange={this.handleDateTime} disabled={true} required/>
                  </div>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="type_id">
                    <Form.Label>Booking Type</Form.Label>
                    <Select
                      name="type_id"
                      options={this.state.data.booking_types}
                      required="true"
                      value={this.state.form.type_id}
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="route_id">
                    <Form.Label>Route</Form.Label>
                    <Select
                      name="route_id"
                      options={this.state.data.booking_routes}
                      required="true"
                      value={this.state.form.route_id}
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="passengers">
                    <Form.Label>Passengers</Form.Label>
                    <input
                      type="number"
                      min="1"
                      className="form-control"
                      id="passengers"
                      name="passengers"
                      placeholder="Number of passengers"
                      value={this.state.form.passengers}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="sales_agent">
                    <Form.Label>Sales Agent</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="sales_agent"
                      name="sales_agent"
                      placeholder=""
                      value={this.state.form.sales_agent}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="office_id">
                    <Form.Label>Booking Office</Form.Label>
                    <Select
                      name="office_id"
                      options={this.state.data.booking_offices}
                      required="true"
                      value={this.state.form.office_id}
                      onChange={this.handleChange}
                      disabled={inputDisabled}></Select>
                  </Form.Group>
                </Form.Row> */}
                {/* <Form.Row>
                  <Form.Group as={Col} controlId="voucher_number">
                    <Form.Label>Voucher Number</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="voucher_number"
                      name="voucher_number"
                      placeholder=""
                      disabled
                      value={this.state.form.voucher_number} />
                  </Form.Group>
                </Form.Row> */}
              </div>
              <div className="col-md-6">
              <h4 className="mb-3">Payment</h4>
                <Form.Row>
                  <Form.Group as={Col} controlId="payment_reference">
                    <Form.Label>Payment Reference / Location</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="payment_reference"
                      name="payment_reference"
                      placeholder=""
                      value={this.state.form.payment_reference}
                      onChange={this.handleChange} required
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price">
                    <Form.Label>Flight Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price"
                      name="price"
                      placeholder=""
                      value={this.state.form.price}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_discount">
                    <Form.Label>Discount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_discount"
                      name="price_discount"
                      placeholder=""
                      value={this.state.form.price_merchandise}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_merchandise">
                    <Form.Label>Merchandise Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_merchandise"
                      name="price_merchandise"
                      placeholder=""
                      value={this.state.form.price_merchandise}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="paidCreditCard">
                    <Form.Label>Credit Card Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidCreditCard"
                      name="paidCreditCard"
                      placeholder=""
                      value={this.state.form.paidCreditCard}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidCash">
                    <Form.Label>Cash Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidCash"
                      name="paidCash"
                      placeholder=""
                      value={this.state.form.paidCash}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidEFT">
                    <Form.Label>EFT Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidEFT"
                      name="paidEFT"
                      placeholder=""
                      value={this.state.form.paidEFT}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidPayFast">
                    <Form.Label>PayFast Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidPayFast"
                      name="paidPayFast"
                      placeholder=""
                      value={this.state.form.paidPayFast}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidInvoice">
                    <Form.Label>Invoice Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidInvoice"
                      name="paidInvoice"
                      placeholder=""
                      value={this.state.form.paidInvoice}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidDebt">
                    <Form.Label>Debt Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidDebt"
                      name="paidDebt"
                      placeholder=""
                      value={debt}
                      disabled={true} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="commission">
                    <Form.Label>Commission</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">%</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="commission"
                      name="commission"
                      placeholder="Percentage"
                      value={this.state.form.commission}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                  <Form.Group as={Col} controlId="commission_value">
                    <Form.Label>&nbsp;</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="commission_value"
                      name="commission_value"
                      placeholder="Rand Value"
                      value={this.state.form.commission_value}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="price_total">
                    <Form.Label>VAT Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_total"
                      name="price_total"
                      placeholder=""
                      value={totalToPay*0.15}
                      disabled={true} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_total">
                    <Form.Label>Total Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_total"
                      name="price_total"
                      placeholder=""
                      value={totalToPay}
                      disabled={true} />
                      </div>
                  </Form.Group>
                </Form.Row>
              </div>
            </div>
            <hr className="mb-4" />
            <Submit enabled={this.state.enabled} action={this.state.action} role={this.props.user.role} delete={this.delete}></Submit>
          </Form>
        </div>
      );
    }
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Submit(props) {
  let auth = [ROLES.Admin, ROLES.OfficeManager, ROLES.Accountant];

  if(props.enabled) {
    if(auth.includes(props.role)) {
      if(props.action === ACTIONS.UPDATE) {
        return (<div className="form-row"><div className="col"><button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button></div><div className="col"><button type="button" onClick={props.delete} class="btn btn-danger btn-lg btn-block">Delete</button></div></div>);
      }
      else {
        return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
      }
    }
    else
    {
      return (<button className="btn btn-primary btn-lg btn-block" type="submit">{props.action}</button>);
    }
  }
  else {
    return (<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>);
  }
}

function Select(props) {
  // Check if props.default is set and props.value is empty, then set default value
  let value = props.value;
  if (props.default && !props.value) {
    value = props.default;
  }

  // Check if props.showId (example: "(1) Faizel Abrahams") is set, then show id in the dropdown
  let lookup = [];
  if (props.showId && props.options && props.options.length > 0) {
    // Map the options to include the id in the name and sort by descending id
    lookup = props.options.map((item) => {
      return {
        id: item.id,
        name: `(${item.id}) ${item.name}`
      }
    }).sort((a, b) => a.id - b.id);
  } else if (props.options && props.options.length > 0) {
    // Sort by ascending Id
    lookup = props.options.sort((a, b) => a.id - b.id);
  }

  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {lookup.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {lookup.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import './PaymentForm.scss';

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'

import EventIcon from '@mui/icons-material/Event';


export default class PaymentForm extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            inputDisabled: false,
            button: 0
        };
    }

    handleChange = (e) => {
        this.props.handleChange(e);
    }

    handleDateTime = (val) => {
        this.props.handleDateTime(val);
    }

    submitForm = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
        this.setState(state => { state.validated = true; return state; });
      }
      else {
        this.props.saveBooking();
      }
    }

    submitFormUpdate = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
        this.setState(state => { state.validated = true; return state; });
      }
      else {
          if(this.state.button === 1) {
              this.props.updateBooking();
          }
          else if(this.state.button === 2) {
              this.props.invoiceBooking();
          }
      }
    }

    clickedSubmit = () => {this.setState({ button: 1 })}
    clickedInvoice = () => {this.setState({ button: 2 })}

    render() {
        const { loading, data, rowsTotal } = this.state;
        const inputDisabled = this.state.inputDisabled ? true : false;
        const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};
        let totalToPay = (parseInt(this.props.formData.price)+parseInt(this.props.formData.price_merchandise));
        let vat = (totalToPay-(totalToPay/1.15)).toFixed(2);
        let debt = totalToPay - parseInt(this.props.formData.paidCreditCard) - parseInt(this.props.formData.paidCash) - parseInt(this.props.formData.paidEFT) - parseInt(this.props.formData.paidInvoice) - parseInt(this.props.formData.paidPayFast);
        let inputDisabledForm = inputDisabled;

        if(this.props.formData.invoiced > 0) {
            inputDisabledForm = true;
        }

        let buttons = (<></>);

        let printSlipButton = (<></>);

        if (typeof this.props.paymentPrintSlip === "function") {
            printSlipButton = (<button type="button" className="btn btn-success btn-block btn-lg"
                                       onClick={() => this.props.paymentPrintSlip(this.props.formData.id)}>Print Slip</button>)
        }
    
        if(inputDisabled) { buttons = (<></>); }
        else if (this.props.formData.id > 0) {
            if(this.props.formData.invoiced) {


                buttons = (<>
                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={() => this.clickedSubmit()}>Update</button>
                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={() => this.clickedInvoice()}>Re-send Invoice</button>
                    {/*<button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitQuote}>Quote</button>*/}
                    {/*<button type="button" class="btn btn-success btn-block btn-lg" onClick={this.props.paymentPrintSlip}>Print Slip</button>*/}
                    {printSlipButton}
                    <button type="button" onClick={this.props.back} className="btn btn-secondary btn-block btn-lg">Back</button></>);
            }
            else {
                buttons = (<>
                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={() => this.clickedSubmit()}>Update</button>
                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={() => this.clickedInvoice()}>Invoice</button>
                    {/*<button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitQuote}>Quote</button>*/}
                    {/*<button type="button" class="btn btn-success btn-block btn-lg" onClick={this.props.paymentPrintSlip}>Print Slip</button>*/}
                    {printSlipButton}
                    <button type="button" onClick={this.props.back} className="btn btn-secondary btn-block btn-lg">Back</button></>);
            }
        }
        // else {
        //   buttons = (<>
        //     <button className="btn btn-primary btn-block btn-lg" type="submit">Save & Invoice</button>
        //     {/*<button type="button" class="btn btn-success btn-block btn-lg" onClick={this.props.paymentPrintSlip}>Print Slip</button>*/}
        //     <button type="button" onClick={this.props.back} class="btn btn-secondary btn-block btn-lg">Back</button></>);
        // }

        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.submitFormUpdate}>
                <Form.Row>
                  <Form.Group as={Col} controlId="price">
                    <Form.Label>Flight Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price"
                      name="price"
                      placeholder=""
                      value={this.props.formData.price}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabledForm} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_discount">
                    <Form.Label>Discount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_discount"
                      name="price_discount"
                      placeholder=""
                      value={this.state.form.price_discount}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_merchandise">
                    <Form.Label>Merchandise Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_merchandise"
                      name="price_merchandise"
                      placeholder=""
                      value={this.props.formData.price_merchandise}
                      onChange={this.handleChange}
                      disabled={inputDisabledForm} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4"/>
                <Form.Row>
                  <Form.Group as={Col} controlId="sales_agent_id">
                    <Form.Label>Sales Agent</Form.Label>
                      <Select
                        name="sales_agent_id"
                        options={this.props.lookups.agents_sales}
                        required="true"
                        value={this.props.formData.sales_agent_id}
                        default="0"
                        showId="true"
                        onChange={this.handleChange}
                        disabled={inputDisabledForm}></Select>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="commissionPercent">
                    <Form.Label>Commission</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">%</span>
                    </div>
                    <input type="number" max={100} min={0} className="form-control" id="commissionPercent" name="commissionPercent" placeholder="" value={this.props.formData.commissionPercent} onChange={this.handleChange} disabled={inputDisabledForm} required/>
                    </div>
                    {/* <Select
                    name="commissionPercent"
                    required="true"
                    valueName={false}
                    value={this.props.formData.commissionPercent}
                    options={[
                      {"id": 0, "name": '0%'},
                      {"id": 5, "name": '5%'},
                      {"id": 10, "name": '10%'},
                      {"id": 15, "name": '15%'}
                    ]}
                    onChange={this.handleChange}
                    disabled={inputDisabledForm}></Select> */}
                  </Form.Group>
                  <Form.Group as={Col} controlId="commission">
                        <Form.Label>&nbsp;</Form.Label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">R</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                id="commission"
                                name="commission"
                                placeholder=""
                                value={this.props.formData.commission}
                                onChange={this.handleChange}
                                disabled={true}/>
                        </div>
                    </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="paidCreditCard">
                    <Form.Label>Credit Card Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidCreditCard"
                      name="paidCreditCard"
                      placeholder=""
                      value={this.props.formData.paidCreditCard}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidCash">
                    <Form.Label>Cash Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidCash"
                      name="paidCash"
                      placeholder=""
                      value={this.props.formData.paidCash}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidEFT">
                    <Form.Label>EFT Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidEFT"
                      name="paidEFT"
                      placeholder=""
                      value={this.props.formData.paidEFT}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidPayFast">
                    <Form.Label>PayFast Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidPayFast"
                      name="paidPayFast"
                      placeholder=""
                      value={this.props.formData.paidPayFast}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidInvoice">
                    <Form.Label>Invoice Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidInvoice"
                      name="paidInvoice"
                      placeholder=""
                      value={this.props.formData.paidInvoice}
                      onChange={this.handleChange}
                      disabled={inputDisabled} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="paidDebt">
                    <Form.Label>Debt Amount</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="paidDebt"
                      name="paidDebt"
                      placeholder=""
                      value={debt}
                      disabled={true} />
                      </div>
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                  <Form.Group as={Col} controlId="payment_reference">
                    <Form.Label>Payment Reference / Location</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      id="payment_reference"
                      name="payment_reference"
                      placeholder=""
                      value={this.props.formData.payment_reference}
                      onChange={this.handleChange} required
                      disabled={inputDisabled} />
                  </Form.Group>
                </Form.Row>
                <hr className="mb-4" />
                <Form.Row>
                    <Form.Group as={Col} controlId="vat">
                        <Form.Label>VAT Amount</Form.Label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">R</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                id="vat"
                                name="vat"
                                placeholder=""
                                value={vat}
                                disabled={true} />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="price_total">
                    <Form.Label>Total Price</Form.Label>
                    <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="price_total"
                      name="price_total"
                      placeholder=""
                      value={totalToPay}
                      disabled={true} />
                      </div>
                  </Form.Group>
                </Form.Row>
            <hr className="mb-4" />
            {buttons}
            {/* <Submit enabled={this.state.enabled} back={this.props.back} action={this.state.action} delete={this.delete} cancel={this.props.handleCancel} payment={this.props.payment} saveBooking={this.props.saveBooking} paymentPrintSlip={this.props.paymentPrintSlip} paymentEmailInvoice={this.props.paymentEmailInvoice}></Submit> */}
        </Form>
        )
    }
}

const ACTIONS = {
    BOARD: "Board",
    BOOK: "Book",
    UPDATE: "Update"
  }
  
  function Submit(props) {
    return (<>
        <button className="btn btn-primary btn-block btn-lg" type="submit">Save & Invoice</button>
        {/*<button type="button" onClick={props.delete} class="btn btn-success btn-block btn-lg" onClick={props.paymentPrintSlip}>Print Slip</button>*/}
        <button type="button" onClick={props.back} className="btn btn-secondary btn-block btn-lg">Back</button></>);
  }
  
  function Select(props) {
    // Check if props.default is set and props.value is empty, then set default value
    let value = props.value;
    if (props.default && !props.value) {
      value = props.default;
    }
  
    // Check if props.showId (example: "(1) Faizel Abrahams") is set, then show id in the dropdown
    let lookup = [];
    if (props.showId && props.options) {
      // Map the options to include the id in the name and sort by descending id
      lookup = props.options.map((item) => {
        return {
          id: item.id,
          name: `(${item.id}) ${item.name}`
        }
      }).sort((a, b) => a.id - b.id);
    } else {
      // Sort by ascending Id
      lookup = props.options.sort((a, b) => a.id - b.id);
    }
  
    if (props.options) {
      if (props.required) {
        return (
          <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={value} onChange={props.onChange} disabled={props.disabled}>
            <option value="">Choose...</option>
            {lookup.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i}>{item.name}</option>)}
          </select>
        );
      }
      else {
        return (
          <select className="custom-select d-block w-100" id={props.name} name={props.name} value={value} onChange={props.onChange} disabled={props.disabled}>
            <option value="">Choose...</option>
            {lookup.map((item, i) => <option value={props.valueName ? item.name : item.id} key={i}>{item.name}</option>)}
          </select>
        );
      }
    }
    else {
      return (
        <div className="custom-select d-block w-100">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  }
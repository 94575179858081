import React from 'react';
import DataTable from 'react-data-table-component';
import * as SETTINGS from '../../constants/settings.js';
import * as LOOKUPS from '../../constants/lookups.js';
import * as BOOKING_FORM from '../../constants/bookingForm';
import DAL from '../../DAL'; // Data Access Layer

import './LookupsView.scss';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ViewListIcon from '@mui/icons-material/ViewList';
import ProductFilterFormView from './Components/ProductFilterForm';

import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import GetApp from '@mui/icons-material/GetApp';
import Apps from '@mui/icons-material/Apps';

import * as ALERTS from '../../constants/alerts.js';
import * as VIEW from "../../constants/Views";
import ProductForm from "./Components/ProductForm";
import Form from "react-bootstrap/Form";
import moment from "moment";

import { AppContext } from '../../Contexts/AppContext';

const entityController = "lookups";
const entityName = "Lookups";

const STATUS = {
    BOARD: "Board",
    BOOK: "Book",
    CREATE: "Submit",
    UPDATE: "Update"
  }

export default class LookupsView extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: entityName,
            rowsTotal: 0,
            rowsPerPage: 10,
            actionQuery: "",
            filters: [],
            global_search: "",
            mobile: false,
            modal: {
                show: false,
                action: "book",
                data: {
                    "name": "",
                    "craft": "",
                    "routeId": 0,
                    "priceAdult": 0.0,
                    "priceKid": 0.0,
                    "wcProductId": 0,
                    "wcAdultPriceId": 0,
                    "wcKidPriceId": 0,
                    "updateDate": null
                }
            },
            isLoaded: false,
            inputDisabled: false,
            form: {
                "name": "",
                "craft": "",
                "routeId": 0,
                "priceAdult": 0.0,
                "priceKid": 0.0,
                "wcProductId": 0,
                "wcAdultPriceId": 0,
                "wcKidPriceId": 0,
                "updateDate": null
            },
            data: [],
            lookups: [],
            lookupTables: [],
            lookupTableSelected: {},
            formStatus: VIEW.FORM_STATUS.CREATE,
        };
        this.action = this.action.bind(this);
        this.getData = this.getData.bind(this);
        this._DAL = DAL(this.context.auth.token);
    }

    async componentDidMount() {
        this.setLoading(true);
        await this.initState();
        await this.getData(this.state.lookupTableSelected.name);
        await this.getLookups();
        // this.setLoading(false);
    }

    componentDidUpdate(prevProps) {
        // TODO: Reload
        // if(this.props.reload) {
        //     
        //     this.props.toggleReload(false);
        //     this.getLookups();
        //     this.getData(1);
        // }
    }

    async initState() {
        let action = (new URLSearchParams(window.location.search)).get("action");

        // let title = "Products";
        // await this.setState({ title: title });

        this.setState(state => {
            state.lookupTableSelected = this.context.auth.lookupTables[0];
            return state;
        });
    }

    async getData(lookupName) {
        // this.setLoading(true);

        let filters = [...this.state.filters]
        filters.push(`global_search=${this.state.global_search}`);
        let query = filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this._DAL.lookups.edit.get(lookupName, (response) => {
            this.setData(response.data.data);
            this.setRowsTotal(response.data.total);
        });

        // this.setLoading(false);
        return true;
    }

    action(row, action) {
        if (action) {
            this.setState({ modal: { show: true, title: "Edit Lookup", action: STATUS.UPDATE }, form: row });
        }
    }

    create = () => { 
        console.log(this.state.lookupTableSelected);

        this.setState({ 
            modal: { show: true, title: "Create Lookup", action: STATUS.CREATE }, 
            form: {lookupId: this.state.lookupTableSelected.id, name: null, value: 0, value2: null, value3: null, value4: null } 
        });
    };

    handlePageChange = page => {
        this.getData(page);
    };

    setData(data) {
        this.setState(state => {
            state.data = data;
            state.loading = false;
            return state;
        });
    }

    setLoading(loading) {
        this.setState(state => {
            state.loading = loading;
            return state;
        });
    }

    setRowsPerPage(perPage) {
        this.setState(state => {
            state.rowsPerPage = perPage;
            return state;
        });
    }

    setRowsTotal(rowsTotal) {
        this.setState(state => {
            state.rowsTotal = rowsTotal;
            return state;
        });
    }

    handlePerRowsChange = async (newPerPage, page) => {
        // this.setLoading(true);

        let config = { headers: { Authorization: `Bearer ${this.props.token}` } };
        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this._DAL.products.get(page, this.state.rowsPerPage, query, (response) => {
            this.setData(response.data.data);
            this.setRowsPerPage(newPerPage);
            this.setRowsTotal(response.data.total);
        });

        // this.setLoading(false);
    };

    getLookups = async () => {
        const lookups = ["crafts", "booking_routes"];

        this._DAL.lookups.get(lookups, (response) => {
            this.setState(state => {
                state.lookups = this.context.auth.lookups;
                state.lookupTables = this.context.auth.lookupTables;
                return state;
            });
        });
    }

    handleChange = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.form[name] = val; return state; });
        return val;
    }

    ModalBody = () => {
        switch (this.state.modal.action) {
            case STATUS.CREATE:
            case STATUS.UPDATE:
                return <ProductForm status={this.state.modal.action}
                    lookups={this.state.lookups}
                    lookupTable={this.state.lookupTableSelected}
                    displayAlert={this.context.alert.send}
                    token={this.context.auth.token}
                    user={this.context.auth.user}
                    action={this.state.modal.action}
                    data={this.state.form}
                    handleCancel={this.handleCancel}
                    handleChange={this.handleChange}
                    close={this.handleClose} />
        }
    }

    handleClose = () => { this.getData(this.state.lookupTableSelected.name); this.setState({ modal: { show: false }, formStatus: BOOKING_FORM.STATUS.VIEW, form: BOOKING_FORM.initForm() }) };

    handleChange_GlobalSearch = (event) => {
        const val = event.target.value;
        this.setState(state => { state.global_search = val; return state; });

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setLoading(true);
            this.getData(this.state.lookupTableSelected.name);
        }, 1000);

        return val;
    }

    lookupTableList = () => {
        console.log("Hello world!");
        console.log(this.context.auth);
        return this.context.auth.lookupTables.map((table, index) => {
            let words = table.name.split("_")
            let name = words.map((word) => { 
                return word[0].toUpperCase() + word.substring(1); 
            }).join(" ");
            let active = this.state.lookupTableSelected.name === table.name ? " active" : "";
            let className = "list-group-item list-group-item-action"+active;

            return <a key={index} className={className} onClick={() => this.lookupTableOnClick(table)}>{name}</a>
        });
    }

    lookupTableOnClick = (table) => {
        console.log(table);
        this._DAL.lookups.edit.get(table.name, (response) => {
            this.setState(state => {
                state.lookupTableSelected = table;
                return state;
            });
            this.setData(response.data.data);
            this.setRowsTotal(response.data.total);
        });
    }

    render() {
        const { loading, data, rowsTotal } = this.state;

        return (
            <div id="bookings-view">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                    <h1 className="h4"><ViewListIcon/>{this.state.title}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group mr-2">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={this.create}>Add Lookup</button>
                        </div>
                        {/* <div className="bd-example">
                            <input
                                type="text"
                                className="form-control mr-2"
                                id="global_search"
                                name="global_search"
                                placeholder="Search..."
                                value={this.state.global_search}
                                onChange={this.handleChange_GlobalSearch} />
                        </div> */}
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-2 col-lg-2">
                            <div className="list-group shadow-sm mb-4 lookup-table-list">
                                {this.lookupTableList()}
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-10 col-sm-12 col-xs-12">
                            <DataTable
                                defaultSortAsc={false}
                                defaultSortField="id"
                                className="shadow-sm"
                                columns={columns(this.state.lookupTableSelected)}
                                data={data}
                                onRowClicked={this.action}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={rowsTotal}
                                selectableRows
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
                <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.ModalBody()}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const columns = lookupTable => [
    {
        name: '#',
        selector: row => row.value,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: lookupTable.value2Name,
        selector: row => getAccountName(lookupTable.value2Name, row.value2),
        sortable: true,
    },
    {
        name: lookupTable.value3Name,
        selector: row => getAccountName(lookupTable.value3Name, row.value3),
        sortable: true,
    },
    {
        name: lookupTable.value4Name,
        selector: row => getAccountName(lookupTable.value4Name, row.value4),
        sortable: true,
    }
];

function getAccountName(valueName, value) {
    if (valueName === 'Account') {
        return value == 1 ? '(1) Sport Helicopters' : '(2) Waterfront Boat Tours';
    }
    return value;
}
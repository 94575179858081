import { AppContext } from './AppContext';
import { AuthProvider } from './AuthProvider';
import { AlertProvider } from './AlertProvider';

import Alert from '../components/alert'

export const AppContextProvider = ({ children }) => {

    let values = { 
        auth: AuthProvider(), 
        alert: AlertProvider ()
    };

    return (
        <AppContext.Provider value={values}>{children}<Alert alert={values.alert.data}></Alert></AppContext.Provider>
    );
}
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const SIGNOUT = '/signout';
export const PROFILE = '/profile';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';

// SYSTEM
export const HOME = '/';
export const ABOUT = '/about';
export const USERS = '/system/users';

// AUTH
export const LOGIN = '/login';

// CLIENTS
export const BOOKING_FORM = '/booking';
export const BOOKINGS = '/bookings';
export const PRODUCTS = '/products';
export const CALENDAR = '/calendar';
export const EVENTS = '/events';
export const ANALYTICS = '/analytics';
export const REPORT = '/report';
export const REPORT2 = '/report2';
export const REPORTPRINT = '/commsreport';

// MESSAGING
export const CONTACTS = '/messaging/contacts';
export const TEMPLATES = '/messaging/templates';

// SUPPORT
export const SUPPORT = '/support';

// CONTENT
export const LOOKUPS = '/lookups';

// EXPERIMENTAL
export const CRUD = '/crud-demo';
export const CRUD2 = '/crud-demo2';
export const CRUDGenerator = '/crud-generator';
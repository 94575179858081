import axios from 'axios';
import * as SETTINGS from '../constants/settings.js';

export default class LookupsDAL {
    constructor(token) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
        this.edit = {
            get: (lookupName, callback) => {
                axios.get(SETTINGS.API_BASE_URL + "/lookups/edit/"+lookupName, this.config)
                .then((response) => {
                    if(typeof callback === "function") { callback(response) }
                }); 
            },
            update: (lookup) => {
                return axios.put(SETTINGS.API_BASE_URL + `/lookups/edit/${lookup.id}`, lookup, this.config);
            },
            create: (lookup) => {
                return axios.post(SETTINGS.API_BASE_URL + `/lookups/edit`, lookup, this.config);
            }
        }
    }

    get(lookups, callback) {
        axios.post(SETTINGS.API_BASE_URL + "/lookups/select", lookups, this.config)
        .then((response) => {
            if(typeof callback === "function") { callback(response) }
        }); 
    }

    get(lookups) {
        return axios.post(SETTINGS.API_BASE_URL + "/lookups/select", lookups, this.config); 
    }
}
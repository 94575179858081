// Import packages
import React from 'react';
import './FilterForm.scss';

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'
import EventIcon from '@mui/icons-material/Event';
import Datetime from "../../components/react-datetime/DateTime";

import * as SETTINGS from '../../constants/settings.js';
import * as ALERTS from '../../constants/alerts.js';
import * as ROLES from '../../constants/roles.js';
import * as PERM from "../../constants/permissions";

export default class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      inputDisabled: false,
      data: {
        booking_types: [],
        booking_routes: [],
        booking_offices: [],
        booking_payment_types: [],
        booking_sales_agents: []
      },
      enabled: true
    };
  }

  async componentDidMount() {
    this.setState(state => {
      state.isLoaded = true;
      return state;
    })
  }

  handleDateTimeStart = (val) => {
    this.props.handleDateTime(val, "dateTimeStart");
  }

  handleDateTimeEnd = (val) => {
    this.props.handleDateTime(val, "dateTimeEnd");
  }

  dateRange = () => {
    if(PERM.ReportAdmin.Read.includes(this.props.user.role)) {
      const constraints = { hours: { min: 0, max: 24, step: 1 }, minutes: { min:0, max:0, step: 0 }};
      return(
          <>
            <Form.Row>
              <Form.Group as={Col} controlId="dateTimeStart">
                <Form.Label>Date Start</Form.Label>
                <div className="input-group input-group-datetimepicker">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><EventIcon/></span>
                  </div>
                  <Datetime readonly="readonly" onfocus="blur();"
                            pickerOptions={{allowInputToggle: false}} timeConstraints={constraints}
                            name="dateTimeStart" value={this.props.form.dateTimeStart} onChange={this.handleDateTimeStart}/>
                </div>
              </Form.Group>
              <Form.Group as={Col} controlId="dateTimeEnd">
                <Form.Label>Date End</Form.Label>
                <div className="input-group input-group-datetimepicker">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><EventIcon/></span>
                  </div>
                  <Datetime readonly="readonly" onfocus="blur();"
                            pickerOptions={{allowInputToggle: false}} timeConstraints={constraints}
                            name="dateTimeEnd" value={this.props.form.dateTimeEnd} onChange={this.handleDateTimeEnd}/>
                </div>
              </Form.Group>
            </Form.Row>
          </>
      );
    }
  }

  render() {
    const { error, isLoaded, data } = this.state;

    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="filter-form-view">
          <Form noValidate validated={this.state.validated} onSubmit={this.props.updateFilters}>
            <div className="row">
              <div className="col-md-12">
                {this.dateRange()}
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Group By</Form.Label>
                    <select className="form-control"
                            id="groupBy"
                            name="groupBy"
                            placeholder=""
                            value={this.props.form.groupBy}
                            onChange={this.props.handleChange}>
                      <option value='1'>Craft</option>
                      <option value='2'>Pilot</option>
                      <option value='3'>Agent</option>
                      <option value='4'>Event</option>
                    </select>
                  </Form.Group>
                </Form.Row>
                <hr/>
                <Form.Row>
                  <Form.Group>
                    <div className="custom-control custom-switch">
                      <input type="checkbox" checked={this.props.form.boardedOnly} onChange={this.props.handleChange} className="custom-control-input" name="boardedOnly" id="boardedOnly" />
                      <label className="custom-control-label" htmlFor="boardedOnly">Boarded Only</label>
                    </div>
                  </Form.Group>
                </Form.Row>
              </div>
            </div>
            <hr className="mb-4" />
            <button className="btn btn-primary btn-lg btn-block" type="submit">Submit</button>
          </Form>
        </div>
      );
    }
  }
}

function Select(props) {
  if (props.options) {
    if (props.required) {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} required value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
    else {
      return (
        <select className="custom-select d-block w-100" id={props.name} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled}>
          <option value="">Choose...</option>
          {props.options.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)}
        </select>
      );
    }
  }
  else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
// src/views/DailyReportPrintView/DailyReportPrintView.js
import React from 'react';
import { AppContext } from '../../Contexts/AppContext.js';
import BookingsDAL from '../../DAL/BookingsDAL';
import * as SETTINGS from '../../constants/settings.js';
import axios from 'axios';
import './DailyReportPrintView.scss';
import TableChart from '@mui/icons-material/TableChart';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
// import Datetime from "react-datetime";
import EventIcon from '@mui/icons-material/Event';
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import FilterForm from "./FilterForm";
import * as ALERTS from "../../constants/alerts";
import * as ROLES from "../../constants/roles";
import * as LOOKUP from '../../utils/lookup.js';
import * as VIEW from '../../constants/Views';
import BookingForm from "../../components/BookingForm/BookingForm.js";
import PaymentForm from "../../components/PaymentForm/PaymentForm.js";

export default class DailyReportPrint extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: "",
            data: [],
            loading: true,
            rowsTotal: 0,
            rowsPerPage: 10,
            actionQuery: "",
            filters: [],
            form: {
                groupBy: 5,
                boardedOnly: true,
                dateTimeStart: moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0
                }).toDate(),
                dateTimeEnd: moment().set({
                    hour: 23,
                    minute: 59,
                    second: 59
                }).toDate()
            },
            modal: {
                show: false,
                action: "view",
                data: null
            },
            date: "",
            day: ""
        };
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.BookingsDAL = new BookingsDAL(this.context.auth.token);
    }

    print = () => window.print();

    async componentDidMount() {
        this.setLoading(true);
        await this.initState();
        await this.getLookups();
        this.setFilters();
        this.setLoading(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reload) {
            this.props.toggleReload(false);
            this.setFilters();
        }
    }

    async getLookups() {
        const lookups = {};
        lookups.booking_routes = await LOOKUP.Routes(this.context.auth);
        lookups.crafts = await LOOKUP.Crafts(this.context.auth);
        lookups.booking_offices = await LOOKUP.BookingOffices(this.context.auth);
        lookups.agents_sales = await LOOKUP.AgentsSales(this.context.auth);
        // Add other lookups as needed

        this.setState({ lookups });
    }

    async handleRowClick(booking) {
        try {
            const response = await this.BookingsDAL.getItem(booking.id);
            // console.log("Booking details fetched:", response.data);
            this.setState({
                modal: {
                    show: true,
                    title: "View Booking",
                    action: "view",
                    data: response.data
                },
                form: response.data
            });
        } catch (error) {
            // console.error("Error fetching booking details:", error);
            this.context.alert.send(error.message, ALERTS.ERROR);
        }
    }

    async initState() {
        let action = (new URLSearchParams(window.location.search)).get("action");

        let title = "Report";
        await this.setState({ title: title });
    }

    async getData(page) {
        this.setLoading(true);
        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this.BookingsDAL.dailyReport(query, (response) => {
            this.setData(response.data);
            this.setLoading(false);
        });

        return true;
    }

    setData(data) {
        this.setState(state => {
            state.data = data;
            return state;
        });
    }

    handleDateTime = (val, name) => {
        let start = this.state.form.dateTimeStart;
        let end = this.state.form.dateTimeEnd;
        let value = moment(val);

        if (name === "dateTimeStart") {
            start = value;
            if (start.isAfter(end)) {
                end = moment(val).add(1, 'days');
            }
        } else if (name === "dateTimeEnd") {
            end = value;
            if (end.isBefore(start)) {
                start = moment(val).add(-1, 'days');
            }
        }

        this.setState(state => {
            state.form.dateTimeStart = start;
            state.form.dateTimeEnd = end;
            return state;
        });
        return val;
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    setLoading(loading) {
        this.setState(state => {
            state.loading = loading;
            return state;
        });
    }

    updateFilters = (event) => {
        event.preventDefault();
        this.setFilters();
        this.handleClose();
        if (this.props.displayAlert) {
            this.props.displayAlert("Updated filters", ALERTS.SUCCESS);
        } else {
            // console.warn("displayAlert is not defined");
        }
    }

    async setFilters() {
        let result = [];
        let keys = Object.keys(this.state.form);
        let form = this.state.form;

        keys.forEach(function (key) {
            let val = form[key];
            if (val && val !== '' && val !== 0) {
                if (key === 'dateTimeStart' || key === 'dateTimeEnd') { val = encodeURI(moment(val).format("YYYY-MM-DDTHH:mm:ss")); }
                result.push(`${key}=${val}`);
            }
        });

        this.setState(state => { state.filters = result; return state; }, () => {
            this.getData(1);
        });
    }

    goToPayment = () => {
        // Get the current form data, which should have the booking details
        const { form } = this.state;
        // console.log("Navigating to Payment with current form data:", form);

        if (!form || !form.id) {
            // console.error("Form data is missing or invalid:", form);
            return;
        }

        this.setState((state) => ({
            modal: {
                ...state.modal,
                action: VIEW.FORM_STATUS.PAYMENT,
                data: form  
            }
        }));
    }

    back = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                action: "view",
                data: this.state.form
            }
        });
    }

    handleCancel = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                show: false, // Close the modal
                action: "",  // Reset action or set to a default
                data: null   // Optionally clear data
            }
        });
        // console.log("Modal state after cancel:", this.state.modal);
    }

    ModalBody = () => {
        // console.log("Rendering ModalBody with action:", this.state.modal.action, "and formData:", this.state.form);

        const permissions = {
            date: false,
            flightNumber: false,
            passengers: true,
            passengersKids: true,
            route_id: true,
            route_range: false,
            craft: false,
            firstname: true,
            lastname: true,
            phone: true,
            email: true,
            office_id: false,
            sales_agent_id: false,
            notes: false,
        };

        switch (this.state.modal.action) {
            case VIEW.FORM_STATUS.PAYMENT:
                return (
                    <PaymentForm
                        key={this.state.form.ID}
                        lookups={this.state.lookups}
                        data={this.state.data}
                        back={this.back}
                        payment={this.payment}
                        user={this.context.auth.user}
                        formData={this.state.form || {}}
                        status={this.state.formStatus}
                        saveBooking={this.saveBookingEvent}
                        updateBooking={this.updateBooking}
                        invoiceBooking={this.invoiceBooking}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}
                        paymentPrintSlip={this.paymentPrintSlip}
                        paymentEmailInvoice={this.paymentEmailInvoice}
                    />
                );
            case "filter":
                return (
                    <FilterForm
                        action={this.state.modal.action}
                        handleChange={this.handleChange}
                        handleDateTime={this.handleDateTime}
                        lookups={this.state.lookups}
                        data={this.state.modal.data}
                        displayAlert={this.props.displayAlert}
                        form={this.state.form}
                        token={this.props.token}
                        user={this.context.auth.user}
                        close={this.handleClose}
                        updateFilters={this.updateFilters}
                    />
                );
            case "view":
                return (
                    <BookingForm
                        status={this.state.modal.action}
                        lookups={this.state.lookups}
                        formData={this.state.modal.data}
                        displayAlert={this.context.alert.send}
                        token={this.context.auth.token}
                        user={this.context.auth.user}
                        payment={this.goToPayment}
                        handleChange={this.handleChange}
                        handleCancel={this.handleCancel}
                        permissions={permissions}
                    />
                );
            default:
                return null;
        }
    }

    handleClose = () => { this.setState({ modal: { show: false } }) };

    filter = () => this.setState(state => { state.modal = { show: true, title: "Filter", action: "filter", data: null }; return state; });

    render() {
        const { loading, data, rowsTotal } = this.state;

        // Ensure dates are valid
        let today = moment.isMoment(this.state.form.dateTimeStart) ? this.state.form.dateTimeStart.toDate() : this.state.form.dateTimeStart;
        if (!today) today = new Date(); // Fallback to current date if undefined

        let endDate = moment.isMoment(this.state.form.dateTimeEnd) ? this.state.form.dateTimeEnd.toDate() : this.state.form.dateTimeEnd;
        if (!endDate) endDate = new Date(); // Fallback to current date if undefined

        // console.log("Today:", today);
        // console.log("End Date:", endDate);

        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        let yyyy = today.getFullYear();

        let ddEnd = String(endDate.getDate()).padStart(2, '0');
        let mmEnd = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        let yyyyEnd = endDate.getFullYear();

        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let day = days[today.getDay()];

        let date = `${dd}/${mm}/${yyyy}`;
        let dateEnd = `${ddEnd}/${mmEnd}/${yyyyEnd}`;

        let dateTitle = `DATE: ${date} DAY: ${day}`;

        if (ddEnd > dd) {
            dateTitle = `DATE: ${date} to ${dateEnd}`;
        }

        let daily = dd === ddEnd ? 'DAILY ' : '';

        let name = "";
        if (this.context.auth.user.role === ROLES.OfficeStaff) {
            name = <> FOR <b className="daily-report-person">{this.context.auth.user.name.toUpperCase()}</b></>;
        }

        return (
            <div id="daily-report-print-view">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                    <h1 className="h4"><TableChart />{this.state.title}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="bd-example">
                            <div className="btn-group mr-2">
                                <button type="button" className="btn btn-sm btn-secondary" onClick={this.print}>Print</button>
                                <OverlayTrigger
                                    key="fitler"
                                    placement="bottom"
                                    overlay={<Tooltip id={`tooltip-bottom`}>Filter</Tooltip>}>
                                    <button type="button" className="btn btn-sm btn-secondary" onClick={this.filter}>
                                        <FilterListIcon />&nbsp;
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card flight-report-card">
                    <div className="card-body">
                        <p><strong className="float-left">SPORT AVIATION: {daily}REPORT OF TURNOVER{name}</strong><strong className="float-right">{dateTitle}</strong></p>
                        <FlightReportGenerator
                            data={this.state.data}
                            groupBy={this.state.form.groupBy}
                            loading={this.state.loading}
                            onRowClick={this.handleRowClick}
                        />
                    </div>
                </div>
                <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.ModalBody()}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function FlightReportGenerator(props) {
    let grouped = {};
    let crafts = [];
    let groupByCss = "group-craft";
    let groupBy = parseInt(props.groupBy);

    if (groupBy === 1) { groupByCss = "group-craft"; }
    if (groupBy === 2) { groupByCss = "group-pilot"; }
    if (groupBy === 3) { groupByCss = "group-agent"; }
    if (groupBy === 4) { groupByCss = "group-event"; }
    if (groupBy === 5) { groupByCss = "group-sales-agent"; }

    if (props.loading) {
        return (<div className={`table-wrapper ${groupByCss}`}><div className="flight-report-status">Loading...</div></div>)
    } else if (props.data.length < 1) {
        return (<div className={`table-wrapper ${groupByCss}`}><div className="flight-report-status">No completed bookings for today.</div></div>)
    }

    if (groupBy === 1) {
        props.data.map(value => {
            if (crafts.indexOf(value.craft) === -1) {
                crafts.push(value.craft);
                grouped[value.craft] = [];
                grouped[value.craft].push(value);
            }
            else {
                grouped[value.craft].push(value);
            }
        });
    }
    else if (groupBy === 2) {
        props.data.map(value => {
            if (crafts.indexOf(value.pilot) === -1) {
                crafts.push(value.pilot);
                grouped[value.pilot] = [];
                grouped[value.pilot].push(value);
            }
            else {
                grouped[value.pilot].push(value);
            }
        });
    }
    else if (groupBy === 3) {
        props.data.map(value => {
            if (crafts.indexOf(value.agent) === -1) {
                crafts.push(value.agent);
                grouped[value.agent] = [];
                grouped[value.agent].push(value);
            }
            else {
                grouped[value.agent].push(value);
            }
        });
    }
    else if (groupBy === 4) {
        props.data.map(value => {
            if (crafts.indexOf(value.eventId) === -1) {
                crafts.push(value.eventId);
                grouped[value.eventId] = [];
                grouped[value.eventId].push(value);
            }
            else {
                grouped[value.eventId].push(value);
            }
        });
    }
    else if (groupBy === 5) {
        props.data.map(value => {
            if (crafts.indexOf(value.sales_agent) === -1) {
                crafts.push(value.sales_agent);
                grouped[value.sales_agent] = [];
                grouped[value.sales_agent].push(value);
            }
            else {
                grouped[value.sales_agent].push(value);
            }
        });
    }

    let groupsCnt = Object.keys(grouped).length;

    let grand_total = 0;
    let grand_total_pax = 0;
    let grand_total_cc = 0;
    let grand_total_cash = 0;
    let grand_total_eft = 0;
    let grand_total_payFast = 0;
    let grand_total_debt = 0;
    let grand_total_sal = 0;
    let grand_total_out = 0;
    let grand_total_vat = 0;
    let grand_total_net = 0;
    let grand_total_commission_value = 0;
    let row = 0;

    return (
        <div className={`table-wrapper ${groupByCss}`}>
            {Object.keys(grouped).map((keyName, i) => {
                let craftGroup = grouped[keyName];
                let total = 0;
                let total_pax = 0;
                let total_cc = 0;
                let total_cash = 0;
                let total_eft = 0;
                let total_payFast = 0;
                let total_debt = 0;
                let total_sal = 0;
                let total_vat = 0;
                let total_net = 0;
                let total_commission_value = 0;

                let rows = craftGroup.map(booking => {
                    let pax = booking.passengers + booking.passengersKids + booking.passengersToddlers + booking.passengersGuides;

                    let price_full = 0;

                    if (booking.source === "WooCommerce") {
                        price_full = booking.price_total;
                    } else {
                        price_full = booking.price + booking.priceKids + booking.price_merchandise; // TODO: Add Full Price to DB
                    }

                    total += booking.price_total;
                    total_pax += pax;
                    total_cc += booking.paidCreditCard;
                    total_cash += booking.paidCash;
                    total_eft += booking.paidEFT;
                    total_payFast += booking.paidPayFast;
                    total_debt += booking.paidDebt;
                    total_sal += booking.commission;
                    total_vat += booking.vat;
                    total_net = total;
                    total_commission_value += booking.commission;

                    grand_total += booking.price_total;
                    grand_total_pax += pax;
                    grand_total_cc += booking.paidCreditCard;
                    grand_total_cash += booking.paidCash;
                    grand_total_eft += booking.paidEFT;
                    grand_total_payFast += booking.paidPayFast;
                    grand_total_debt += booking.paidDebt;
                    grand_total_sal += booking.commission;
                    grand_total_vat += booking.vat;
                    grand_total_net += booking.price_total;
                    grand_total_commission_value += booking.commission;

                    let parts = booking.craft.split(" - ");
                    let flightNumber = parts[0];

                    row++;

                    return (
                        <tr key={row} onClick={() => props.onRowClick(booking)}>
                            <td>{booking.sales_agent}</td>
                            <td>{booking.route}</td>
                            <td>{pax}</td>
                            <td>{price_full.toFixed(2)}</td>
                            <td>{booking.price_discount}</td>
                            <td>{booking.price_total.toFixed(2)}</td>
                            <td>{booking.commissionPercent}</td>
                            <td>{booking.commission.toFixed(2)}</td>
                        </tr>
                    );
                });

                let totalRow = <></>
                // console.log("II:" + grouped.length);
                // console.log(i);
                if ((i + 1) === groupsCnt) {
                    totalRow = (<>
                        <tr>
                            <td colSpan="1"></td>
                            <td className="visible-cell"><strong>TOTALS:</strong></td>
                            <td className="visible-cell"><strong>{grand_total_pax}</strong></td>
                            <td className="visible-cell"></td>
                            <td className="visible-cell"></td>
                            <td className="visible-cell"><strong>{grand_total.toFixed(2)}</strong></td>
                            <td className="visible-cell"></td>
                            <td className="visible-cell"><strong>{grand_total_commission_value.toFixed(2)}</strong></td>
                        </tr>
                        <tr>
                            <td colSpan="24"></td>
                        </tr>
                    </>);
                }

                return (
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col">SALES AGENT</th>
                                <th scope="col">ROUTE</th>
                                <th scope="col">PAX</th>
                                <th scope="col">FULL PRICE</th>
                                <th scope="col">DISCOUNT</th>
                                <th scope="col">SALE VALUE</th>
                                <th scope="col">AGENT COMM %</th>
                                <th scope="col">AGENT COMM COST</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2"></td>
                                <td className="visible-cell"><strong>{total_pax}</strong></td>
                                <td colSpan="2"></td>
                                <td className="visible-cell"><strong>{total.toFixed(2)}</strong></td>
                                <td colSpan="1"></td>
                                <td className="visible-cell"><strong>{total_commission_value.toFixed(2)}</strong></td>
                            </tr>
                            <tr>
                                {/* <td colSpan="24"></td> */}
                                <td colSpan="21"></td>
                            </tr>
                            {totalRow}
                        </tfoot>
                    </table>)
            })}
        </div>
    )
}

function formatDate(dateString) {
    // Parse the date string
    let date = new Date(dateString);

    // Get the day, month, and year
    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let year = date.getFullYear();

    // Add leading zero if necessary
    if (day < 10) {
        day = '0' + day;
    }

    // Format the time
    let time = date.toTimeString().slice(0, 8);

    // Construct the formatted date string
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
}
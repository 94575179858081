
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom'
import './account-modal.scss';

import * as ROUTES from '../../constants/routes.js';
import * as PERMS from '../../constants/permissions.js';
import * as ALERTS from "../../constants/alerts";
import * as SETTINGS from "../../constants/settings";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { AppContext } from "../../Contexts/AppContext";

class AccountModal extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
        this.state = {
            logout: false,
            show: false,
            reload: false,
            profilePicture: require('../../content/user/user.png')
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({show: false });
        }
    }

    handleShow() {
        this.setState(prevState  => ({show: true }));
    }

    logout() {
        this.setState({logout:true});
    }

    accountSwitch = (event) => {
        let accountId = this.context.auth.user.accountId;

        let id = parseInt(event.target.value);
        this.setState({accountSwitchEnabled: false});

        // let toggleReload = this.props.toggleReload;

        if(accountId === 2) {
            this.context.auth.switchAccount((result) => {
                if(result) {
                    // toggleReload
                    this.context.alert.send("Sport Helicopters", ALERTS.SUCCESS);
                }
                else {
                    this.context.alert.send("Sport Helicopters - Could not switch", ALERTS.ERROR);
                }
            });
        }
        
        else if(accountId === 1) {
            this.context.auth.switchAccount((result) => {
                if(result) {
                    // toggleReload
                    this.context.alert.send("Sport Motor Yachts", ALERTS.SUCCESS);
                }
                else {
                    this.context.alert.send("Sport Motor Yachts - Could not switch", ALERTS.ERROR);
                }
            });
        }
    }

    accountSwitcher = () => {
        let accountId = this.context.auth.user.accountId;
        let role = this.context.auth.user.role;
        if(PERMS.AccountSwitcher.Read.includes(role)) {
            let classesHeli = "btn btn-lg btn-secondary";
            let classesYacht = "btn btn-lg btn-secondary";
            if(accountId === 1) {
                classesHeli = classesHeli+" active";
            }
            else if (accountId === 2) {
                classesYacht = classesYacht+" active";
            }

            return (
                <Modal.Body>
                <div className="btn-group btn-group-toggle account-switcher" data-toggle="buttons">
                    <label className={classesHeli}>
                        <input onChange={this.accountSwitch} type="radio" value="1" name="options" id="option1" autoComplete="off" checked={accountId === 1} /><img src="/sport-logo.png" />
                    </label>
                    <label className={classesYacht}>
                        <input onChange={this.accountSwitch} type="radio" value="2" name="options" id="option2" autoComplete="off" checked={accountId === 2}/><img src="/motor-yachts-logo.png" />
                    </label>
                </div>
                </Modal.Body>
            );
        }
        return <></>
    }

    renderModal() {
        return (
            <Modal.Dialog id="account-modal" className="fade-in" ref={this.setWrapperRef}>
            <Modal.Header>
                <div className="row">
                    <div className="col col-md-4"><img src={this.state.profilePicture} /></div>
                    <div className="col col-md-8"><h4><small>{this.context.auth.user.name}</small></h4><h6><small>{this.context.auth.user.email}</small></h6></div>
                </div>
            </Modal.Header>
            {this.accountSwitcher()}
            <Modal.Footer>
            <button type="button" className="btn btn-secondary btn-sm" disabled>Manage Account</button>
            <Button variant="primary" className="btn-sm" onClick={this.context.auth.signOut}>Sign Out</Button>
            </Modal.Footer>
            </Modal.Dialog>
        );
    }

    render() {
        // if(this.state.logout) {
        //     return <Navigate to={ROUTES.LOGIN} />
        // }

        let modal = this.renderModal();
        return (
            <>
                <img className={this.state.show ? "active" : ""} src={this.state.profilePicture} onClick={this.handleShow} />
                {this.state.show && this.renderModal()}
            </>
            );
    }
}

export default AccountModal;
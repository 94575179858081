import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as VIEW from '../../constants/Views.js';

import "../../components/react-datetime/react-datetime.css";
import Form from 'react-bootstrap/Form';
import * as ALERTS from "../../constants/alerts";



export default class OptionsForm extends React.Component {
  constructor(props) {
    super(props);
  }

  alertFullyBooked = (e) => {
      e.preventDefault();
      if(this.props.event.seatsBooked >= this.props.event.seatsTotal) {
          this.props.displayAlert("Event fully booked", ALERTS.ERROR);
      }
  }

  render() {
      let buttons = (<></>);
      let bookingButton = (<button className="btn btn-success btn-block btn-lg"
                                     onClick={() => this.props.navigateTo(VIEW.FORM_STATUS.CREATE)}>New Booking</button>);

      if(this.props.event.seatsBooked >= this.props.event.seatsTotal) {
          bookingButton = (<button className="btn btn-success btn-block btn-lg"
                                   onClick={this.alertFullyBooked}>New Booking</button>);
      }

      if(this.props.status === VIEW.FORM_STATUS.OPTIONS_EVENT) {
          buttons = <>
             {bookingButton}
              <button className="btn btn-success btn-block btn-lg"
                      onClick={() => this.props.navigateTo(VIEW.FORM_STATUS.PASSENGER_MANIFEST)}>Passenger Manifest</button>
              <button type="button" className="btn btn-danger btn-block btn-lg" onClick={this.props.cancelEvent}>Cancel Event</button>
          </>
      } else if(this.props.status === VIEW.FORM_STATUS.OPTIONS) {
          buttons = <>
              <button className="btn btn-success btn-block btn-lg"
                      onClick={() => this.props.navigateTo(VIEW.FORM_STATUS.CREATE_PRIVATE_CHARTER)}>Private Charter</button>
              <button className="btn btn-success btn-block btn-lg"
                      onClick={() => this.props.navigateTo(VIEW.FORM_STATUS.CREATE_EVENT)}>New Event</button>
          </>
      }
    return (
      <Form>
          {buttons}
      </Form>
    )
  }
}

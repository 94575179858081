import React from 'react';
import DataTable from 'react-data-table-component';
import * as SETTINGS from '../../constants/settings.js';
import * as LOOKUPS from '../../constants/lookups.js';
import * as BOOKING_FORM from '../../constants/bookingForm';
import DAL from '../../DAL'; // Data Access Layer

import './ProductsView.scss';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ViewIcon from '@mui/icons-material/LocalOffer';
import ProductFilterFormView from './Components/ProductFilterForm';

import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import GetApp from '@mui/icons-material/GetApp';
import Apps from '@mui/icons-material/Apps';

import * as ALERTS from '../../constants/alerts.js';
import * as VIEW from "../../constants/Views";
import ProductForm from "./Components/ProductForm";
import Form from "react-bootstrap/Form";
import moment from "moment";

import { AppContext } from '../../Contexts/AppContext';

const entityController = "products";
const entityName = "Product";

export default class Products extends React.Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: "",
            rowsTotal: 0,
            rowsPerPage: 10,
            actionQuery: "",
            filters: [],
            global_search: "",
            mobile: false,
            modal: {
                show: false,
                action: "book",
                data: {
                    "name": "",
                    "craft": "",
                    "routeId": 0,
                    "priceAdult": 0.0,
                    "priceKid": 0.0,
                    "wcProductId": 0,
                    "wcAdultPriceId": 0,
                    "wcKidPriceId": 0,
                    "updateDate": null
                }
            },
            isLoaded: false,
            inputDisabled: false,
            form: {
                "name": "",
                "craft": "",
                "routeId": 0,
                "priceAdult": 0.0,
                "priceKid": 0.0,
                "wcProductId": 0,
                "wcAdultPriceId": 0,
                "wcKidPriceId": 0,
                "updateDate": null
            },
            data: [],
            lookups: {
                booking_types: [],
                booking_routes: [],
                booking_offices: [],
                booking_payment_types: [],
                booking_sales_agents: []
            },
            formStatus: VIEW.FORM_STATUS.CREATE,
        };
        this.action = this.action.bind(this);
        this.getData = this.getData.bind(this);
        this._DAL = DAL(this.context.auth.token);
    }

    async componentDidMount() {
        this.setLoading(true);
        await this.initState();
        await this.getData(1);
        await this.getLookups();
        // this.setLoading(false);
    }

    componentDidUpdate(prevProps) {
        // TODO: Reload
        // if(this.props.reload) {
        //     
        //     this.props.toggleReload(false);
        //     this.getLookups();
        //     this.getData(1);
        // }
    }

    async initState() {
        let action = (new URLSearchParams(window.location.search)).get("action");

        let title = "Products";
        await this.setState({ title: title });
    }

    async getData(page) {
        // this.setLoading(true);

        let filters = [...this.state.filters]
        filters.push(`global_search=${this.state.global_search}`);
        let query = filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this._DAL.products.get(page, this.state.rowsPerPage, query, (response) => {
            this.setData(response.data.data);
            this.setRowsTotal(response.data.total);
        });

        // this.setLoading(false);
        return true;
    }

    action(row, action) {
        if (action) {
            this.setState({ modal: { show: true, title: "View " + entityName, action: "view" }, form: row });
        }

        console.log(row);
    }

    handlePageChange = page => {
        this.getData(page);
    };

    setData(data) {
        this.setState(state => {
            state.data = data;
            state.loading = false;
            return state;
        });
    }

    setLoading(loading) {
        this.setState(state => {
            state.loading = loading;
            return state;
        });
    }

    setRowsPerPage(perPage) {
        this.setState(state => {
            state.rowsPerPage = perPage;
            return state;
        });
    }

    setRowsTotal(rowsTotal) {
        this.setState(state => {
            state.rowsTotal = rowsTotal;
            return state;
        });
    }

    handlePerRowsChange = async (newPerPage, page) => {
        // this.setLoading(true);

        let config = { headers: { Authorization: `Bearer ${this.props.token}` } };
        let query = this.state.filters.join('&');
        query = query.length > 0 ? "?" + query : "";

        this._DAL.products.get(page, this.state.rowsPerPage, query, (response) => {
            this.setData(response.data.data);
            this.setRowsPerPage(newPerPage);
            this.setRowsTotal(response.data.total);
        });

        // this.setLoading(false);
    };

    getLookups = async () => {
        const lookups = ["crafts", "booking_routes"];

        this._DAL.lookups.get(lookups, (response) => {
            this.setState(state => {
                state.lookups = response.data;
                return state;
            });
        });

        //   .catch((error) => {
        //     console.log(error);
        //     this.setState(state => {
        //       state.error = error;
        //       return state;
        //     });
        //     this.context.alert.send(error.message, ALERTS.ERROR);
        //   }); 
    }

    handleChange = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        this.setState(state => { state.form[name] = val; return state; });
        return val;
    }

    ModalBody = () => {
        switch (this.state.formStatus) {
            case BOOKING_FORM.STATUS.CREATE:
            case BOOKING_FORM.STATUS.VIEW:
                return <ProductForm status={this.state.formStatus}
                    lookups={this.state.lookups}
                    displayAlert={this.context.alert.send}
                    token={this.context.auth.token}
                    user={this.context.auth.user}
                    action={this.state.modal.action}
                    data={this.state.form}
                    handleCancel={this.handleCancel}
                    handleChange={this.handleChange}
                    close={this.handleClose} />
        }
    }

    handleClose = () => { this.getData(1); this.setState({ modal: { show: false }, formStatus: BOOKING_FORM.STATUS.VIEW, form: BOOKING_FORM.initForm() }) };

    handleChange_GlobalSearch = (event) => {
        const val = event.target.value;
        this.setState(state => { state.global_search = val; return state; });

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setLoading(true);
            this.getData(1);
        }, 1000);

        return val;
    }

    render() {
        const { loading, data, rowsTotal } = this.state;

        return (
            <div id="bookings-view">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                    <h1 className="h4"><ViewIcon></ViewIcon>{this.state.title}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="bd-example">
                            <input
                                type="text"
                                className="form-control mr-2"
                                id="global_search"
                                name="global_search"
                                placeholder="Search..."
                                value={this.state.global_search}
                                onChange={this.handleChange_GlobalSearch} />
                        </div>
                    </div>
                </div>
                <DataTable
                    defaultSortAsc={false}
                    defaultSortField="id"
                    className="shadow-sm"
                    columns={columns(this.action)}
                    data={data}
                    onRowClicked={this.action}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={rowsTotal}
                    selectableRows
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                />
                <Modal show={this.state.modal.show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.ModalBody()}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const columns = action => [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'RouteId',
        selector: row => row.routeId,
        sortable: true,
    },
    {
        name: 'Craft',
        selector: row => row.craft,
        sortable: true,
    },
    {
        name: 'Price Adults',
        selector: row => row.priceAdult,
        sortable: true,
    },
    {
        name: 'Price Kids',
        selector: row => row.priceKid,
        sortable: true
    }
];
import Users from './UsersDAL';
import Bookings from './BookingsDAL';
import Products from './ProductsDAL';
import Lookups from './LookupsDAL';
import Events from './EventsDAL';
import PrintService from './PrintServiceDAL';

export default function DAL(token) {
    return {
        users: new Users(token),
        bookings: new Bookings(token),
        products: new Products(token),
        lookups: new Lookups(token),
        events: new Events(token),
        printService: new PrintService(token)
    }
}
